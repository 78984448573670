import {
  MaterialMap,
  MaterialPrimitive,
  OperatorData,
  OperatorDefinition,
  OperatorSchema,
} from '../../types';
import { Vector2 } from 'three';
import { ScaleMode } from '../constants';
import { UVTransform, ImagePlug } from '../Image/ImagePlug';

// import { proxyUnwrap, ProxyNode, isProxyNode } from '../../utils/proxyUnwrap';
// import { ImageNode } from '../../utils/textureVector';

//

const overrideMapList = [
  'anisotropyMap',
  'aoMap',
  'baseMap',
  'bumpMap',
  'clearCoatNormalMap',
  'flakesTintMap',
  'flakesMap',
  'sheenMap',
  'sheenRoughnessMap',
  'emissiveMap',
  'lightMap',
  'metallicMap',
  'normalMap',
  'opacityMap',
  'roughnessMap',
  'specularMap',
  'specularIntensityMap',
  'thicknessMap',
  'thinFilmThicknessMap',
  'transparencyMap',
  'transmissionMap',
];

// Maps for which the tiling information is used even if there is no image.
const proceduralMapList = ['flakesMap'];

export const TilingOverride: OperatorDefinition = {
  schema: {
    // overrides
    uOffset: {
      type: 'Number',
      label: 'U Offset',
      defaultValue: 0,
      step: 0.1,
      animatable: true,
    },
    vOffset: {
      type: 'Number',
      label: 'V Offset',
      defaultValue: 0,
      step: 0.1,
      animatable: true,
    },
    uTile: {
      type: 'Number',
      label: 'U Tile',
      defaultValue: 1,
      step: 0.1,
      animatable: true,
    },
    vTile: {
      type: 'Number',
      label: 'V Tile',
      defaultValue: 1,
      step: 0.1,
      animatable: true,
    },
    scaleMode: {
      label: 'Scale Mode',
      type: 'Options',
      defaultValue: ScaleMode.Tiling,
      values: [ScaleMode.Scale, ScaleMode.Tiling],
      labels: ['Scale', 'Tiling'],
    },
    uScale: {
      type: 'Number',
      label: 'U Scale',
      defaultValue: 1,
      step: 0.1,
      animatable: true,
    },
    vScale: {
      type: 'Number',
      label: 'V Scale',
      defaultValue: 1,
      step: 0.1,
      animatable: true,
    },
    // maps
    anisotropyMap: { type: 'Boolean', label: 'Anisotropy' },
    aoMap: { type: 'Boolean', label: 'Ambient Occlusion' },
    baseMap: { type: 'Boolean', label: 'Base' },
    bumpMap: { type: 'Boolean', label: 'Bump' },
    clearCoatNormalMap: { type: 'Boolean', label: 'Clear Coat Normal' },
    flakesTintMap: { type: 'Boolean', label: 'Flakes Tint' },
    flakesMap: { type: 'Boolean', label: 'Flakes' },
    sheenMap: { type: 'Boolean', label: 'Sheen' },
    sheenRoughnessMap: { type: 'Boolean', label: 'Sheen Roughness' },
    emissiveMap: { type: 'Boolean', label: 'Emissive' },
    lightMap: { type: 'Boolean', label: 'Light' },
    metallicMap: { type: 'Boolean', label: 'Metallic' },
    normalMap: { type: 'Boolean', label: 'Normal' },
    opacityMap: { type: 'Boolean', label: 'Opacity' },
    roughnessMap: { type: 'Boolean', label: 'Roughness' },
    specularMap: { type: 'Boolean', label: 'Specular' },
    specularIntensityMap: { type: 'Boolean', label: 'Specular Intensity' },
    transparencyMap: { type: 'Boolean', label: 'Transparency' },
    transmissionMap: { type: 'Boolean', label: 'Transmission' },
    thicknessMap: { type: 'Boolean', label: 'Thickness' },
    thinFilmThicknessMap: { type: 'Boolean', label: 'Thin-film Thickness' },
  },
  update(operator: any, primitive: MaterialPrimitive, opts: any) {
    const { uScale, vScale, uOffset, vOffset, scaleMode } = operator;
    // console.log('TilingOverride', operator, primitive);
    let { uTile, vTile } = operator;
    // Taken from ./Image/ImageBase.js
    if (scaleMode === ScaleMode.Scale) {
      uTile = 1 / uScale;
      vTile = 1 / vScale;
    }
    const uvTransform = {
      uvOffset: new Vector2(uOffset, vOffset),
      uvTile: new Vector2(uTile, vTile),
      rotation: 0.0,
    } as UVTransform;

    if (!primitive.overrides) primitive.overrides = {};

    // The `attachTo` can be different from what the operator key is.
    const reverseImageAttach: { [key: string]: string } = {
      metallicMap: 'metalnessMap',
      baseMap: 'map',
    };

    for (const mapName of overrideMapList) {
      if (operator[mapName]) {
        const overrideName = reverseImageAttach[mapName] || mapName;
        // const map = primitive.maps.find((n) => n.attachTo === mapName);
        // console.log('override?', mapName, operator[mapName], map);
        // @ts-ignore
        primitive.overrides[overrideName] = uvTransform;

        // const mapNode: ImageNode | ProxyNode<ImageNode> | undefined =
        // const mapNode = primitive[mapName];

        //     let imagePlug = proxyUnwrap(mapNode)?.Image;
        //     const isProceduralMap = proceduralMapList.includes(mapName);

        //     if (!mapNode && !imagePlug && !isProceduralMap) continue;

        //     if (isProceduralMap) {
        //       /*
        //         flakesMap is not a real map, we need to create it. By adding an Image property, this will
        //         be detected as imageType === 'bitmap'. This is fine, because the only place outside of
        //         texture that checks for imageType === 'bitmap' is in the render-webgl material setup, and
        //         that's where we set the tilling overrides!
        //       */
        //       imagePlug =
        //         imagePlug ??
        //         ({
        //           uvTransform: {
        //             uvOffset: { x: 0, y: 0 },
        //             uvTile: { x: 0, y: 0 },
        //             rotation: 0,
        //           },
        //           texelTransform: {
        //             gain: 1,
        //             gainPivot: 0,
        //             brightness: 0,
        //             invert: false,
        //           },
        //         } as ImagePlug);
        //     }

        //     if (imagePlug) {
        //       const newImagePlug = { ...imagePlug, uvTransform };
        //       if (isProxyNode(mapNode)) {
        //         // FIXME: This will fail on nested proxies.
        //         // FIXME: This will fail of the proxy is used twice but overriden once.
        //         //        Maybe use primitive[mapName] = { ...mapNode.Proxy.proxy, Image: newImagePlug, }?
        //         //          - Then we could just have mapNode = proxyUnwrap(mapNode);
        //         mapNode.Proxy.proxy = {
        //           ...(mapNode.Proxy.proxy as ImageNode),
        //           Image: newImagePlug,
        //         };
        //       } else {
        //         const newMapValue = { ...mapNode, Image: newImagePlug };
        //         primitive[mapName] = newMapValue;
        //       }
      }
      // console.log('TilingOverride', opts.node, primitive);
    }
    return primitive;
  },
};
