import { Component, ReactElement, ReactNode, FunctionComponent } from 'react';
import {
  EvaluatedNode,
  EvaluatedPolyMeshNode,
  SceneGraphNodeType,
} from '../../types';
import Image from './Image';
import Item from './Item';
import Material from './Material';
import Model from './Model';
import Null from './Null';
import Objects from './Objects';
import PolyMesh from './PolyMesh';

import { EvalNodeProps } from '../Node';
// : { [key: string]: Component<EvalNodeProps> }

const NodeComponentMap: {
  [type in SceneGraphNodeType]: FunctionComponent<EvalNodeProps>;
} = {
  Camera: Objects,
  Image,
  Item,
  Light: Objects,
  Material,
  MaterialLibrary: Objects,
  Model,
  Null,
  Objects,
  PolyMesh,
  Scene: Objects,
  Stage: Item,
};

// : Component<EvalNodeProps>
export function lookupComponent(
  type: SceneGraphNodeType
): FunctionComponent<EvalNodeProps> {
  return NodeComponentMap[type] ? NodeComponentMap[type] : Objects;
}
