export default function FaceEdgeAdjacency(polyMap) {
  this.polyMap = polyMap;

  this.faceEdgeIndices = null;

  this.edgeFaceCounts = null;
  this.edgeFaceIndices = null;

  this._compute();
}

FaceEdgeAdjacency.prototype = {
  constructor: FaceEdgeAdjacency,

  // F -> E
  //faceValueIndex is the index of value on the face
  //ie: the index of the value in faceValueIndices
  //    not the value of faceValueIndices
  getEdgeIdFromFaceValueIndex: function (faceValueIndex) {
    return this.faceEdgeIndices[faceValueIndex];
  },

  // E -> F, part 1
  getNumFacesForEdgeId: function (edgeID) {
    return this.edgeFaceCounts[edgeID];
  },
  // E -> F, part 2
  getFaceForEdgeId: function (edgeId, faceOffset) {
    return this.edgeFaceIndices[edgeId * 2 + faceOffset];
  },

  //returns false if there is an invalid surface
  //if this function returns false this adjacency structure is invalid and cannot be used
  checkValidity: function () {
    for (let i = 0; i < this.edgeFaceCounts.length; ++i) {
      if (this.edgeFaceCounts[i] > 2) return false;
    }
    return true;
  },

  _compute: function () {
    const polyMap = this.polyMap;
    const edgeVertexAdjacency = polyMap.edgeVertexAdjacency;

    const faceRangeOffsets = polyMap.faceRangeOffsets;
    const faceValueIndices = polyMap.faceValueIndices;

    const numEdges = edgeVertexAdjacency.edgeHighVertexIndices.length;
    //const numFaces = faceRangeOffsets.length - 1;
    const edgeFaceCounts = new Uint32Array(numEdges);
    const edgeFaceIndices = new Uint32Array(numEdges * 2);
    const faceEdgeIndices = new Uint32Array(faceValueIndices.length);

    for (let f = 0; f < faceRangeOffsets.length - 1; f++) {
      const faceBegin = faceRangeOffsets[f];
      const faceUntil = faceRangeOffsets[f + 1];
      const numFaceValues = faceUntil - faceBegin;

      let vNext = faceValueIndices[faceBegin];

      // count faces on each edge.
      for (let fv = 0; fv < numFaceValues; fv++) {
        const v = vNext;
        vNext = faceValueIndices[faceBegin + ((fv + 1) % numFaceValues)];

        const edgeId = edgeVertexAdjacency.findEdgeId(v, vNext);

        const faceValueIndex = faceBegin + fv;

        faceEdgeIndices[faceValueIndex] = edgeId;

        edgeFaceIndices[edgeId * 2 + edgeFaceCounts[edgeId]] = f;
        edgeFaceCounts[edgeId]++;
      }
    }

    this.faceEdgeIndices = faceEdgeIndices;

    this.edgeFaceCounts = edgeFaceCounts;
    this.edgeFaceIndices = edgeFaceIndices;

    return this;
  },
};
