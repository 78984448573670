import {
  OperatorData,
  PolyMeshPrimitive,
  OperatorDefinition,
} from '../../types';

const Mesh: OperatorDefinition = {
  schema: {
    depth: {
      type: 'Number',
      label: 'Depth',
      defaultValue: 1,
      step: 0.1,
      min: 0.0,
      animatable: true,
    },
    width: {
      type: 'Number',
      label: 'Width',
      defaultValue: 1,
      step: 0.1,
      min: 0.0,
      animatable: true,
    },
    height: {
      type: 'Number',
      label: 'Height',
      defaultValue: 1,
      step: 0.1,
      min: 0.0,
      animatable: true,
    },
    depthSegments: {
      type: 'Integer',
      label: 'Depth Segments',
      defaultValue: 1,
      min: 1,
      animatable: true,
    },
    widthSegments: {
      type: 'Integer',
      label: 'Width Segments',
      defaultValue: 1,
      min: 1,
      animatable: true,
    },
    heightSegments: {
      type: 'Integer',
      label: 'Height Segments',
      defaultValue: 1,
      min: 1,
      animatable: true,
    },
  },

  update(op: OperatorData, primitive: PolyMeshPrimitive) {
    primitive.geometry = {
      kind: 'Box',
      args: [op.width, op.height, op.depth],
    };
    return primitive;
  },
};

export default Mesh;
