// import { CameraProperties } from './CameraProperties';
import Default from './Default';
// import GroupProperties from './GroupProperties';
// import ImageProperties from './ImageProperties';
// import LayerProperties from './LayerProperties';
// import ImageLayerProperties from './ImageLayerProperties';
// import MaterialProperties from './MaterialProperties';
// import MetaData from './MetaData';
import ModelProperties from './ModelProperties';
import PolyMeshProperties from './PolyMeshProperties';
// import RigidBodyProperties from './RigidBodyProperties';
// import SceneProperties from './SceneProperties';
// import ShapeProperties from './ShapeProperties';
// import VrayAOProperties from './VrayAOProperties';
// import Tags from './Tags';

export default {
  // CameraProperties,
  Default,
  // ImageProperties,
  // MaterialProperties,
  // MetaData,
  ModelProperties,
  PolyMeshProperties,
  // RigidBodyProperties,
  // SceneProperties,
  // ShapeProperties,
  // GroupProperties,
  // LayerProperties,
  // ImageLayerProperties,
  // Tags,
  // VrayAOProperties,
};
