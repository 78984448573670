import Arrays from '../utility/Arrays';
import BitSet from '../container/BitSet';
import ObjectArrayView from '../container/ObjectArrayView';
import IndexMappings from './IndexMappings';

/**
 * Construct a compact (injective / scattering) map to globally unique values
 * within an ObjectArrayView and optionally also the (injective / compacting)
 * source-to-target mapping (STTM).
 *
 * For each index in the input data, the STTM contains the index in compacted
 * form. The STTM can be used to transcribe existing indices into the data or,
 * if the data is "unindexed", it is the indices.
 *
 * @constructor
 *
 * @param {!ObjectArrayView} values
 * @param {boolean=} ttsOnly whether to only create the target-to-source map
 */
export default function GlobalCompaction(values, ttsOnly) {
  const nValues = values.length;

  const tupleOrder = Arrays.sort(
    IndexMappings.identity(nValues),
    values.newCompareAtIndicesStable()
  );

  let nUnique = 0;
  let sourceToTargetMap = null;

  const compare = values.newCompareAtIndices();

  if (ttsOnly) {
    nUnique = Arrays.unique(tupleOrder, compare);
  } else {
    // customized UNIQUE algo that builds the STTM on the fly

    sourceToTargetMap = new Uint32Array(nValues);

    let writeIndex = 0;
    let prevUniqueAt = 0;
    let minValueIndex = tupleOrder[0];

    for (let i = 1; i < nValues; ++i) {
      const valueIndex = tupleOrder[i];

      if (compare(valueIndex, minValueIndex) !== 0) {
        for (let j = prevUniqueAt; j !== i; ++j)
          sourceToTargetMap[tupleOrder[j]] = minValueIndex;

        prevUniqueAt = i;
        minValueIndex = valueIndex;

        if (++writeIndex !== i) tupleOrder[writeIndex] = valueIndex;
      }
    }

    for (let j = prevUniqueAt; j !== nValues; ++j)
      sourceToTargetMap[tupleOrder[j]] = minValueIndex;

    nUnique = writeIndex + 1;
  }

  // Now that duplicates have been removed, restore the original order by
  // sorting the indices (since we have a finite integer range, we can do
  // so very quickly using a BitSet):

  const targetToSourceMap = new BitSet(nValues)
    .includeFromArray(tupleOrder, 0, nUnique)
    .toIndexArray(nUnique);

  if (sourceToTargetMap !== null) {
    // The STTM already has all equal indices mapped to the first, but
    // these indices are still in respect to the input, so they need to
    // be translated to refer to compact data:

    const reusableMemory = tupleOrder;

    const sparseCompaction = IndexMappings.inverse(
      targetToSourceMap,
      null,
      reusableMemory
    );

    IndexMappings.apply(sourceToTargetMap, sparseCompaction);
  }

  this.targetToSourceMap = targetToSourceMap;
  this.sourceToTargetMap = sourceToTargetMap;
}

GlobalCompaction.prototype = {
  constructor: GlobalCompaction,

  compactedValues: function(data) {
    const map = this.targetToSourceMap;
    const nCompact = map.length;

    const result = new ObjectArrayView(data.type, nCompact);

    const elementData = data.newRangeArray();

    for (let i = 0; i !== nCompact; ++i) {
      data.rangeToArray(map[i], 1, elementData);
      result.arrayToRange(elementData, i, 1);
    }

    return result;
  },

  indices: function(nFaceVertices) {
    return new Uint32Array(this.sourceToTargetMap);
  },

  transcribeIndices: function(indices) {
    return IndexMappings.apply(indices, this.sourceToTargetMap);
  },
};
