import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { EvalNodeProps } from '../Node';
import Asset from '../Asset';
import { EvaluatedModelNode } from '../../types';

interface ModelEvalNodeProps extends EvalNodeProps {
  evalNode: EvaluatedModelNode;
}

export default function Model(props: ModelEvalNodeProps) {
  const { assetState, children, evalNode, nodeProps } = props;
  const ref = useRef();

  if (nodeProps && nodeProps['useFrame']) {
    useFrame((state) => nodeProps['useFrame'](state, ref));
  }
  const { position, euler, scale } = evalNode.Transform;

  return (
    <group
      {...nodeProps}
      name={evalNode.node.name}
      position={position}
      rotation={euler.toArray()}
      scale={scale}
      ref={ref}
    >
      {evalNode.Null.link?.assetId && (
        <Asset
          {...props}
          id={evalNode.Null.link.assetId}
          fromId={assetState.id}
        />
      )}
      {children}
    </group>
  );
}
