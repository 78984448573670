import { Vector3 } from 'three';
import ObjectArrayView from '../../generic/container/ObjectArrayView';

function BlendShapeData(positions, normals, weights, weight) {
  this.positions = positions || new ObjectArrayView(Vector3, 0); // must be an object array view (cannot be null)
  this.normals = normals; // must be an object array view or null
  this.weights = weights; // Stored as a ScalarArrayView
  this.weight = weight || 1; // a single weight for the whole shape (a float value)
}

BlendShapeData.prototype = {
  constructor: BlendShapeData,

  isEmpty: function () {
    return !this.positions.length;
  },

  setWeight: function (w) {
    this.weight = w;
  },
};

export default BlendShapeData;
