import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { EvalNodeProps } from '../Node';
import { EvaluatedObjectsNode } from '../../types';

export default function Objects(props: EvalNodeProps) {
  const { children, nodeProps } = props;
  const ref = useRef();
  const evalNode = props.evalNode as EvaluatedObjectsNode;
  const { node } = evalNode;

  return (
    <group {...nodeProps} name={node.name} ref={ref}>
      {children}
    </group>
  );
}
