import Arrays from '../../generic/utility/Arrays';
import ObjectArrayView from '../../generic/container/ObjectArrayView';
import ScalarArrayView from '../../generic/container/ScalarArrayView';
import IndexMappings from '../../generic/algorithm/IndexMappings';
import RenderIndexing from '../algorithm/RenderIndexing';
import EarCutTriangulation from '../algorithm/EarCutTriangulation';
import SimpleFanTriangulation from '../algorithm/SimpleFanTriangulation';

export default function Exporter(polyMesh) {
  this.mesh = polyMesh;

  this.triangulation = null;
  this.renderIndexing = null;
}

Exporter.prototype = {
  constructor: Exporter,

  reindexForRendering: function(optionalMapIdWhitelist) {
    if (this.triangulation !== null)
      throw Error('Reindex must come before triangulation!');

    this.renderIndexing = new RenderIndexing(this.mesh, optionalMapIdWhitelist);

    return this;
  },

  triangulate: function(optionalTriangulationType) {
    var triangulationType = optionalTriangulationType || EarCutTriangulation;

    this.triangulation = new triangulationType(this.mesh, this.renderIndexing);

    return this;
  },

  getNumFaces: function() {
    var triangulation = this.triangulation;

    return triangulation === null
      ? this.mesh.getNumFaces()
      : triangulation.numTriangles;
  },

  getNumFaceVertices: function() {
    return this.triangulation === null
      ? this.mesh.getNumFaceVertices()
      : this.getNumFaces() * 3;
  },

  getNumMapValues: function(mapId) {
    var renderIndexing = this.renderIndexing;

    return renderIndexing !== null
      ? renderIndexing.numVertices
      : this.mesh.getMapById(mapId).values.length;
  },

  getFaceArities: function(
    optionalArrayOrType,
    optionalOffset,
    optionalStride
  ) {
    if (this.triangulation !== null)
      throw Error('No face arity export for triangular meshes!');

    var result = new ScalarArrayView(
        this.getNumFaces(),
        optionalArrayOrType,
        optionalOffset,
        optionalStride
      ),
      offsets = this.mesh.faceRangeOffsets,
      nOffsets = offsets.length;

    if (nOffsets > 1) {
      var prevOffset = offsets[0];

      for (var i = 1; i !== nOffsets; ++i) {
        var offset = offsets[i];
        result.setAt(i - 1, offset - prevOffset);
        prevOffset = offset;
      }
    }

    return result.data;
  },

  getMaterialIds: function(
    optionalArrayOrType,
    optionalOffset,
    optionalStride
  ) {
    var result = null,
      source = this.mesh.materialIDs;

    if (source === null) return null;

    if (this.triangulation === null) {
      var offset = optionalOffset || 0,
        stride = optionalStride || 1,
        arrayOrType = optionalArrayOrType || source.data.constructor;

      if (
        offset === 0 &&
        stride === 1 &&
        arrayOrType === source.data.constructor
      )
        return source;

      result = new ScalarArrayView(source.length, arrayOrType, offset, stride);

      result.fromArray(source);
    } else {
      this.triangulation.translateMaterialIds(source, result);
    }

    return result.data;
  },

  getMapValues: function(
    mapId,
    optionalArrayOrType,
    optionalOffset,
    optionalStride
  ) {
    var result = null,
      renderIndexing = this.renderIndexing,
      source = this.mesh.getMapById(mapId).values,
      type = source.type,
      offset = optionalOffset || 0,
      stride = optionalStride || type.InstanceScalarSize,
      arrayOrType = optionalArrayOrType || source.data.constructor;

    if (
      renderIndexing === null &&
      offset === 0 &&
      stride === type.InstanceScalarSize &&
      arrayOrType === source.data.constructor
    )
      result = source;
    else {
      // compatible format, just expose the data

      var length =
        renderIndexing === null ? source.length : renderIndexing.numVertices;

      result = this._offsetObjects(type, length, arrayOrType, offset, stride);

      if (renderIndexing !== null) {
        renderIndexing.getMapValues(mapId, result);
      } else {
        result.fromArray(source.data);
      }
    }

    return result.data;
  },
  getGroups: function() {
    var triangulation = this.triangulation;
    var result = triangulation.groups;
    return result;
  },
  getMapIndices: function(
    mapId,
    optionalArrayOrType,
    optionalOffset,
    optionalStride
  ) {
    var result = null,
      renderIndexing = this.renderIndexing,
      triangulation = this.triangulation,
      source =
        renderIndexing !== null
          ? renderIndexing.renderIndices
          : this.mesh.getMapById(mapId).faceValueIndices;

    if (triangulation === null) {
      var offset = optionalOffset || 0,
        stride = optionalStride || 1,
        arrayOrType = optionalArrayOrType || source.constructor;

      if (offset === 0 && stride === 1 && arrayOrType === source.constructor)
        return source;

      result = new ScalarArrayView(source.length, arrayOrType, offset, stride);

      result.fromArray(source);
    } else {
      result = new ScalarArrayView(
        this.triangulation.numTriangles * 3,
        optionalArrayOrType,
        optionalOffset,
        optionalStride
      );

      triangulation.translateIndices(source, result);
    }

    return result.data;
  },

  getUnindexedMapValues: function(
    mapId,
    allowTriangulation,
    optionalArrayOrType,
    optionalOffset,
    optionalStride
  ) {
    var result = null,
      polyMap = this.mesh.getMapById(mapId),
      values = polyMap.values,
      valueType = values.type,
      indices = polyMap.faceValueIndices,
      triangulation = this.triangulation;

    if (!allowTriangulation || triangulation === null) {
      result = this._offsetObjects(
        valueType,
        indices.length,
        optionalArrayOrType || values.data.constructor,
        optionalOffset,
        optionalStride
      );

      var temp = values.newRangeArray(1);

      for (var i = 0, n = indices.length; i !== n; ++i)
        result.arrayToRange(values.rangeToArray(indices[i], 1, temp), i, 1);
    } else {
      result = this._offsetObjects(
        valueType,
        triangulation.numTriangles * 3,
        optionalArrayOrType || values.data.constructor,
        optionalOffset,
        optionalStride
      );

      triangulation.translateToUnindexedValues(values, result, indices);
    }

    return result.data;
  },

  getEdgeIndicesMapValues: function() {
    var polyMap = this.mesh.getMapById('positions'),
      values = polyMap.values,
      valueType = values.type,
      indices = polyMap.edgeVertexAdjacency.edgeVertices;

    var result = this._offsetObjects(
      valueType,
      indices.length,
      values.data.constructor
    );
    var temp = values.newRangeArray(1);
    for (var i = 0; i < indices.length; i++) {
      result.arrayToRange(values.rangeToArray(indices[i], 1, temp), i, 1);
    }
    return result;
  },

  getTriangulatingIndices: function(
    optionalArrayOrType,
    optionalOffset,
    optionalStride
  ) {
    var triangulation = this.triangulation;

    if (triangulation === null) return null;

    var result = new ScalarArrayView(
        this.getNumFaceVertices(),
        optionalArrayOrType,
        optionalOffset,
        optionalStride
      ),
      nFaceVertices = this.getNumFaceVertices(),
      identityMap = IndexMappings.identityForReading(nFaceVertices);

    triangulation.translateIndices(identityMap, result);

    return result.data;
  },

  _offsetObjects: function(
    type,
    length,
    arrayOrType,
    optionalOffset,
    optionalStride
  ) {
    var offset = optionalOffset || 0,
      stride = optionalStride || type.InstanceScalarSize,
      offsetLength = (offset / stride) | 0,
      minArrayLength = (offsetLength + length) * stride,
      array = Arrays.maybeCreate(arrayOrType, minArrayLength),
      offsetView = offset === 0 ? array : array.subarray(offset);

    return new ObjectArrayView(type, null, offsetView, stride);
  },
};
