import { Reference } from './Reference';
import { Physical } from './Physical';
import { MapOverride } from './MapOverride';
import { TilingOverride } from './TilingOverride';

export default {
  MapOverride,
  Physical,
  Reference,
  TilingOverride,
};
