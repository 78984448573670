import Arrays from '../utility/Arrays';
import FlatArrayView from './FlatArrayView';
import ScalarBuffer from './ScalarBuffer';

export default function ScalarArrayView(
  length,
  optionalArrayOrType,
  optionalOffset,
  optionalStride
) {
  const offset = optionalOffset === undefined ? 0 : optionalOffset;
  const stride = optionalStride === undefined ? 1 : optionalStride;

  const offsetLength = ((offset + stride - 1) / stride) | 0;
  const minArrayLength =
    length !== null ? (offsetLength + length) * stride : null;

  const array = Arrays.maybeCreate(
    optionalArrayOrType || Uint32Array,
    minArrayLength
  );

  FlatArrayView.call(
    this,
    array,
    length !== null
      ? length
      : // figure out length from user-provided array
        ((array.length - offset + stride - 1) / stride) | 0
  );

  this.offset = offset;
  this.stride = stride;
}

ScalarArrayView.prototype = Object.assign(
  Object.create(FlatArrayView.prototype),
  {
    constructor: ScalarArrayView,

    getAt: function(index) {
      return this.array[this.offset + index * this.stride];
    },

    setAt: function(index, value) {
      this.array[this.offset + index * this.stride] = value;
    },

    newCompatibleView: function(arrayOrType, optionalLength) {
      return new ScalarArrayView(optionalLength || null, arrayOrType, 0);
    },

    newCompatibleBuffer: function() {
      return new ScalarBuffer(this.data.constructor);
    },
  }
);
