import { diffReferences } from './diffReferences';
import { getObject, ObjectsStore } from './getObject';
import { getIn } from './hierarchy';
import {
  isArraySchema,
  isObjectSchema,
  isPrimitiveSchema,
  ObjectSchema,
  Path,
  Schema,
  schemas,
  isRawSchema,
  hasId,
} from './schemas';

export interface DiffObject {
  action: string;
  type: string;
  path: Path;
  value: any;
}
export type DiffObjects = DiffObject[];

function assert(truthy: any, message: string) {
  if (!truthy) throw new Error(`assertion failure: ${message}`);
}

function allIndexed(fn: (l: any, i: number) => boolean, list: any[]) {
  for (let i = 0; i < list.length; i++) {
    if (!fn(list[i], i)) return false;
  }
  return true;
}

/**
 *
 * @param {Object} schema Schema for the object being hydrated
 * @param {Object} obj  The hashed object being hydrated
 * @param {Array} p Input path from existing data
 * @param {String} type Type of object being hydrated
 * @param {Object} hier  Hierarchy for id lookup
 * @param {Array} origPath (aka, where to look up object id's)
 */
function hydrate(
  schema: Schema,
  get: (s: string) => any,
  obj: any,
  p: Path,
  type: string,
  hier: string[],
  rootType: string,
  origPath?: any[],
  origId?: string
): DiffObjects {
  let path = p;
  let arrResult: DiffObjects = [{ action: 'ADD', path, value: obj, type }];

  if (hasId(schema)) {
    // console.log(
    //   'get id from hier',
    //   obj.id,
    //   hier,
    //   origPath,
    //   path,
    //   origId
    // );

    // need ignoreMissing: true because olders scenes don't have IDs for operators. (Migration opportunity)
    const id = getIn(hier, origPath || path, origId, true);
    if (id) {
      obj.id = id;
    }
  }

  if (type === rootType) {
    // console.log('hydrate, new path', path);
    path = [obj.id]; // New path to descend from
  }

  if (isObjectSchema(schema)) {
    Object.keys(obj).forEach((k) => {
      if (k === 'id') return;

      const subSchemaType =
        schema.as || (schema.keys ? schema.keys[k] : undefined);
      const subSchema = subSchemaType && schemas[subSchemaType];

      const subPath = path.concat([k]);
      if (subSchemaType && subSchema) {
        if (isArraySchema(subSchema)) {
          const subResults = obj[k].map((v: string, idx: number) =>
            hydrate(
              schemas[subSchema.of],
              get,
              get(v),
              subPath.concat([idx]),
              subSchema.of,
              hier,
              rootType
            )
          );
          if (subSchema.of === rootType) {
            obj[k] = [];
            arrResult = subResults.reduce(
              (acc: [], arr: []) => acc.concat(arr),
              arrResult
            );
          } else {
            obj[k] = subResults.map((arr: any) => arr[0].value);
          }
        } else if (isRawSchema(subSchema)) {
          // do nothing for string schema
        } else {
          const subHydrate = hydrate(
            subSchema,
            get,
            get(obj[k]),
            subPath,
            subSchemaType,
            hier,
            rootType
          )[0];

          if (subSchemaType === rootType) {
            arrResult.push(subHydrate);
          } else {
            obj[k] = subHydrate.value;
          }
        }
      }
    });
  }
  // console.log('hydrate:', type, p, type===rootType, obj, origPath);
  // console.log(arrResult);
  return arrResult; // type===rootType ? arrResult : obj;
}

export function diffTree(
  sceneId: string,
  type: string,
  objects: ObjectsStore,
  toRootHash: string,
  fromRootHash?: string,
  secondRootHash?: string
): DiffObjects {
  const rootType = type;

  const get = getObject(objects);

  const toRoot = get(toRootHash);
  const fromRoot = fromRootHash ? get(fromRootHash) : {};
  const secondRoot = secondRootHash ? get(secondRootHash) : {};

  const toHierarchy = get(toRoot.hierarchy);
  const toReferences = get(toRoot.references);

  const fromHierarchy = get(fromRoot.hierarchy);
  const fromReferences = get(fromRoot.references) || {};

  const secondHierarchy = get(secondRoot.hierarchy);
  const secondReferences = get(secondRoot.references) || {};

  let arrResult: DiffObjects = [];

  function prepDiff(
    type: string,
    path: Path,
    to: any,
    from?: any,
    second?: any
  ): DiffObjects {
    const schema = schemas[type];
    if (!schema) throw new Error(`No schema for ${type}`);
    const hasSecond = second !== undefined;
    // console.log(
    //   'prepDiff?',
    //   type,
    //   path,
    //   schema.type,
    //   type===rootType,
    //   to,
    //   from,
    //   second,
    //   hasSecond
    // );

    const id = path[0];

    if (isRawSchema(schema)) {
      if (to !== from || (hasSecond && second !== from)) {
        return [
          {
            action: 'SET',
            type,
            path,
            value: hasSecond && second !== from ? second : to,
          },
        ];
      }
    } else if (isPrimitiveSchema(schema)) {
      return diff(type, path, undefined, to, from, second);
    } else if (isArraySchema(schema)) {
      return diffArray(schema.of, path, to, from, second);
    } else if (isObjectSchema(schema)) {
      return diff(type, path, undefined, to, from, second);
    }
    return [];
  }

  function diffArrayWithIds(
    type: string,
    path: Path,
    to: string,
    from?: string,
    second?: string
  ): DiffObjects {
    // console.log('getIn', path);
    const ids = getIn(toHierarchy, path);
    const fromIds = from ? getIn(fromHierarchy, path) : [];
    const secondIds = second ? getIn(secondHierarchy, path) : [];
    // console.log('diffArraywithids', type, path, ids, fromIds, secondIds);
    let arrResult: DiffObjects = [];

    ids.forEach((id: string, idx: number) => {
      const fromIdx = fromIds.indexOf(id);
      const secondIdx = secondIds.indexOf(id);

      arrResult = arrResult.concat(
        diff(
          type,
          path.concat([idx]),
          id,
          to[idx],
          from && fromIdx !== -1 ? from[fromIdx] : undefined,
          second ? (secondIdx !== -1 ? second[secondIdx] : false) : undefined
        )
      );
    });

    let numNewSecondObjs = 0;

    secondIds.forEach((id: string, idx: number) => {
      const toIdx = ids.indexOf(id);
      const fromIdx = fromIds.indexOf(id);

      if (toIdx === -1) {
        arrResult = arrResult.concat(
          diff(
            type,
            path.concat([ids.length + numNewSecondObjs]),
            id,
            undefined,
            from && fromIdx !== -1 ? from[fromIdx] : undefined,
            second ? second[idx] : undefined,
            path.concat([idx])
          )
        );
        numNewSecondObjs += 1;
      }
    });

    fromIds.forEach((id: string, idx: number) => {
      if (
        ids.indexOf(id) === -1 &&
        (!secondIds || secondIds.indexOf(id) === -1)
      ) {
        arrResult = arrResult.concat(
          diff(
            type,
            path.concat([idx]),
            id,
            false,
            from ? from[idx] : undefined,
            false
          )
        );
      }
    });

    return arrResult;
  }

  function diffArray(
    type: string,
    path: Path,
    to: any[] | string,
    from?: any[] | string,
    second?: any[] | string
  ): DiffObjects {
    // if (to.length === 0 && from.length === 0) return;
    if (type === rootType) {
      return diffArrayWithIds(
        type,
        path,
        to as string,
        from ? (from as string) : undefined,
        second ? (second as string) : undefined
      );
    }

    const maxI = Math.max(
      to.length,
      from ? from.length : 0,
      second ? second.length : 0
    );
    let arrResult: DiffObjects = [];
    for (let i = 0; i < maxI; i++) {
      arrResult = arrResult.concat(
        diff(
          type,
          path.concat([i]),
          undefined,
          to && to[i],
          from && from[i],
          second && second[i]
        )
      );
    }
    return arrResult;
  }

  function hasKey<O>(obj: O, key: string | number | symbol): key is keyof O {
    return key in obj;
  }

  function diffObject(
    schema: ObjectSchema,
    path: Path,
    to?: any,
    from?: any,
    second?: any
  ): DiffObjects {
    // console.log('diffObject', schema, to, from, second, path);
    const p = path; // id ? [id] : path;
    let arrResult: DiffObjects = [];

    if (schema.as) {
      const toKeys = Object.keys(to);
      for (const toKey of toKeys) {
        arrResult = arrResult.concat(
          prepDiff(
            schema.as,
            p.concat([toKey]),
            to[toKey],
            from[toKey],
            second && second[toKey]
          )
        );
      }
    } else if (schema.keys) {
      // console.log('diff keys', to);
      Object.keys(to).forEach((k) => {
        if (hasKey(schema.keys, k)) {
          const type = schema.keys ? schema.keys[k] : '';
          // const childSchema = schemas[schema.keys[k]];
          arrResult = arrResult.concat(
            prepDiff(type, p.concat([k]), to[k], from[k], second && second[k])
          );
        } else {
          throw new Error(`Unknown type to diff: ${k} for ${schema.type}`);
        }
      });
    }

    return arrResult;
  }

  function diff(
    type: string,
    path: Path,
    id: string | undefined,
    toHash: string | false | undefined, // false means removed
    fromHash: string | undefined,
    secondHash: string | false | undefined, // false means removed
    origPath?: Path
  ): DiffObjects {
    // console.log('diff', type, path, id, toHash, fromHash, secondHash);

    if (toHash === fromHash && (!secondHash || secondHash === fromHash)) {
      // console.log('Same, skip diff', type, path);
      return [];
    }

    const to = toHash ? get(toHash) : undefined;
    const from = fromHash ? get(fromHash) : undefined;
    const second = secondHash ? get(secondHash) : undefined;
    const hasSecond = second !== undefined;

    const schema = schemas[type];
    // console.log('diff', type, schema, path, id, to, from, second);
    if (!schema) throw new Error(`Unknown schema type: ${type}`);

    if (isObjectSchema(schema)) {
      if ((!to || secondHash === false) && from) {
        // console.log('remove?', type, path, id);
        // console.log('remove object', type, path, id, schema);
        const value = id || path[path.length - 1];
        const p = path.slice(0, path.length - 1);
        return [{ action: 'REMOVE', type, path: p, value: [value] }];
      } else if (to && from) {
        // console.log('diffObject', type===rootType, path, id);
        return diffObject(
          schema,
          type === rootType && id ? [id] : path,
          to,
          from,
          second
        );
      } else if (!from && (to || hasSecond)) {
        // console.log('hydrate?', id, schema, type, hasSecond, path, origPath);
        return hydrate(
          schema,
          get,
          hasSecond ? second : to,
          path,
          type,
          hasSecond ? secondHierarchy : toHierarchy,
          rootType,
          origPath,
          id
        );
        // .forEach(({ type, path, obj }) => {
        //   console.log('add?', type, path, obj);
        //   add(type, path, obj);
        // });
      }
    } else if (schema.type === 'Primitive') {
      // console.log('diff primitive', to, from, second);
      if (from && !to && !second) {
        // console.log('remove from primitive?', from, id);
        return [
          {
            action: 'REMOVE',
            type,
            path: path.slice(0, path.length - 1),
            value: [path[path.length - 1]],
          },
        ];
      } else if (!from && (to || hasSecond)) {
        return hydrate(
          schema,
          get,
          hasSecond ? second : to,
          path,
          type,
          hasSecond ? secondHierarchy : toHierarchy,
          rootType,
          origPath,
          id
        );
        // .forEach(({ type, path, obj }) => {
        //   add(type, path, obj);
        // });
      } else if (from !== to || (hasSecond && secondHash !== fromHash)) {
        let changeTo = from !== to ? to : second;
        if (from !== to && hasSecond && secondHash !== fromHash) {
          // merge changes from second into from, using === for equality
          if (typeof to === 'object') {
            Object.keys(second).forEach((k) => {
              if (second[k] !== from[k]) changeTo[k] = second[k];
            });
          } else {
            changeTo = second;
          }
        }
        return [{ action: 'SET', type, path, value: changeTo }];
      } else {
        console.error('Unknown diff', type, path, toHash, fromHash);
        throw new Error(`Unknown diff ${type}`);
      }
    }
    return [];
  }

  arrResult = arrResult.concat(
    diff(
      type,
      [],
      sceneId,
      toRoot.content,
      fromRoot.content,
      secondRoot.content
    )
  );

  arrResult = arrResult.concat(
    diffReferences(toReferences, fromReferences, secondReferences)
  );

  // Combine all REMOVE diffs at the same path together
  const removes: { [key: string]: number } = {};
  const deleteFromDiffs: number[] = [];
  arrResult = arrResult.map((d, idx) => {
    if (d.action !== 'REMOVE') return d;
    const key = d.path.join('-');
    // const key = `${d.type}-${d.path.slice(0, d.path.length - 1).join('-')}`;
    if (removes[key] !== undefined) {
      arrResult[removes[key]].value.push(d.value[0]);
      deleteFromDiffs.push(idx);
    } else {
      removes[key] = idx;
      // console.log('removing', d);
    }
    return d;
  });

  deleteFromDiffs.forEach((idx, num) => {
    arrResult.splice(idx - num, 1);
  });

  return arrResult;

  // diffFiles(to.files, from && from.files, second && second.files);

  // function diffFiles(toHash, fromHash, secondHash) {
  //   const files = get(toHash);
  //   const fromFiles = fromHash ? get(fromHash) : {};
  //   const secondFiles = secondHash ? get(secondHash) : {};
  //   // console.log('diffFiles', files, fromFiles, secondFiles);
  //   // TODO: Remove files?

  //   Object.keys(files).forEach(id => {
  //     if (!fromFiles[id]) addFile(Object.assign({ id }, get(files[id])));
  //     else if (fromFiles[id] !== files[id]) throw new Error('Update file');
  //   });

  //   Object.keys(secondFiles).forEach(id => {
  //     if (!fromFiles[id]) addFile(get(secondFiles[id]));
  //     else if (fromFiles[id] !== secondFiles[id]) throw new Error('Update file');
  //   });
  // }
}
