import {
  EvaluateOptions,
  ImagePrimitive,
  ImageUrlOptions,
  OperatorData,
  OperatorDefinition,
  Org,
  TextureSizeValues,
} from '../../types';
import queries from '../../queries';
import * as THREE from 'three';
import { useLoader } from '@react-three/fiber';

// import { getActiveOrg } from '@threekit/orgs';
import { sRGBEncoding, RGBM16Encoding } from 'three';
import { MISSING_ORIG_SIZE } from '../constants';
import { imageBaseSchema, imageBaseUpdate } from './ImageBase';
import { ImagePlug } from './ImagePlug';

const sizeOptions = [
  { label: 'Organization', value: TextureSizeValues.Organization },
  { label: '512', value: TextureSizeValues.p512 },
  { label: '1k', value: TextureSizeValues.p1k },
  { label: '2k', value: TextureSizeValues.p2k },
  { label: '4k', value: TextureSizeValues.p4k },
];

const vraySizeOptions = [
  { label: 'Organization', value: TextureSizeValues.Organization },
  { label: 'Original', value: TextureSizeValues.Original },
  { label: '512', value: TextureSizeValues.p512 },
  { label: '1k', value: TextureSizeValues.p1k },
  { label: '2k', value: TextureSizeValues.p2k },
  { label: '4k', value: TextureSizeValues.p4k },
];

enum Platform {
  WEBGL = 0,
  AR = 1,
  VRAY = 2,
}

const platformProperty = {
  [Platform.WEBGL]: 'webglSize',
  [Platform.AR]: 'arSize',
  [Platform.VRAY]: 'vraySize',
};

// const getPlatform = ({ render, export: exportFormat }: PlayerContext) => {
//   if (exportFormat === 'gltf' || exportFormat === 'usdz') {
//     return Platform.AR;
//   } else if (render === 'vray') {
//     return Platform.VRAY;
//   }
//   return Platform.WEBGL;
// };

const defaultTextureSizes = {
  webglSize: TextureSizeValues.p4k,
  arSize: TextureSizeValues.p4k,
  vraySize: TextureSizeValues.Original,
};

// async function getOrgPlayerSettings(store: ThreekitStore) {
//   const activeOrg = getActiveOrg(store);
//   const playerSettings = activeOrg?.org?.playerSettings;
//   if (!playerSettings) {
//     return defaultTextureSizes;
//   }
//   return { ...defaultTextureSizes, ...playerSettings };
// }

const getSize = (operator: OperatorData, platform: Platform, org: Org) => {
  const property = platformProperty[platform];
  let size = operator[property];
  if (size === TextureSizeValues.Organization) {
    // @ts-ignore
    size = org.playerSettings[property] || defaultTextureSizes[property];
  }
  return size as TextureSizeValues;
};

const isPower2 = (x: number) => (x & (x - 1)) === 0;

const getImageUrlOptions = (operator: any, size: TextureSizeValues) => {
  if (size === TextureSizeValues.Original) {
    return undefined;
  }

  const { originalWidth, originalHeight } = operator;
  if (originalWidth !== MISSING_ORIG_SIZE) {
    const maxDim = Math.max(originalWidth, originalHeight);
    if (maxDim <= size && isPower2(originalWidth) && isPower2(originalHeight)) {
      return undefined;
    }
  }

  return { power2: size } as ImageUrlOptions;
};

const selectSource = (
  operator: any,
  size: TextureSizeValues,
  platform: Platform
) => {
  const {
    glBitmapFile,
    originalBitmapFile,
    rendererBitmapFile,
    hdrBitmapFile,
  } = operator;
  switch (platform) {
    case Platform.VRAY:
      return size === TextureSizeValues.Original
        ? rendererBitmapFile || originalBitmapFile // to be backward compatible
        : hdrBitmapFile || rendererBitmapFile || originalBitmapFile;
    case Platform.WEBGL:
    case Platform.AR:
      return (
        hdrBitmapFile ||
        glBitmapFile ||
        rendererBitmapFile ||
        originalBitmapFile
      );
  }
};

const getSourceAndOptions = (operator: OperatorData, opts: EvaluateOptions) => {
  // const platform = getPlatform(playerCtx);
  const platform = Platform.WEBGL;
  const size = getSize(operator, platform, opts.org);
  const options = getImageUrlOptions(operator, size);
  const source = selectSource(operator, size, platform);
  return {
    source,
    options,
  };
};

//------------------------------------------------------------

export const Image: OperatorDefinition = {
  schema: {
    ...imageBaseSchema,
    originalBitmapFile: { type: 'Image', hidden: true },
    // Dimensions of the originalBitmapFile
    originalWidth: {
      type: 'Number',
      hidden: true,
      defaultValue: MISSING_ORIG_SIZE,
    },
    originalHeight: {
      type: 'Number',
      hidden: true,
      defaultValue: MISSING_ORIG_SIZE,
    },

    glBitmapFile: { type: 'Image', hidden: true },
    rendererBitmapFile: { type: 'Image', hidden: true },

    // TODO: migrate images with hdrBitmapFile to use glBitmapFil with glBitmapEncoding === RGBM16
    hdrBitmapFile: { type: 'Image', hidden: true },

    webglSize: {
      type: 'Options',
      defaultValue: TextureSizeValues.Organization,
      options: sizeOptions,
      label: 'WebGL',
    },
    arSize: {
      type: 'Options',
      defaultValue: TextureSizeValues.Organization,
      options: sizeOptions,
      label: 'AR',
    },
    vraySize: {
      type: 'Options',
      defaultValue: TextureSizeValues.Organization,
      options: vraySizeOptions,
      label: 'V-ray',
    },
  },

  update(
    operator: OperatorData,
    primitive: ImagePrimitive,
    opts: EvaluateOptions
  ) {
    // console.log('Image operator update?', operator, primitive, opts);
    // imageBaseUpdate(operator, primitive);

    if (!operator.originalBitmapFile) {
      // primitive.image = null;
      return primitive;
    }
    const sourceAndUrl = getSourceAndOptions(operator, opts);
    if (!sourceAndUrl) {
      return primitive;
    }
    const { source, options } = sourceAndUrl;

    const url = queries.files.imageUrl(opts.source, source, options);
    // console.log('sourceAndUrl', sourceAndUrl, url);
    if (!opts.skipFiles) {
      primitive.texture = useLoader(THREE.TextureLoader, url);
      primitive.texture.wrapS = THREE.RepeatWrapping;
      primitive.texture.wrapT = THREE.RepeatWrapping;
    }

    // const { hdrBitmapFile, originalWidth, originalHeight } = operator;

    // if (!sourceAndUrl) {
    //   primitive.image = null;
    //   return primitive;
    // }

    // const { source, options } = sourceAndUrl;

    // primitive.image = await getOrFetchImageAsync(
    //   store,
    //   source,
    //   options,
    //   player
    // );
    // primitive.imageEncoding =
    //   source === hdrBitmapFile ? RGBM16Encoding : sRGBEncoding;
    // primitive.rawAssetHash = source.hash;
    // primitive.filename = source.filename;
    // primitive.originalWidth =
    //   originalWidth !== MISSING_ORIG_SIZE ? originalWidth : null;
    // primitive.originalHeight =
    //   originalHeight !== MISSING_ORIG_SIZE ? originalHeight : null;

    return primitive;
  },
};
