import { Vector3 } from 'three';

import ObjectArrayView from '../../generic/container/ObjectArrayView';
import PolyMesh from '../model/PolyMesh';
import PolyMap from '../model/PolyMap';
import { recalculateNormalsFromConnectivity } from './normals';

export default function setNormalsFromSmoothingGroups(
  polyMesh: PolyMesh,
  smoothingGroups: Int32Array
) {
  if (!polyMesh || !smoothingGroups) return polyMesh;

  const faceRangeOffsets = polyMesh.faceRangeOffsets;
  if (faceRangeOffsets.length !== smoothingGroups.length + 1) {
    console.error(
      'Number of smoothing groups does not match number of faces: ',
      faceRangeOffsets.length - 1,
      smoothingGroups.length
    );
    return polyMesh;
  }

  const faceValueIndices = polyMesh.positions.faceValueIndices;
  const nbVertices = polyMesh.positions.values.length;
  const normalMapFaceValueIndices = new Uint32Array(
    polyMesh.positions.faceValueIndices.length
  );

  const valueAdjacency = polyMesh.positions.valueAdjacency;
  const faceFaceAdjacency = polyMesh.positions.faceFaceAdjacency;

  let normalIndex = 0;

  const setNormalIndex = (vertexIndex: number, face: number) => {
    const faceArray = [];
    for (let j = faceRangeOffsets[face]; j < faceRangeOffsets[face + 1]; ++j) {
      const fvi = faceValueIndices[j];
      faceArray.push(fvi);
      if (fvi === vertexIndex) {
        normalMapFaceValueIndices[j] = normalIndex;
        return;
      }
    }

    console.error(`index ${vertexIndex} is not on face ${face}`, faceArray);
  };

  // for every vertex
  for (let i = 0; i < nbVertices; ++i) {
    const numFacesOnVertex = valueAdjacency.getNumFacesForVertex(i);
    const facesOnVertex = new Array<number>(numFacesOnVertex);
    const faceNormalMapping = new Array<number>(numFacesOnVertex).fill(-1);

    // get faces on vertex
    for (let j = 0; j < numFacesOnVertex; ++j) {
      facesOnVertex[j] = valueAdjacency.getFaceForVertexId(i, j);
    }

    // for every face around vertex
    for (let j = 0; j < numFacesOnVertex; ++j) {
      if (faceNormalMapping[j] > -1) continue;

      const startFace = facesOnVertex[j];
      const startFaceAdjacentFaces = faceFaceAdjacency.adjacentFacesOnVertex(
        startFace,
        i
      ) as number[];

      const facesInGroup = [startFace];

      let current = startFace;
      let next = startFaceAdjacentFaces[0];

      // walk around the left, and then the right
      for (let k = 0; k < startFaceAdjacentFaces.length; ++k) {
        // walk around the faces around the vertex
        for (let f = 0; f < numFacesOnVertex; ++f) {
          if (
            next === startFace ||
            faceNormalMapping[facesOnVertex.indexOf(next)] > -1 ||
            (smoothingGroups[current] & smoothingGroups[next]) === 0
          ) {
            // full circle, already allocated or hard edge
            break;
          }

          // face add to smoothing group
          facesInGroup.push(next);
          const nextAdjacentFaces = faceFaceAdjacency.adjacentFacesOnVertex(
            next,
            i
          ) as number[];

          // edge found
          if (nextAdjacentFaces.length < 2) break;

          const nextNext =
            nextAdjacentFaces[0] === current
              ? nextAdjacentFaces[1]
              : nextAdjacentFaces[0];
          current = next;
          next = nextNext;
        }

        // walk the other way on the next
        current = startFace;
        next = startFaceAdjacentFaces[1];
      }

      for (const fig of facesInGroup) {
        setNormalIndex(i, fig);
        faceNormalMapping[facesOnVertex.indexOf(fig)] = normalIndex;
      }
      ++normalIndex;
    }
  }

  const normalMap = new PolyMap({
    faceRangeOffsets: polyMesh.faceRangeOffsets,
    faceValueIndices: normalMapFaceValueIndices,
    values: new ObjectArrayView(Vector3, normalIndex),
  });
  const resultMesh = new PolyMesh(polyMesh, { normalMap });
  return recalculateNormalsFromConnectivity(resultMesh);
}
