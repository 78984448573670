export const CUSTOM_FILM_SIZE = -1;
export const DEFAULT_FILM_SIZE = 0;

// You can reorder them to change the option order, but the Ids must stay fixed.
const FilmSizes = [
  {
    id: CUSTOM_FILM_SIZE,
    name: '[ custom ]',
    width: -1,
    height: -1,
  },
  {
    id: 0,
    name: 'Full Frame',
    width: 36,
    height: 24,
  },
  {
    id: 1,
    name: 'IMAX',
    width: 70,
    height: 48.5,
  },
  {
    id: 2,
    name: 'Medium Format',
    width: 50.7,
    height: 39,
  },
  {
    id: 3,
    name: 'APS-C',
    width: 23.6,
    height: 15.6,
  },
  {
    id: 4,
    name: 'APS-C Canon',
    width: 22.2,
    height: 14.8,
  },
  {
    id: 5,
    name: 'Micro Four Thirds',
    width: 17.3,
    height: 13,
  },
  {
    id: 6,
    name: '1"',
    width: 12.8,
    height: 9.6,
  },
  {
    id: 7,
    name: '1/1.2"',
    width: 10.67,
    height: 8,
  },
  {
    id: 8,
    name: '1/2.0"',
    width: 6.4,
    height: 4.8,
  },
  {
    id: 9,
    name: '1/2.3"',
    width: 6.17,
    height: 4.55,
  },
  {
    id: 10,
    name: '1/2.5"',
    width: 5.76,
    height: 4.29,
  },
  {
    id: 11,
    name: '1/2.55"',
    width: 5.75,
    height: 4.32,
  },
  {
    id: 12,
    name: '1/2.6"',
    width: 5.5,
    height: 4.1,
  },

  {
    id: 13,
    name: '1/3"',
    width: 4.8,
    height: 3.6,
  },
  {
    id: 14,
    name: '1/3.2"',
    width: 4.54,
    height: 3.42,
  },
];

export function getFilmSizeOptions() {
  return FilmSizes.map(fs => ({
    label: fs.name,
    value: fs.id,
  }));
}

// Doesn't need to be super efficient since we only have 20 sizes
export function getFilmSize(id: number) {
  return FilmSizes.find(fs => fs.id === id);
}
