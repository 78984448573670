import Arrays from '../utility/Arrays';
import FlatArrayView from './FlatArrayView';
import ObjectBuffer from './ObjectBuffer';

/**
 * Object view mapping fixed size objects to flat arrays.
 *
 * @constructor
 *
 * @param {!function(new:*)} type
 *
 *    object constructor
 *
 * @param {number} length
 *
 *    number of objects to map,
 *    can be null when an existing array is given,
 *
 * @param {ArrayOrType=} optionalArrayOrType
 *
 *    existing array or array constructor, default is Float32Array
 *
 * @param {number=} optionalStride
 *
 *    an explicit stride for cases where it differs from the element size
 *    deduced from {@code type.InstanceScalarSize}
 */
export default function ObjectArrayView(
  type,
  length,
  optionalArrayOrType,
  optionalStride
) {
  const elementSize = type.InstanceScalarSize || optionalStride;
  const stride = optionalStride || elementSize;
  const arrayLength = length !== null ? length * stride : null;

  if (elementSize === undefined || stride === undefined)
    throw Error("Can't determine element size / stride!");

  const existingArrayOrType = optionalArrayOrType || Float32Array;

  const array = Arrays.maybeCreate(existingArrayOrType, arrayLength);

  FlatArrayView.call(
    this,
    array,
    length ||
      // Note: Integer division by 'stride', rounding towards infinity
      // at a minimum remainder of 'elementSize':
      ((array.length + stride - elementSize) / stride) | 0
  );

  this.type = type;
  this.stride = stride;
  this.elementSize = elementSize;
  this.tempBuffer = new Float32Array(elementSize);
}

ObjectArrayView.prototype = Object.assign(
  Object.create(FlatArrayView.prototype),
  {
    constructor: ObjectArrayView,

    getAt: function(index, optionalValue) {
      const result = optionalValue || new this.type();
      return result.fromArray(this.data, index * this.stride);
    },

    setAt: function(index, value) {
      value.toArray(this.data, index * this.stride);
    },

    addAt: function(index, value) {
      value.toArray(this.tempBuffer, 0);
      for (let i = 0, offset = index * this.stride; i < this.elementSize; i++) {
        this.data[offset + i] += this.tempBuffer[i];
      }
    },

    multiplyScalarAt: function(index, scalar) {
      for (let i = 0, offset = index * this.stride; i < this.elementSize; i++) {
        this.data[offset + i] *= scalar;
      }
    },

    newCompatibleView: function(arrayOrType, optionalLength) {
      return new ObjectArrayView(
        this.type,
        optionalLength || null,
        arrayOrType,
        this.stride
      );
    },

    newCompatibleBuffer: function() {
      return new ObjectBuffer(this.type, this.data.constructor, this.stride);
    },
  }
);

ObjectArrayView.fromObjects = function(type, objects, optionalArrayType) {
  const result = new ObjectArrayView(type, objects.length, optionalArrayType);

  Arrays.copyObjects(objects, result.data);
  return result;
};
