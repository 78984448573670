import { v4 as uuid } from 'uuid';
import { Path } from './schemas';

type HierarchyEntry =
  | string
  | HierarchyEntry[]
  | {
      [key: string]: HierarchyEntry;
    };

export type Hierarchy = {
  [id: string]: HierarchyEntry;
};

export type IdMap = { [key: string]: string };
type IdMapper = (id: string) => string;

export const mapIds = (map: IdMap = {}): IdMapper => (id) => {
  if (map[id] == null) map[id] = uuid();
  return map[id];
};

export const mapHierarchyIds = (idMapper: IdMapper) => (
  entry: HierarchyEntry
): HierarchyEntry => {
  if (typeof entry === 'string') {
    return idMapper(entry);
  }
  if (Array.isArray(entry)) {
    return entry.map(mapHierarchyIds(idMapper));
  }

  const result: HierarchyEntry = {};
  for (const key of Object.keys(entry)) {
    result[key] = mapHierarchyIds(idMapper)(entry[key]);
  }
  return result;
};

// descend into an object structure by path and set the value
export function setIn(obj: any, path: Path, value: any) {
  for (let i = 0; i < path.length - 1; i++) {
    const segment = path[i];
    const next = path[i + 1];
    if (obj[segment] == null) {
      obj[segment] = typeof next === 'number' ? [] : {};
    }
    obj = obj[segment];
  }
  obj[path[path.length - 1]] = value;
}

// descend into an object structure by path:
// getIn({ one: [1,2] }, ['one',1]) === 2
export function getIn(
  obj: any,
  path: Path,
  id?: string,
  ignoreMissing?: boolean
) {
  if (path.length === 0) return id;
  let r = obj;

  for (const segment of path) {
    if (!r) {
      if (ignoreMissing) return undefined;
      throw new Error(`Can not get path ${JSON.stringify(path)} in ${obj}`);
    }
    r = r[segment];
  }
  return r;
}
