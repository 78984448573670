import { Vector3 } from 'three';
import { maxMeshTriangles } from './maxPolymeshTriangles';

export default function SimpleFanTriangulation(mesh) {
  this.mesh = mesh;
  this.groups = null;

  const { maxNumTriangles, maxNumVerticesPerFace } = maxMeshTriangles(mesh);

  this.startFaceIndices = this.getStartFaceIndices(
    mesh.positions,
    maxNumVerticesPerFace
  );
  //console.log( 'this.startFaceIndices', this.startFaceIndices );

  this.numTriangles = maxNumTriangles;
}

SimpleFanTriangulation.prototype = {
  constructor: SimpleFanTriangulation,

  getStartFaceIndices: function (positionsPolyMap, maxNumVerticesPerFace) {
    //return an array of size faces, that says which face vertex index to use as the starting vertex in simple fan triangulation.
    // you will want return the first convex vertex face vertex index (in the range 0 to number of vertices in that face), if there is one
    // otherwise you can return 0.

    var faceOffsets = this.mesh.faceRangeOffsets;

    var numFaces = this.mesh.faceRangeOffsets.length - 1;
    var startFaceIndices = new Uint32Array(numFaces);
    var faceEdgeSign = new Float32Array(maxNumVerticesPerFace);

    var faceValueIndices = positionsPolyMap.faceValueIndices;
    var positionValues = positionsPolyMap.values;

    var dPrev = new Vector3();
    var d = new Vector3();
    var dNext = new Vector3();
    var dTemp;

    var pPrev = new Vector3();
    var p = new Vector3();
    var pNext = new Vector3();
    var pNextNext = new Vector3();
    var pTemp;

    var cpTemp;
    var cp = new Vector3();
    var cpNext = new Vector3();

    for (var i = 0, il = faceOffsets.length - 1; i < il; ++i) {
      var faceBegin = faceOffsets[i];
      var faceUntil = faceOffsets[i + 1];
      var numFaceVertices = faceUntil - faceBegin;

      if (numFaceVertices <= 3) {
        continue;
      }

      var vPrev;
      var v = faceValueIndices[faceBegin + (numFaceVertices - 1)];
      var vNext = faceValueIndices[faceBegin];
      var vNextNext = faceValueIndices[faceBegin + (1 % numFaceVertices)];

      positionValues.getAt(v, p);
      positionValues.getAt(vNext, pNext);
      positionValues.getAt(vNextNext, pNextNext);

      d.copy(pNext).sub(p);
      dNext.copy(pNextNext).sub(pNext);

      cpNext.copy(dNext).cross(d);

      for (var j = 0; j < numFaceVertices; ++j) {
        vPrev = v;
        v = vNext;
        vNext = vNextNext;
        vNextNext = faceValueIndices[faceBegin + ((j + 2) % numFaceVertices)];

        pTemp = pPrev;
        pPrev = p;
        p = pNext;
        pNext = pNextNext;
        pNextNext = pTemp;
        positionValues.getAt(vNextNext, pNextNext);

        dTemp = dPrev;
        dPrev = d;
        d = dNext;
        dNext = dTemp;
        dNext.copy(pNextNext).sub(pNext);

        cpTemp = cp;
        cp = cpNext;
        cpNext = cpTemp;
        cpNext.copy(dNext).cross(d);

        var sign = cp.dot(cpNext);

        faceEdgeSign[j] = sign;
      }

      //console.log( 'faceEdgeSign for face: ', i, faceEdgeSign );

      for (var j = 0; j < numFaceVertices; ++j) {
        var sign = faceEdgeSign[j];
        var signNext = faceEdgeSign[(j + 1) % numFaceVertices];
        if (sign >= 0 && signNext >= 0) {
          startFaceIndices[i] = (j + 1) % numFaceVertices;
          break;
        }
      }
    }

    return startFaceIndices;
  },

  translateIndices: function (inputArray, outputView) {
    const MAX_MATERIAL_IDS = 30;

    var faceOffsets = this.mesh.faceRangeOffsets,
      outputArray = outputView.data,
      writeIndex = outputView.offset,
      stride = outputView.stride;

    var materialIds = null;
    var polygonOrder = null;
    var groupMin = null;
    var groupMax = null;

    // if multi-materials, sort polygons in material order and create material groups
    if (this.mesh.materialIds) {
      materialIds = this.mesh.materialIds;

      var numPolygons = faceOffsets.length - 1;
      var polygonOrder = new Uint32Array(numPolygons);
      for (var i = 0; i < polygonOrder.length; i++) {
        polygonOrder[i] = i;
      }

      polygonOrder.sort(function (a, b) {
        return materialIds[a] - materialIds[b];
      });
      //----FOR VRAY MATERIAL, which only has 0 and 4294967295:
      /*
      var originalToCompactMaterialIds = {};
      var nextCompactMaterialId = 0;
      var compactMaterialIds = new Uint32Array( numPolygons );
      for(var i = 0; i < materialIds.length; i++) {
        var originalMaterialId = materialIds[i];
        if( originalToCompactMaterialIds[originalMaterialId] === undefined ) {
          originalToCompactMaterialIds[originalMaterialId] = nextCompactMaterialId ++;
        }
        compactMaterialIds[i] = originalToCompactMaterialIds[originalMaterialId];
      }*/

      //-------------------------------------------------------------------------------
      var numMaterials = Math.min(
        materialIds[polygonOrder[numPolygons - 1]] + 1,
        MAX_MATERIAL_IDS
      );

      groupMin = new Uint32Array(numMaterials);
      for (var i = 0; i < numMaterials; i++) {
        groupMin[i] = outputArray.length;
      }
      groupMax = new Uint32Array(numMaterials);
    }

    for (var i = 0; i < this.startFaceIndices.length; ++i) {
      var polygonIndex = i;
      if (polygonOrder) {
        polygonIndex = polygonOrder[i];
      }

      var startOffset = this.startFaceIndices[polygonIndex];
      var startIndex = faceOffsets[polygonIndex];
      var endIndex = faceOffsets[polygonIndex + 1];
      var polygonLength = endIndex - startIndex;

      for (var j = 0; j < polygonLength - 2; ++j) {
        if (materialIds) {
          var materialId = Math.min(
            materialIds[polygonIndex],
            MAX_MATERIAL_IDS - 1
          );
          groupMin[materialId] = Math.min(groupMin[materialId], writeIndex);
          groupMax[materialId] = Math.max(
            groupMax[materialId],
            writeIndex + stride * 2
          );
        }

        outputArray[writeIndex] = inputArray[startIndex + startOffset];
        writeIndex += stride;

        outputArray[writeIndex] =
          inputArray[startIndex + ((startOffset + j + 1) % polygonLength)];
        writeIndex += stride;

        outputArray[writeIndex] =
          inputArray[startIndex + ((startOffset + j + 2) % polygonLength)];
        writeIndex += stride;
      }
    }

    if (materialIds) {
      // convert max to length in place
      for (var i = 0; i < groupMax.length; i++) {
        groupMax[i] = groupMax[i] - groupMin[i] + 1;
      }
      this.groups = {
        groupStarts: groupMin,
        groupCounts: groupMax,
      };
    }
  },

  translateMaterialIds: function (outputView) {
    var faceOffsets = this.mesh.faceRangeOffsets,
      inputArray = this.mesh.materialIds,
      faceBegin = faceOffsets[0];

    for (var i = 1, j = 0, n = faceOffsets.length; i < n; ++i) {
      var materialId = inputArray[i - 1],
        faceUntil = faceOffsets[i],
        nVertices = faceUntil - faceBegin,
        nTriangles = nVertices >= 3 ? nVertices - 2 : 0;

      for (
        var writeUntil = writeIndex + nTriangles * stride;
        writeIndex !== writeUntil;
        writeIndex += stride
      )
        outputArray[writeIndex] = materialId;

      faceBegin = faceUntil;
    }
  },

  translateToUnindexedValues: function (inputView, outputView, indices) {
    var faceOffsets = this.mesh.faceRangeOffsets,
      writeIndex = 0,
      temp = inputView.newRangeArray(1);

    var materialIds = null;
    var polygonOrder = null;
    var groupMin = null;
    var groupMax = null;

    // if multi-materials, sort polygons in material order and create material groups
    if (this.mesh.materialIds) {
      materialIds = this.mesh.materialIds;

      var numPolygons = faceOffsets.length - 1;
      var polygonOrder = new Uint32Array(numPolygons);
      for (var i = 0; i < polygonOrder.length; i++) {
        polygonOrder[i] = i;
      }

      polygonOrder.sort(function (a, b) {
        return materialIds[a] - materialIds[b];
      });

      var numMaterials = materialIds[polygonOrder[numPolygons - 1]] + 1;

      groupMin = new Uint32Array(numMaterials);
      for (var i = 0; i < numMaterials; i++) {
        groupMin[i] = outputView.length;
      }
      groupMax = new Uint32Array(numMaterials);
    }

    for (var i = 0; i < this.startFaceIndices.length; ++i) {
      var polygonIndex = i;
      if (polygonOrder) {
        polygonIndex = polygonOrder[i];
      }

      var startOffset = this.startFaceIndices[polygonIndex];
      var startIndex = faceOffsets[polygonIndex];
      var endIndex = faceOffsets[polygonIndex + 1];
      var polygonLength = endIndex - startIndex;

      for (var j = 0; j < polygonLength - 2; ++j) {
        if (materialIds) {
          var materialId = materialIds[polygonIndex];
          groupMin[materialId] = Math.min(groupMin[materialId], writeIndex);
          groupMax[materialId] = Math.max(groupMax[materialId], writeIndex + 2);
        }

        outputView.arrayToRange(
          inputView.rangeToArray(indices[startIndex + startOffset], 1, temp),
          writeIndex,
          1
        );

        outputView.arrayToRange(
          inputView.rangeToArray(
            indices[startIndex + ((startOffset + j + 1) % polygonLength)],
            1,
            temp
          ),
          writeIndex + 1,
          1
        );

        outputView.arrayToRange(
          inputView.rangeToArray(
            indices[startIndex + ((startOffset + j + 2) % polygonLength)],
            1,
            temp
          ),
          writeIndex + 2,
          1
        );

        writeIndex += 3;
      }
    }

    if (materialIds) {
      // convert max to length in place
      for (var i = 0; i < groupMax.length; i++) {
        groupMax[i] = groupMax[i] - groupMin[i] + 1;
      }
      this.groups = {
        groupStarts: groupMin,
        groupCounts: groupMax,
      };
    }
  },
};
