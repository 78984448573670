import BlockBuffer from './BlockBuffer';
import ObjectArrayView from './ObjectArrayView';

export default function ObjectBuffer(
  type,
  optionalArrayType,
  optionalElementSize
) {
  BlockBuffer.call(this, optionalArrayType || Float32Array);

  this.elementSize = optionalElementSize || type.InstanceScalarSize;
  this.type = type;
}

ObjectBuffer.prototype = Object.assign(Object.create(BlockBuffer.prototype), {
  constructor: ObjectBuffer,

  toObjectArrayViewAndClear: function() {
    return new ObjectArrayView(this.type, null, this.toArrayAndClear());
  },

  getAt: function(index, optionalValue) {
    const result = optionalValue || new this.type();

    const blockIndex = index >> this.intraBlockIndexBits;
    const blockOffset = index & this.intraBlockIndexMask;

    return result.fromArray(
      this.blocks[blockIndex],
      blockOffset * this.elementSize
    );
  },

  setAt: function(index, value) {
    const blockIndex = index >> this.intraBlockIndexBits;
    const blockOffset = index & this.intraBlockIndexMask;

    value.toArray(this.blocks[blockIndex], blockOffset * this.elementSize);
  },

  push: function(value) {
    const index = this.length++;
    let block = this.lastBlock;
    const blockOffset = index & this.intraBlockIndexMask;

    if (blockOffset === 0) {
      const blockSize = this.intraBlockIndexMask + 1;
      block = new this.arrayType(blockSize * this.elementSize);
      this.lastBlock = block;
      this.blocks.push(block);
    }

    value.toArray(block, blockOffset * this.elementSize);
    //Return new size
    return index + 1;
  },
});
