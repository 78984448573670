import RangeSet from '../../generic/utility/RangeSet';

const rangeRE = /^\s*(\d+)\s*(?:-\s*(\d+)\s*)?$/;

export interface StringIndicesToArrayOptions {
  verbose?: boolean;
  maxIndex?: number;
}

/**
 * Split string on ',' and build a sorted RangeSet of the individual values and
 * ranges, then return expanded array with all enumerated indices.
 *
 * @param str
 * @param options
 */
export default function stringIndicesToArray(
  str: string = '',
  options?: StringIndicesToArrayOptions
) {
  options = options || {};

  if (typeof str !== 'string') return [];
  if (!str.length) return [];

  const indices = str.split(',');
  let rangeSegment;
  const range = new RangeSet(options.maxIndex);

  for (let i = 0; i < indices.length; i++) {
    rangeSegment = indices[i];
    if (!rangeSegment) {
      continue;
    } // Ignore empty strings

    const match = rangeRE.exec(rangeSegment);
    if (match) {
      let start = parseInt(match[1]);
      let end = match[2] === undefined ? start : parseInt(match[2]);

      if (start <= end) {
        range.add(start, end);
      } else {
        if (options.verbose !== false) {
          console.warn(
            `Misformatted range segment'${rangeSegment}' will be ignored. End value ${end} should not be less than start value ${start}.`
          );
        }
      }
    } else {
      if (options.verbose !== false) {
        console.warn(
          `Misformatted range segment'${rangeSegment}' will be ignored. Ranges should constitute only of positive integers, dashes, and commas. E.g.: '1,3,5-6,9'.`
        );
      }
    }
  }

  return range.expanded();
}

// not needed if regex is ensuring adjacent digits only, parseInt('01')=0, so all bases are covered
// function parsePositiveInteger(str: string): number | false {
//   if(str == null || str === '') return false; // undefined is caught by == null
//   const n = parseFloat(str);
//   if (n !== Number(str)) return false; // eg. parseFloat("3 4") = 3. Let's not allow that
//   if (isNaN(n)) return false;
//   if (n % 1 !== 0) return false;
//   if (!isFinite(n)) return false;
//   if (n < 0) return false;
//   return n;
// }
