import {
  EvaluateOptions,
  OperatorData,
  OperatorDefinition,
  PropertiesPrimitive,
} from '../../types';
import { arExportable } from './Default';

const ModelProperties: OperatorDefinition = {
  label: 'Model Properties',
  schema: {
    visible: {
      label: 'Visible',
      type: 'Boolean',
      defaultValue: true,
      animatable: true,
    },
    arExportable,
    renderable: { label: 'Renderable', type: 'Boolean', defaultValue: true },
    selectable: { label: 'Selectable', type: 'Boolean', defaultValue: true },
    overrideChildSelection: {
      label: 'Override Child Selection',
      type: 'Boolean',
      defaultValue: true,
    },
    configurator: {
      label: 'Configurator',
      type: 'String',
      hidden: true,
      defaultValue: '',
    },
  },
  update(
    op: OperatorData,
    primitive: PropertiesPrimitive,
    opts: EvaluateOptions
  ) {
    primitive.visible = op.visible;
    return primitive;
  },
};

export default ModelProperties;
