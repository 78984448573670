import { Vector3, Matrix4, Euler } from 'three';
import PolyMap from '../model/PolyMap';
import PolyMesh from '../model/PolyMesh';
import PolyMaps from '../model/PolyMaps';
import { recalculateNormalsFromConnectivity } from '../../geometric/operator/normals';

/**
 * Apply bend on vertices
 *
 * @param vertices - vertices
 * @param defaultAxis - axis to use
 * @param bendAngle - bend angle
 * @param rotation - rotation of the source
 * @param translation - translation of the data
 * @param fixedEndsEnabled - use fixed ends
 * @return true if the operation is complete and normals can be recomputed
 */
export function bendVertices(
  vertices,
  defaultAxis,
  bendAngle,
  rotation,
  translation,
  fixedEndsEnabled
) {
  const translationMat4 = new Matrix4().makeTranslation(
    translation.x,
    translation.y,
    translation.z
  );

  //transformations
  const rotMat4 = new Matrix4()
    .makeRotationFromEuler(new Euler().setFromVector3(rotation))
    .multiply(getDefaultAxisRotation(defaultAxis));
  //translation first
  const transform = new Matrix4().multiplyMatrices(rotMat4, translationMat4);
  const invTransform = new Matrix4().copy(transform).invert();

  const v = new Vector3();
  //put mesh positions into bend transform space before calculating maxLenY
  for (let i = 0; i < vertices.length; ++i) {
    vertices.getAt(i, v);
    v.applyMatrix4(transform);
    vertices.setAt(i, v);
  }

  bendAngle = -bendAngle; // make clockwise the positive direction
  const maxLenY = findLengthY(vertices.data);
  if (maxLenY < 0.000001) {
    return false;
  }
  //Note: when fixedEndsEnabled == false:
  //   the length of the bent object remains same - the ends move to accommodate
  //Note: when fixedEndsEnabled == true,
  //   the length of the bent object changes, so that the ends can remain in the same place
  let radius = maxLenY / bendAngle; // radius of circle the object is bending around (arc length formula)
  let displacement = 0;
  if (fixedEndsEnabled) {
    radius = (0.5 * maxLenY) / Math.sin(bendAngle / 2);
    displacement = Math.tan(bendAngle / 4) * maxLenY * 0.5;
  }

  let theta, sint, cost;
  for (let i = 0; i < vertices.length; ++i) {
    vertices.getAt(i, v);

    //theta is proportional to v.y, so v.y == maxLen/2 gives bendAngleRad/2
    theta = (v.y * bendAngle) / maxLenY;
    sint = Math.sin(theta);
    cost = Math.cos(theta);

    v.y = v.x * sint + sint * radius;
    v.x = v.x * cost + cost * radius - radius;
    v.x += displacement;

    v.applyMatrix4(invTransform);

    vertices.setAt(i, v);
  }
  return true;
}

export default function bendPolyMesh(
  mesh,
  defaultAxis,
  bendAngle,
  rotation,
  translation,
  fixedEndsEnabled
) {
  //bend angle 0 results in infinite radius for circle the object bends around (i.e. straight line instead of circle)
  if (bendAngle === 0) {
    return mesh;
  }

  //clone positions
  mesh = new PolyMesh(mesh);
  let mapId = PolyMaps.IdPositions;
  let newPolyMap = new PolyMap(PolyMaps.resolveMap(mesh, mapId));
  let newMapValues = newPolyMap.values.clone();
  newPolyMap.values = newMapValues;
  PolyMaps.assignMap(mesh, mapId, newPolyMap);
  //clone normals
  mapId = PolyMaps.IdNormals;
  newPolyMap = new PolyMap(PolyMaps.resolveMap(mesh, mapId));
  newMapValues = newPolyMap.values.clone();
  newPolyMap.values = newMapValues;
  PolyMaps.assignMap(mesh, mapId, newPolyMap);

  if (
    bendVertices(
      mesh.positions.values,
      defaultAxis,
      bendAngle,
      rotation,
      translation,
      fixedEndsEnabled
    )
  ) {
    mesh = recalculateNormalsFromConnectivity(mesh);
  }
  return mesh;
}

bendPolyMesh.AxisTypes = {
  X: 1,
  Y: 2,
  Z: 3,
};

function findLengthY(vertices) {
  //every third element (from the second) is the y element
  let min = vertices[1];
  let max = vertices[1];
  for (let i = 4; i < vertices.length; i += 3) {
    if (vertices[i] < min) {
      min = vertices[i];
    }
    if (vertices[i] > max) {
      max = vertices[i];
    }
  }
  return max - min;
}

function getDefaultAxisRotation(axis) {
  //default axis is Z, don't do anything, otherwise rotate
  let axisRotation = new Matrix4();
  switch (axis) {
    case bendPolyMesh.AxisTypes.X:
      axisRotation.makeRotationY(-Math.PI / 2);
      break;
    case bendPolyMesh.AxisTypes.Y:
      axisRotation.makeRotationX(Math.PI / 2);
      break;
  }
  return axisRotation;
}
