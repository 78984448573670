import PolyMaps from '../model/PolyMaps';
import ScalarBuffer from '../../generic/container/ScalarBuffer';
import BitSet from '../../generic/container/BitSet';
import IndexMappings from '../../generic/algorithm/IndexMappings';
import { ConversionTable, DefaultMap } from './convertSelection';
import { NumberArray } from '../../generic/utility/ArrayTypes';
import { ComponentType } from '../../../operators/constants';
import PolyMesh from '../model/PolyMesh';
import stringIndicesToArray from '../operator/stringIndicesToArray';
import PolyMap from '../model/PolyMap';

export default class Selection {
  public _indices: NumberArray; // Immutable

  public get indices(): ArrayLike<number> {
    // public readonly
    return this._indices;
  }

  //

  constructor(
    public readonly mesh: PolyMesh,
    public readonly componentType: ComponentType,
    indices?: NumberArray | string,
    optionalMapId: string = DefaultMap
  ) {
    if (indices) {
      // make sure: empty string or array of indices is valid, only copy from getMeshIndices IFF input indices is undefined
      if (typeof indices === 'string') {
        const maxIndex = this.getMaxIndex(optionalMapId);
        this._indices = stringIndicesToArray(indices, { maxIndex });
      } else {
        this._indices = this.restrictIndicesToMesh(indices, optionalMapId);
      }
    } else {
      this._indices = Selection.getMeshIndices(mesh, componentType, optionalMapId);
    }
  }

  private getMaxIndex(optionalMapId: string) {
    if (this.componentType === ComponentType.FACE) {
      return this.mesh.faceRangeOffsets.length - 1;
    }
    else {
      const map = this.mesh.getMapById(optionalMapId || DefaultMap) as PolyMap<any>;
      if (this.componentType === ComponentType.VERT)
        return map.values.length;
      else if (this.componentType === ComponentType.EDGE)
        return map.edgeVertexAdjacency.getNumEdges();
      else
        throw new Error(`Unexpected component type ${this.componentType}, accepted values must be from ComponentType enum, consisting of [${ComponentType.VERT},${ComponentType.EDGE},${ComponentType.FACE}]`);
    }
  }

  private restrictIndicesToMesh(indices: NumberArray, optionalMapId: string = DefaultMap): NumberArray {
    let maxIndexExclusive = this.getMaxIndex(optionalMapId);

    // don't allocate unless we need to
    let numValidIndices = 0;
    for (const srcIndex of indices) {
      if (srcIndex >= 0 && srcIndex < maxIndexExclusive) {
        numValidIndices++;
      }
    }


    if (numValidIndices === indices.length) {
      return indices;
    }

    // some invalid
    console.warn(`${indices.length - numValidIndices} invalid indice(s) found in range, restricting range`);
    const dstIndices = new Uint32Array(numValidIndices);
    let dstIndex = 0;

    for (const srcIndex of indices) {
      if (srcIndex >= 0 && srcIndex < maxIndexExclusive) {
        dstIndices[dstIndex++] = srcIndex;
      }
    }
    return dstIndices;
  }

  public getIndicesAs(
    componentType: ComponentType = this.componentType,
    optionalSrcMapId?: string,
    optionalDstMapId?: string
  ) {
    const convFunc = ConversionTable[this.componentType][componentType];

    return convFunc
      ? convFunc.call(
        null,
        this.mesh,
        this._indices,
        optionalSrcMapId || DefaultMap,
        optionalDstMapId || optionalSrcMapId || DefaultMap
      )
      : this._indices;
  }

  public getAs(
    componentType: ComponentType,
    optionalSrcMapId?: string,
    optionalDstMapId?: string
  ) {
    return new Selection(
      this.mesh,
      componentType,
      this.getIndicesAs(componentType, optionalSrcMapId, optionalDstMapId)
    );
  }

  // scene-graph/src/polyMesh/attic/cutWithPlane.js
  // scene-graph/src/polyMesh/__test__/showcase.js
  static Faces = ComponentType.FACE;
  static Vertices = ComponentType.VERT;
  static Edges = ComponentType.EDGE;

  public static getMeshIndices(
    mesh: PolyMesh,
    componentType: ComponentType,
    optionalMapId: string = DefaultMap
  ) {
    return IndexMappings.identityForReading(
      Selection.getNumElements(mesh, componentType, optionalMapId)
    );
  }

  public static getNumElements(
    mesh: PolyMesh,
    componentType: ComponentType,
    optionalMapId: string = DefaultMap
  ) {
    switch (componentType) {
      case ComponentType.FACE:
        return mesh.getNumFaces();

      case ComponentType.VERT:
        return PolyMaps.resolveMap(mesh, optionalMapId || DefaultMap).values
          .length;

      case ComponentType.EDGE:
        return PolyMaps.resolveMap(
          mesh,
          optionalMapId
        ).edgeVertexAdjacency.getNumEdges();

      default:
        throw Error('Bad meaning argument!');
    }
  }

  public static newRandomSelection(
    seed: number,
    density: number,
    polyMesh: PolyMesh,
    componentType: ComponentType,
    optionalMapId?: string
  ) {
    var n = Selection.getNumElements(polyMesh, componentType, optionalMapId),
      nPopulation = (density * n) | 0,
      bits = new BitSet(n).fill(0, nPopulation),
      prng = seed;

    for (var i = 0, e = n - 1; i < e; ++i) {
      // Magic constants taken from
      // https://en.wikipedia.org/wiki/Linear_congruential_generator
      prng = (prng * 22695477 + 1) >>> 0;

      bits.swap(i, i + ((prng & 0x3fffffff) % (n - i)));
    }

    return new Selection(polyMesh, componentType, bits.toIndexArray());
  }
}
