import {
  Color,
  BufferGeometry,
  BufferAttribute,
  InterleavedBuffer,
  InterleavedBufferAttribute,
} from 'three';
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  Component,
  ReactNode,
  Suspense,
} from 'react';
import {
  polyMeshToMeshBufferGeometry,
  IndicesMode,
} from '../../polyMesh/ThreeExportPolyMesh';

export default class BinGeometry extends BufferGeometry {
  constructor(cnsPolyMesh: any) {
    super();

    const indexingMode = IndicesMode.CompactIndices;
    const interleaved = false;

    const geoBufferInfo =
      cnsPolyMesh &&
      polyMeshToMeshBufferGeometry(
        cnsPolyMesh,
        indexingMode,
        interleaved,
        cnsPolyMesh.tangentMap,
        null,
        null,
        null,
        null,
        this
      );
    // console.log('create bingeom?', cnsPolyMesh, ThreeExport, geoBufferInfo);
    return geoBufferInfo.geometry;
  }
}
