export default function FaceFaceAdjacency(polyMap) {
  //for each face there is an offset array (faceFaceCounts)
  //this offset array indexes into an array of all faces that are adjacent (faceFaceIndices)

  this.polyMap = polyMap;

  this.faceFaceCounts = null;
  this.faceFaceIndices = null;

  this._compute();
}

FaceFaceAdjacency.prototype = {
  constructor: FaceFaceAdjacency,

  //F->F
  getAdjacentFaces: function (face) {
    let it = this.faceFaceCounts[face];
    const end = this.faceFaceCounts[face + 1];
    const numAdjacentFaces = end - it;
    const adjacentFaces = new Uint32Array(numAdjacentFaces);
    for (let j = 0; it < end; ++it, ++j) {
      adjacentFaces[j] = this.faceFaceIndices[it];
    }
    return adjacentFaces;
  },

  //return array of 0-2 faces
  //valuesIndex is the index of the vertex in the values array
  adjacentFacesOnVertex: function (face, valuesIndex) {
    const adjacentFaces = this.getAdjacentFaces(face);
    const faceRangeOffsets = this.polyMap.faceRangeOffsets;
    const faceValueIndices = this.polyMap.faceValueIndices;
    const adjacentFacesOnVertex = [];

    for (let i = 0; i < adjacentFaces.length; ++i) {
      const adjFace = adjacentFaces[i];
      const end = faceRangeOffsets[adjFace + 1];
      for (let j = faceRangeOffsets[adjFace]; j < end; ++j) {
        if (faceValueIndices[j] === valuesIndex) {
          adjacentFacesOnVertex.push(adjFace);
          break;
        }
      }
    }

    return adjacentFacesOnVertex;
  },

  _compute: function () {
    const faceRangeOffsets = this.polyMap.faceRangeOffsets;
    const faceValueIndices = this.polyMap.faceValueIndices;
    const faceEdgeAdjacency = this.polyMap.faceEdgeAdjacency;

    const faceFaceCounts = new Uint32Array(faceRangeOffsets.length);
    const faceFaceIndices = [];

    for (let i = 0; i < faceRangeOffsets.length - 1; ++i) {
      const adjacentFaces = [];
      for (let j = faceRangeOffsets[i]; j < faceRangeOffsets[i + 1]; ++j) {
        const edge = faceEdgeAdjacency.getEdgeIdFromFaceValueIndex(j);

        //if edge has 2 faces then find the adjacent face that isnt the given face
        const numFaces = faceEdgeAdjacency.getNumFacesForEdgeId(edge);
        if (numFaces > 1) {
          let adjacentFaceIndex = faceEdgeAdjacency.getFaceForEdgeId(edge, 0);
          if (adjacentFaceIndex === i)
            adjacentFaceIndex = faceEdgeAdjacency.getFaceForEdgeId(edge, 1);
          adjacentFaces.push(adjacentFaceIndex);
        }
      }

      faceFaceCounts[i + 1] = faceFaceCounts[i] + adjacentFaces.length;
      faceFaceIndices.push.apply(faceFaceIndices, adjacentFaces);
    }

    this.faceFaceCounts = faceFaceCounts;
    this.faceFaceIndices = new Uint32Array(faceFaceIndices);

    return this;
  },
};
