import {
  EvaluateOptions,
  OperatorData,
  PolyMeshPrimitive,
  OperatorDefinition,
  ThreekitSource,
} from '../../types';
import { useLoader } from '@react-three/fiber';
import * as THREE from 'three';
import BingeomLoader from './BingeomLoader';
import { flatNormalMap } from '../../polyMesh/geometric/operator/normals';

// @ts-ignore
THREE.Vector2.InstanceScalarSize = 2;
// @ts-ignore
THREE.Vector3.InstanceScalarSize = 3;
// @ts-ignore
THREE.Color.InstanceScalarSize = 3;
// @ts-ignore
THREE.Matrix3.InstanceScalarSize = 9;
// @ts-ignore
THREE.Matrix4.InstanceScalarSize = 16;

const Mesh: OperatorDefinition = {
  label: 'Mesh',
  schema: {
    geometry: { type: 'File', label: 'PolyMesh Geometry File' },
  },

  update(
    operator: OperatorData,
    primitive: PolyMeshPrimitive,
    opts: EvaluateOptions
  ) {
    if (!operator.geometry) return primitive;

    let mesh =
      !opts.skipFiles &&
      useLoader(
        BingeomLoader,
        operator.geometry,
        BingeomLoader.attach(opts.source)
      );
    if (Array.isArray(mesh)) mesh = mesh[0]; // fix incorrect typing from useLoader

    if (mesh && !mesh.normalMap) mesh = flatNormalMap(mesh);
    if (mesh) primitive.mesh = mesh;
    return primitive;
  },
};

export default Mesh;
