import { ActionOperon, OperatorOperon } from './Operon';

// A lookup functon for finding primitives with schema (eg operators)
export interface Lookups {
  [key: string]: (section?: string, key?: string) => any;
}

const actionOperators: { [type: string]: ActionOperon } = {};

export function lookupAction(plugName: string, type: string) {
  return actionOperators[type];
}

export function addActions(acts: { [type: string]: ActionOperon }) {
  Object.assign(actionOperators, acts);
}

const sgOperators: {
  [plugName: string]: { [type: string]: OperatorOperon };
} = {};

// WIP: skip these without warnings for now:
const skipPlugs: { [plugName: string]: boolean } = {
  Camera: true,
  Environment: true,
  Light: true,
  Mixer: true,
  Objects: true, // No ops for this anyway
  Timeline: true,
  Player: true,
  PostEffect: true,
};
export function lookupOperator(plugName: string, type: string) {
  const plug = sgOperators[plugName];
  if (!plug) {
    if (!skipPlugs[plugName])
      console.warn(`Unknown plug: ${plugName}`, Object.keys(sgOperators));
    return null;
  }
  const operator = plug[type];
  if (!operator) {
    console.log(`Unknown Operator: ${plugName}, ${type}`);
    return null;
  }

  return operator;
}

export function addOperators(plugs: {
  [plugName: string]: { [type: string]: OperatorOperon };
}) {
  for (const [plugName, ops] of Object.entries(plugs)) {
    if (!sgOperators[plugName]) sgOperators[plugName] = {};
    Object.assign(sgOperators[plugName], ops);
  }
}

export function lookupAttribute(plugName: string, type: string) {
  return null;
}

export const lookups: Lookups = {
  Action: lookupAction,
  Attribute: lookupAttribute,
  Operator: lookupOperator,
};
