import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { EvalNodeProps } from '../Node';
import { EvaluatedNullNode } from '../../types';

interface NullEvalNodeProps extends EvalNodeProps {
  evalNode: EvaluatedNullNode;
}

export default function Null(props: NullEvalNodeProps) {
  const { children, evalNode, nodeProps } = props;
  const ref = useRef();
  const { node } = evalNode;

  if (nodeProps && nodeProps['useFrame']) {
    useFrame((state) => nodeProps['useFrame'](state, ref));
  }
  const { position, euler, scale } = evalNode.Transform;

  return (
    <group
      {...nodeProps}
      name={node.name}
      position={position}
      rotation={euler.toArray()}
      scale={scale}
      ref={ref}
    >
      {children}
    </group>
  );
}
