import { Matrix4, Vector3 } from 'three';
import ObjectArrayView from '../../generic/container/ObjectArrayView';

export default function Skinning(from) {
  this.positionSkinRange = from.positionSkinRange; //Uint32Array
  this.skinWeights = from.skinWeights; //Float32Array
  this.skinBoneIndices = from.skinBoneIndices; //Uint16Array

  this.poseSkinToPoseBoneTransform = from.poseSkinToPoseBoneTransform; //ObjectArrayView of Matrix4's
}

Skinning.prototype = {
  constructor: Skinning,

  applyTransforms: function (
    positions,
    skinToWorldTransform,
    boneWorldTransforms,
    rootBoneIndex
  ) {
    return this.deform(
      this.createFinalTransforms(
        skinToWorldTransform,
        boneWorldTransforms,
        rootBoneIndex
      ),
      positions
    );
  },

  createFinalTransforms: function (
    skinToWorldTransform,
    boneWorldTransforms,
    rootBoneIndex
  ) {
    const poseSkinToPoseBoneTransform = this.poseSkinToPoseBoneTransform;
    const worldToSkinTransform = new Matrix4().getInverse(
      skinToWorldTransform,
      true
    );
    const finalTransforms = new Array(poseSkinToPoseBoneTransform.length);

    const identity = new Matrix4().identity();

    if (poseSkinToPoseBoneTransform.length === 0) {
      return finalTransforms;
    }

    let rootToWorldTransform;
    let worldToRootTransform;
    let poseRootToSkinTransform;

    if (rootBoneIndex > -1) {
      rootToWorldTransform = boneWorldTransforms[rootBoneIndex];
      worldToRootTransform = new Matrix4().getInverse(
        rootToWorldTransform,
        true
      );
      poseRootToSkinTransform = new Matrix4().getInverse(
        poseSkinToPoseBoneTransform.getAt(rootBoneIndex),
        true
      );
    }

    const boneToRootTransform = new Matrix4();
    const boneToSkinTransform = new Matrix4();
    const finalTransform = new Matrix4();

    for (let i = 0, il = poseSkinToPoseBoneTransform.length; i < il; i++) {
      let boneToWorldTransform = boneWorldTransforms[i];
      if (rootBoneIndex > -1) {
        boneToRootTransform.multiplyMatrices(
          worldToRootTransform,
          boneToWorldTransform
        );
        boneToSkinTransform.multiplyMatrices(
          poseRootToSkinTransform,
          boneToRootTransform
        );
      } else {
        boneToSkinTransform.multiplyMatrices(
          worldToSkinTransform,
          boneToWorldTransform
        );
      }

      finalTransforms[i] = new Matrix4().multiplyMatrices(
        boneToSkinTransform,
        poseSkinToPoseBoneTransform.getAt(i)
      );
    }

    return finalTransforms;
  },

  deform: function (finalTransforms, positions) {
    const positionSkinRange = this.positionSkinRange;
    const skinWeights = this.skinWeights;
    const skinBoneIndices = this.skinBoneIndices;

    const deformedPositions = new ObjectArrayView(Vector3, positions.length);
    const v = new Vector3();
    const d = new Vector3();

    for (let i = 0; i < positions.length; i++) {
      positions.getAt(i, v);
      d.multiplyScalar(0);
      for (let j = positionSkinRange[i]; j < positionSkinRange[i + 1]; j++) {
        positions.getAt(i, v);
        v.applyMatrix4(finalTransforms[skinBoneIndices[j]]);
        v.multiplyScalar(skinWeights[j]);
        d.addVectors(d, v);
      }

      if (positionSkinRange[i + 1] - positionSkinRange[i] === 0) {
        d.copy(v);
      }

      deformedPositions.setAt(i, d);
    }

    return deformedPositions;
  },
};
