import {
  MaterialMap,
  MaterialPrimitive,
  OperatorData,
  OperatorDefinition,
} from '../../types';
import { Color, sRGBEncoding, Vector2 } from 'three';

import {
  AllUvChannels,
  UV_TRIPLANAR,
  DEFAULT_FLAKES_COLOR,
  ThinFilmLUTs,
  ThinFilmLUTFiles,
} from '../constants';
import { makeMap, MapModifierType } from './matOperatorUtils';
import { PhysicalMaterialPlug } from './MaterialPlug';
import {
  unlitPhysicalCommonSchema,
  inferRenderCategory,
} from './unlitPhysicalCommon';
import { ToOptions } from '../utils';
// import { getOrFetchImageAsync } from '../../files';
// import { InteropImage } from '@threekit/image';

export const Physical: OperatorDefinition = {
  schema: {
    ...unlitPhysicalCommonSchema,

    ...makeMap('transparency', MapModifierType.Factor, {
      factorName: 'Transparency',
      defaultValue: 0,
    }),
    enableTransmission: {
      // switches between old, fast, feedforward 'Transparency' and new, advanced KHR 'Transmission', which is a superset of transparent
      label: 'Full Transmission',
      type: 'Boolean',
      defaultValue: false,
    },
    ...makeMap('transmission', MapModifierType.Factor, {
      // The reason we don't use the 'transparency' properties for this is because the transparency map uses G like the opacity map instead of R like KHR transmission
      factorName: 'Transmission',
      defaultValue: 0,
    }),
    ...makeMap('thickness', MapModifierType.Factor, {
      factorName: 'Thickness',
      defaultValue: 1,
      min: 0, // KHR is (0, +inf) exclusive
      max: 20,
    }),
    automaticThickness: {
      label: 'Automatic Thickness',
      type: 'Boolean',
      defaultValue: false,
    },
    // KHR defines attenuation to not be a map. we could always add it later if needed
    attenuationColor: {
      label: 'Attenuation Color',
      type: 'Color',
      defaultValue: { r: 1, g: 1, b: 1 },
    },
    attenuationDistance: {
      label: 'Attenuation Distance',
      type: 'Number',
      defaultValue: 1,
      min: 0, // KHR: 0 inclusive. Not in their spec, but in their webgl sampler viewer, 0 becomes infinite (and so does ours)
      max: 20, // KHR: +inf
      step: 0.01,
    },

    // Metal
    ...makeMap('metallic', MapModifierType.Factor, {
      defaultValue: 0,
    }),

    // Roughness
    ...makeMap('roughness', MapModifierType.Factor, {
      defaultValue: 0.25,
    }),
    ...makeMap('anisotropy', MapModifierType.Factor, {
      defaultValue: 0,
      min: -1,
      max: 1,
    }),
    // Leave this in scene graph even tough the map is no longer use.
    // Otherwise scene graph will struggle with older scenes using anisotropy
    ...makeMap('anisotropyRotation', MapModifierType.Factor, {
      defaultValue: 0,
      min: -1,
      max: 1,
    }),
    anisotropyLabel: {
      type: 'Label',
      defaultValue: 'Anisotropy requires the Tangent operator.',
    },
    // Specular
    ...makeMap('specular', MapModifierType.Color, {
      defaultValue: { r: 1, g: 1, b: 1 },
      factorName: 'Color',
      mapName: 'Color Map',
      uvName: 'Color UV Channel',
    }),
    ior: {
      label: 'Index of Refraction',
      type: 'Number',
      defaultValue: 1.5, // KHR default https://github.com/KhronosGroup/glTF/blob/master/extensions/2.0/Khronos/KHR_materials_ior/README.md
      min: 1.0,
      max: 10.0,
      step: 0.1,
      animatable: true,
    },
    ...makeMap('specularIntensity', MapModifierType.Factor, {
      defaultValue: 0.2,
      factorName: 'Specular Intensity',
      mapName: 'Intensity Map',
      uvName: 'Intensity UV Channel',
    }),
    multiScatter: {
      label: 'MultiScattering BRDF',
      type: 'Boolean',
      defaultValue: true,
    },
    thinFilmLutType: {
      label: 'Thin-Film LUT',
      type: 'Options',
      defaultValue: ThinFilmLUTs.Custom,
      options: ToOptions({
        ['Custom']: ThinFilmLUTs.Custom,
        ['Default']: ThinFilmLUTs.Default,
      }),
    },
    // Thin-Film Iridescence
    thinFilmLut: {
      label: 'Thin-Film LUT',
      type: 'Node',
      assetType: ['Texture' /*, 'Vector'*/],
    },
    ...makeMap('thinFilmThickness', MapModifierType.Factor, {
      defaultValue: 1,
      factorName: 'Max Thickness',
      mapName: 'Thickness Image',
      uvName: 'Thickness UV Channel',
    }),
    thinFilmThicknessMin: {
      label: 'Min Thickness',
      type: 'Number',
      defaultValue: 0.0,
      min: 0.0,
      max: 1.0,
      step: 0.01,
      animatable: true,
    },

    // Clearcoat
    clearCoat: {
      label: 'Thickness',
      type: 'Number',
      defaultValue: 0.0,
      min: 0.0,
      max: 1.0,
      step: 0.01,
      animatable: true,
    },
    clearCoatRoughness: {
      label: 'Roughness',
      type: 'Number',
      defaultValue: 0.25,
      min: 0.0,
      max: 1.0,
      step: 0.01,
      animatable: true,
    },
    ...makeMap('clearCoatNormal', MapModifierType.Factor, {
      mapName: 'Normal Image',
      factorName: 'Normal Factor',
      defaultValue: 1,
      min: -5,
      max: 5,
    }),

    // Flakes
    flakesEnabled: {
      label: 'Enable Flakes',
      type: 'Boolean',
      defaultValue: false,
    },

    flakesColor: {
      // Due to unfortunate naming, we can't have flakesColor as the name of the color
      // This is a legacy property, and should be migrated to flakesTint if flakesColor!=default && flakesTint == default
      // Also, I had accidentally forgotten the sRGB mapping, so that will have to be accounted for during migration.
      // Meanwhile, we do this switch in the update code
      // The UI will hide this as much as possible.
      label: 'Flake Color (Deprecated)',
      configurable: false,
      type: 'Color',
      defaultValue: DEFAULT_FLAKES_COLOR,
    },
    ...makeMap('flakesTint', MapModifierType.Color, {
      colorName: 'Flake Tint',
      mapName: 'Flake Tint Image',
      uvName: 'Flake Tint UV Channel',
      defaultValue: DEFAULT_FLAKES_COLOR,
      defaultUv: UV_TRIPLANAR,
    }),
    flakesMap: {
      // flakesMap isn't actually used in the material, but required to have the triplanar and uv mapping plugs work.
      // The reason is that Triplanar.ts read this schema to extract labels.
      label: 'Flakes',
      type: 'Node',
      hidden: true,
    },
    flakesUV: {
      label: 'Flake UV Channel',
      type: 'Options',
      defaultValue: UV_TRIPLANAR,
      options: AllUvChannels,
    },
    flakesDensity: {
      label: 'Flake Density',
      type: 'Number',
      defaultValue: 0.2,
      min: 0.0,
      max: 1.0,
      step: 0.01,
    },
    flakesSize: {
      label: 'Flake Size',
      type: 'Number',
      defaultValue: 0.0004,
      min: 0.0,
      max: 0.005,
      step: 0.0001,
    },
    flakesOrientationWarning: {
      type: 'Label',
      defaultValue: 'An orientation value > 0.5 can lead to artifacts.',
    },
    flakesOrientation: {
      label: 'Flake Orientation',
      type: 'Number',
      defaultValue: 0.4,
      min: 0.0,
      max: 1.0,
      step: 0.01,
    },

    // Sheen
    // Backwards compatibility for old sheen.
    // Migration plan: If "sheenFactor" > 0 AND "sheenEnabled" == false, set "sheenEnabled" = true, "sheenFactorNew" = sheenFactor, "sheenColor" = { r: 1.0, g: 1.0, b: 1.0 }, and "sheenRoughnessFactor" = sheenFactor.
    //                 Then, delete old "sheenFactor", rename "sheenFactorNew" to "sheenFactor".
    // Note: "sheenFactor" > 0 AND "sheenEnabled" == true, it means they manually switched to the new sheen, so discard "sheenFactor" and just rename "sheenFactorNew" to "sheenFactor".
    sheenFactor: {
      label: 'Deprecated Sheen',
      configurable: false,
      type: 'Number',
      defaultValue: 0.0,
      min: 0.0,
      max: 1.0,
      step: 0.01,
    },
    sheenEnabled: {
      label: 'Enable Sheen',
      type: 'Boolean',
      defaultValue: false,
    },
    sheenFactorNew: {
      label: 'Sheen Factor',
      type: 'Number',
      defaultValue: 1.0,
      min: 0.0,
      max: 1.0,
      step: 0.01,
    },
    ...makeMap('sheen', MapModifierType.Color, {
      defaultValue: { r: 0.75, g: 0.75, b: 0.75 },
    }),
    ...makeMap('sheenRoughness', MapModifierType.Factor, {
      factorName: 'Roughness',
      mapName: 'Roughness Map',
      uvName: 'Roughness UV',
      defaultValue: 0.5,
    }),

    // Bump/ normals
    ...makeMap('bump', MapModifierType.Factor, {
      defaultValue: 1,
      min: -5,
      max: 5,
    }),

    ...makeMap('normal', MapModifierType.Factor, {
      defaultValue: 1,
      min: -5,
      max: 5,
    }),

    // Emissive
    ...makeMap('emissive', MapModifierType.Color, {
      defaultValue: { r: 0, g: 0, b: 0 },
    }),
    emissiveScale: {
      label: 'Scale',
      type: 'Number',
      defaultValue: 1.0,
      min: 0.0,
      max: 20.0,
      step: 0.1,
      animatable: true,
    },

    // Lighting
    ...makeMap('ao', MapModifierType.Factor, {
      defaultValue: 1,
      mapName: 'AO Image',
      factorName: 'AO Factor',
    }),

    ...makeMap('light', MapModifierType.Color, {
      defaultValue: { r: 0, g: 0, b: 0 },
    }),
  },

  update(operator: OperatorData, primitive: MaterialPrimitive) {
    // let thinFilmBuiltInLut: InteropImage | undefined = undefined;
    // if (operator.thinFilmLutType !== ThinFilmLUTs.Custom) {
    //   const hash = ThinFilmLUTFiles[operator.thinFilmLutType as ThinFilmLUTs]!;
    //   thinFilmBuiltInLut = await getOrFetchImageAsync(
    //     store,
    //     {
    //       hash,
    //       filename: '.png',
    //       type: 'image/png',
    //     },
    //     {},
    //     player
    //   );
    // }

    // Sheen backwards compatibility.
    // Once old sheen gets migrated (as described in the schema portion), you should be able to remove this without having to update webgl or vray.
    let sheenOverride = {};
    const { sheenEnabled, sheenFactor, sheenFactorNew } = operator;
    if (!sheenEnabled && sheenFactor > 0) {
      sheenOverride = {
        sheenEnabled: true,
        sheenFactor: sheenFactor,
        sheenColor: operator.baseColor,
        sheenMap: operator.baseMap,
        sheenRoughness: sheenFactor,
      };
    } else if (sheenEnabled) {
      sheenOverride = {
        sheenFactor: sheenFactorNew,
      };
    }

    let flakesTintOverride = {};
    const { flakesColor, flakesTintColor } = operator;
    const isDefaultFlakesColor = (c: Color) => c.equals(DEFAULT_FLAKES_COLOR);

    const usingOldFlakesColor = !isDefaultFlakesColor(flakesColor);
    const usingNewFlakesColor = !isDefaultFlakesColor(flakesTintColor);

    if (usingOldFlakesColor && !usingNewFlakesColor) {
      // Old color was mistakenly linear, so we need to make it sRGB so the inverse srgb => linear cancels out.
      const sRgbOldColor = (flakesColor as Color).clone().convertLinearToSRGB();
      flakesTintOverride = { flakesTintColor: sRgbOldColor };
    }

    // console.log('Evaluate physical operator?', operator);
    const textureMaps = [
      ['map', 'baseMap'],
      ['aoMap', 'aoMap'],
      ['thicknessMap', 'thicknessMap'],
      ['transparencyMap', 'transparencyMap'],
      ['specularMap', 'specularMap'],
      ['specularIntensityMap', 'specularIntensityMap'],
      ['roughnessMap', 'roughnessMap'],
      ['anisotropyMap', 'anisotropyMap'],
      ['metalnessMap', 'metallicMap'],
      ['emissiveMap', 'emissiveMap'],
      ['normalMap', 'normalMap'],
      ['opacityMap', 'opacityMap'],
      ['lightMap', 'lightMap'],
      ['aoMap', 'aoMap'],
    ];

    primitive.maps = textureMaps.reduce(
      (acc: Array<MaterialMap>, [k, mapName]) => {
        if (!operator[mapName]) return acc;
        return acc.concat([{ ref: operator[mapName], attachTo: k }]);
      },
      []
    );

    //     {textureMaps.map(([k, mapName]) => {
    //       const id = mat[mapName]?.assetId;
    //       return id && <Asset id={id} attachTo={k} key={k} />;
    //     })}
    // console.log('Physical, maps: ', primitive.maps);
    //console.log("operator in Physical.ts", operator);

    primitive.aoMapIntensity = operator.aoFactor;
    primitive.baseColor = operator.baseColor;
    primitive.bumpScale = operator.bumpFactor;
    primitive.clearcoat = operator.clearCoat;
    primitive.clearcoatRoughness = operator.clearCoatRoughness;
    primitive.emissive = new Color(0, 0, 0); //operator.emissiveColor;
    // primitive.notEmissiveColor = operator.emissiveColor;
    primitive.metalness = operator.metallicFactor;
    primitive.lightMapIntensity = operator.lightColor.r;
    //primitive.multiScatter = operator.multiScatter;
    primitive.normalScale = new Vector2(
      operator.normalFactor,
      operator.normalFactor
    );
    primitive.opacity = operator.opacityFactor;
    primitive.roughness = operator.roughnessFactor;
    primitive.sheen = operator.sheenFactor;
    primitive.sheenRoughness = operator.sheenRoughnessFactor;
    primitive.specularIntensity = operator.specularIntensityFactor * 0.5;
    primitive.transmission = operator.transmissionFactor;
    primitive.transparent =
      operator.baseMapTransparent || operator.opacity < 1.0;

    return primitive;

    // primitive = {
    //   ...operator,
    //   ...sheenOverride,
    //   ...flakesTintOverride,
    //   // thinFilmBuiltInLut,
    // } as PhysicalMaterialPlug; // FIXME: missing props?
    // // primitive.renderCategory = inferRenderCategory(primitive);
    // return primitive;
  },
};

export default Physical;
