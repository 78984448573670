import { Color, Vector2, Vector3 } from 'three';
import lodash from 'lodash';
import { v4 as uuid } from 'uuid';
import { SupportedFileType } from './attributes';
import ifUndef from './ifUndef';

interface Vec3Data {
  x: number;
  y: number;
  z: number;
}
type Vec3Types = Vector3 | Vec3Data | number[];
function initVector3(from: Vec3Types | null | undefined): Vector3 {
  if (!from) return new Vector3();
  if (Array.isArray(from)) return new Vector3(from[0], from[1], from[2]);
  if (from.hasOwnProperty('x')) return new Vector3(from.x, from.y, from.z);
  return new Vector3();
}

interface Vec2Data {
  x: number;
  y: number;
}
type Vec2Types = Vector2 | Vec2Data | number[];
function initVector2(from: Vec2Types | null | undefined): Vector2 {
  if (!from) return new Vector2();
  if (Array.isArray(from)) return new Vector2(from[0], from[1]);
  if (from.hasOwnProperty('x')) return new Vector2(from.x, from.y);
  return new Vector2();
}

export function childSchema(schema: Vec2Schema, child: 'x' | 'y'): NumberSchema;
export function childSchema(
  schema: Vec3Schema,
  child: 'x' | 'y' | 'z'
): NumberSchema;
export function childSchema(
  schema: Vec2Schema | Vec3Schema,
  child: 'x' | 'y' | 'z'
): NumberSchema {
  const { min, max, step, animatable } = schema;

  const init: (v: any) => any =
    schema.type === 'Vec2' ? initVector2 : initVector3;

  const childSchema: NumberSchema = {
    type: 'Number',
    animatable,
    step,
  };

  if (schema.defaultValue != null) {
    childSchema.defaultValue = init(schema.defaultValue)[child];
  }

  if (min != null) {
    if (typeof min === 'object') childSchema.min = init(min)[child];
    else childSchema.min = min;
  }

  if (max != null) {
    if (typeof max === 'object') childSchema.max = init(max)[child];
    else childSchema.max = max;
  }

  return childSchema;
}

interface ColorData {
  r: number;
  g: number;
  b: number;
}

type ColorTypes = Color | ColorData | number | number[] | string;

export function validateHexColor(value: string) {
  if (value.length !== 4) {
    return value;
  }

  return `#${value
    .slice(1)
    .split('')
    .map((hex) => hex + hex)
    .join('')}`;
}

export function initColor(from: ColorTypes | null | undefined): Color {
  if (typeof from === 'number') return new Color(from); // hex value
  if (!from) return new Color();
  if (typeof from === 'string') {
    const color = new Color();
    // FIXME TODO: add proper radix
    /* tslint:disable:radix */
    const next = validateHexColor(from);
    color.setHex(parseInt('0x' + next.replace('#', '')));
    /* tslint:enable:radix */
    return color;
  }
  if (Array.isArray(from)) return new Color(from[0], from[1], from[2]);
  // Check actual properties don't use (hasOwnProperties), because the default Color doesn't have it's own r,g,b properties, they're in it's prototype.
  if (from.r != null && from.g != null && from.b != null)
    return new Color(from.r, from.g, from.b);
  return new Color();
}

function getClampFn(min: number, max: number) {
  if (min !== undefined && max !== undefined) {
    return (v: number) => Math.min(Math.max(min, v), max);
  } else if (min !== undefined) return (v: number) => Math.max(v, min);
  else if (max !== undefined) return (v: number) => Math.min(v, max);
  else return (v: number) => v;
}

// interface AssetOperatorSchema {
//   type: 'Asset';
//   defaultValue?: string;
// }

interface BasePropertySchema<T extends string> {
  type: T;
  label?: string;
  hidden?: boolean;
  configurable?: boolean;
  of?: PropertySchema; // Required for compatibility
}

interface DefaultablePropertySchema<T extends string, D>
  extends BasePropertySchema<T> {
  defaultValue?: D | null;
}

interface ArraySchema extends DefaultablePropertySchema<'Array', any[]> {
  of?: PropertySchema;
}

interface AssetSchema
  extends DefaultablePropertySchema<'Asset', string | object> {
  assetType?: string | string[];
  skipEval?: (player: any) => boolean;
}

interface AttributeSchema extends DefaultablePropertySchema<'Attribute', any> {
  filterBy?: string;
  acceptArrayValue?: boolean;
}

interface AttributePropertySchema
  extends BasePropertySchema<'AttributeProperty'> {
  from?: string;
}

interface AttributeValuesSchema extends BasePropertySchema<'AttributeValues'> {
  from?: string;
}

type BinarySchema = BasePropertySchema<'Binary'>;

interface BooleanSchema extends DefaultablePropertySchema<'Boolean', boolean> {
  animatable?: boolean;
}

type ButtonSchema = BasePropertySchema<'Button'>;

interface ColorSchema extends DefaultablePropertySchema<'Color', ColorTypes> {
  animatable?: boolean;
}

type AnySchema = BasePropertySchema<'Any'>;
type CustomSchema = BasePropertySchema<'Custom'>;
type FileSchema = BasePropertySchema<'File'>;
type ImageSchema = BasePropertySchema<'Image'>;

interface IntegerSchema extends DefaultablePropertySchema<'Integer', number> {
  min?: number | null;
  max?: number | null;
  step?: number | null;
  animatable?: boolean;
}

type LabelSchema = DefaultablePropertySchema<'Label', string>;

interface NodeSchema extends BasePropertySchema<'Node'> {
  assetType?: string | string[];
  nodeType?: string | string[];
  plug?: string;
}

type NodeListSchema = BasePropertySchema<'NodeList'>;

interface NumberSchema extends DefaultablePropertySchema<'Number', number> {
  min?: number | null;
  max?: number | null;
  step?: number | null;
  lockToStep?: boolean;
  animatable?: boolean;
  clearable?: boolean;
}

type ObjectSchema = DefaultablePropertySchema<'Object', any>;

interface OptionsSchema extends DefaultablePropertySchema<'Options', any> {
  options?: Array<{ value: any; label: string }>;
  values?: any[];
  labels?: string[];
}

type PartSchema = DefaultablePropertySchema<'Part', any>;
type PathSchema = BasePropertySchema<'Path'>;
interface PathPropertySchema extends BasePropertySchema<'PathProperty'> {
  from?: string;
}

interface PlugSchema extends BasePropertySchema<'Plug'> {
  plug?: string;
  assetType?: string | string[];
}

interface StringSchema extends DefaultablePropertySchema<'String', string> {
  maxLength?: number | null;
}

type StringsSchema = DefaultablePropertySchema<'Strings', string[]>;

type UuidSchema = BasePropertySchema<'Uuid'>;

interface Vec2Schema extends DefaultablePropertySchema<'Vec2', Vec2Types> {
  min?: number | Vec2Data | null;
  max?: number | Vec2Data | null;
  step?: number | null;
  animatable?: boolean;
}

interface Vec3Schema extends DefaultablePropertySchema<'Vec3', Vec3Types> {
  min?: number | Vec3Data | null;
  max?: number | Vec3Data | null;
  step?: number | null;
  animatable?: boolean;
}

type PricingSchema = BasePropertySchema<'Pricing'>;

type DefaultsEqualAsset = Omit<AssetSchema, 'type'> & {
  type: 'DefaultsEqualAsset';
};

export type PropertySchema =
  | ArraySchema
  | AssetSchema
  | AttributeSchema
  | AttributePropertySchema
  | AttributeValuesSchema
  | BinarySchema
  | BooleanSchema
  | ButtonSchema
  | ColorSchema
  | CustomSchema
  | FileSchema
  | ImageSchema
  | IntegerSchema
  | LabelSchema
  | NodeSchema
  | NodeListSchema
  | NumberSchema
  | ObjectSchema
  | OptionsSchema
  | PartSchema
  | PathSchema
  | PathPropertySchema
  | PlugSchema
  | StringSchema
  | StringsSchema
  | UuidSchema
  | Vec2Schema
  | Vec3Schema
  | PricingSchema
  | AnySchema
  | PricingSchema
  | DefaultsEqualAsset;

type SchemaTypes = PropertySchema['type'];

type ReferenceType = 'asset' | 'file' | 'node' | 'part' | 'plug';

export interface PropertySchemaInstance {
  schema: PropertySchema;
  defaultValue: any;
  get?: (value: any) => any;
  set: (value: any) => any;
  references?: ReferenceType; // | ReferenceType[];
  referenceFor?: (value: any) => ReferenceType;
  plug?: string;
  nodeType?: string | string[];
  isCustom?: boolean;
  equals?: (val1: any, val2: any) => boolean;
  of?: PropertySchemaInstance;
}

export const schemaTypes: {
  [x in SchemaTypes]: (schema: PropertySchema) => PropertySchemaInstance;
} = {
  Any(schema: AnySchema) {
    return {
      schema,
      defaultValue: null,
      set: (value: any) => value,
    };
  },

  Array(schema: ArraySchema) {
    if (!schema.of) throw new Error('Array of must be specified');
    const subSchema = schemaTypes[schema.of.type](schema.of);

    return {
      schema,
      references: subSchema.references,

      defaultValue: schema.defaultValue || [],
      set: (value: any) => {
        if (!Array.isArray(value)) return [];
        return value.map((v) => subSchema.set(v));
      },
    };
  },

  //
  // An Asset reference is a composite property that has both
  // `assetId` (uuid of referenced asset), and
  // `configuration` (optional configuration for smart asset);
  Asset(schema: AssetSchema) {
    const defaultValue = schema.defaultValue || { assetId: '' };
    return {
      references: 'asset',
      schema,
      defaultValue,
      assetType: schema.assetType,
      set: (value: any) => {
        if (value == null) {
          return defaultValue;
        }

        if (typeof value === 'string') {
          // console.log('string asset?', value);
          return value; // return { assetId: value };
        }
        return value;
      },

      equals: (a: any, b: any) => {
        if (typeof a === 'string') return a === b; // asset instance, use string compare
        if (!a || !b) return false; // one is falsy
        const sameId = a.assetId && b.assetId && a.assetId === b.assetId;
        const sameQuery =
          a.query && b.query && lodash.isEqual(a.query, b.query); // matches queries, not resolved asset ids
        const sameAsset = sameId || sameQuery;
        const sameConfig = lodash.isEqual(a.configuration, b.configuration);
        return sameAsset && sameConfig;
      },
    };
  },

  Attribute(schema: AttributeSchema) {
    return {
      schema,
      defaultValue: schema.defaultValue,
      set: (value: any) => value,
    };
  },

  Binary(schema: BinarySchema) {
    return {
      schema,
      defaultValue: '',
      references: 'file',

      set: (id) => {
        return id;
      },
    };
  },

  Boolean(schema: BooleanSchema) {
    const defaultValue = schema.defaultValue || false;

    return {
      schema,
      defaultValue,

      set: (value: any) => {
        return value == null ? defaultValue : !!value;
      },
    };
  },

  Button(schema: ButtonSchema) {
    return {
      schema,
      defaultValue: null,
      set: (value: any) => value,
    };
  },

  Color(schema: ColorSchema) {
    const defaultValue = initColor(schema.defaultValue);
    const set = (value: any) => {
      return initColor(value !== undefined ? value : defaultValue);
    };

    return {
      schema,
      defaultValue,
      set,
      get: (value: Color) => {
        return set(value).clone();
      },

      equals: (value1: any, value2: any) =>
        value1 && value2 && value1.equals(value2),
    };
  },

  Custom(schema: CustomSchema) {
    return {
      schema,
      isCustom: true,
      defaultValue: {},
      set: (value: any) => value,
    };
  },

  PathProperty(schema: PathPropertySchema) {
    return {
      defaultValue: null,
      schema,
      set: (value: any) => value,
    };
  },

  AttributeProperty(schema: AttributePropertySchema) {
    return {
      defaultValue: null,
      schema,
      set: (value: any) => value,
    };
  },

  AttributeValues(schema: AttributeValuesSchema) {
    return {
      defaultValue: null,
      schema,
      set: (value: any) => value,
    };
  },

  File(schema: FileSchema) {
    return {
      schema,
      defaultValue: '',
      references: 'file',

      set: (id) => {
        return id;
      },
    };
  },

  Image(schema: ImageSchema) {
    return {
      schema,
      defaultValue: '',
      references: 'file',

      set: (id) => id,
    };
  },

  Integer(schema: IntegerSchema) {
    const min = ifUndef(schema.min, 0);
    const max = ifUndef(schema.max, 100);
    const defaultValue = ifUndef(schema.defaultValue, 0);
    const clamp = getClampFn(min, max);

    return {
      schema,
      defaultValue,
      set: (value: any) => {
        const num = Number(value);
        if (isNaN(num)) return defaultValue;
        return Math.round(clamp(num));
      },
    };
  },

  Label(schema: LabelSchema) {
    return {
      schema,
      defaultValue: schema.defaultValue || '',

      set: (val: any) => {
        return typeof val === 'string' ? val : '';
      },
    };
  },

  Node(schema: NodeSchema) {
    return {
      schema,
      defaultValue: '',
      references: schema.assetType ? undefined : 'node',
      nodeType: schema.nodeType,

      // Dynamic reference -- if it's an asset reference we store it, if it's
      // an internal plug reference we need to track it separately
      referenceFor: schema.assetType
        ? (value: string | object) => {
            return !value || typeof value === 'string' ? 'node' : 'asset';
          }
        : undefined,

      set: (id) => {
        return id;
      },
    };
  },

  NodeList(schema: NodeListSchema) {
    return {
      schema,
      defaultValue: [],

      references: 'node',

      set: (ids) => {
        if (Array.isArray(ids)) {
          return ids.map((id) => (id && id.id ? id.id : id));
        }

        return [];
      },

      equals: (val1, val2) => JSON.stringify(val1) === JSON.stringify(val2),
    };
  },

  Number(schema: NumberSchema) {
    const step = ifUndef(schema.step, 1);
    const min = ifUndef(schema.min, -Number.MAX_VALUE);
    const max = ifUndef(schema.max, Number.MAX_VALUE);
    const lockToStep = ifUndef(schema.lockToStep, false);
    const clamp = getClampFn(min, max);
    let defaultValue = schema.defaultValue;

    const applyModifications = (value: number | null | undefined) => {
      let nextValue = value;

      if (nextValue == undefined) {
        return nextValue;
      }

      if (lockToStep) {
        const minValue = schema.min || 0;
        nextValue = minValue + Math.round((nextValue - minValue) / step) * step;
      }

      nextValue = clamp(nextValue);

      return Math.round(nextValue * 10000) / 10000;
    };

    if (!schema.clearable) {
      defaultValue = ifUndef(defaultValue, 0);
    }

    return {
      schema,
      defaultValue: applyModifications(defaultValue),
      set: (value: string | number) => {
        const nextValue = Number(value);

        if (isNaN(nextValue)) return defaultValue;

        return applyModifications(nextValue);
      },
    };
  },

  Options(schema: OptionsSchema) {
    const defaultValue = schema.defaultValue;
    return {
      schema,
      defaultValue,

      set: (value: any) => {
        return value == null ? defaultValue : value;
      },
    };
  },

  Part(schema: PartSchema) {
    return {
      schema,
      references: 'part',

      defaultValue: schema.defaultValue || '',
      set: (part) => {
        // console.log('set part?', part);
        return part;
      },
    };
  },

  Path(schema: PathSchema) {
    return {
      references: 'node',

      defaultValue: [],
      schema,
      set: (value: Array<string | number>) => {
        return value || [];
      },
    };
  },

  Plug(schema: PlugSchema) {
    if (!schema.plug) {
      throw new Error('plug property expects a `plug` property');
    }

    return {
      schema,
      defaultValue: '',

      plug: schema.plug,

      references: schema.assetType ? undefined : 'plug',

      // Dynamic reference -- if it's an asset reference we store it, if it's
      // an internal plug reference we need to track it separately
      referenceFor: schema.assetType
        ? (value: string | object) => {
            return !value || typeof value === 'string' ? 'plug' : 'asset';
          }
        : undefined,

      set: (id) => {
        if (id && id.id) return id.id;
        return id;
      },
    };
  },

  Object(schema: ObjectSchema) {
    const defaultValue = schema.defaultValue || {};

    return {
      schema,
      defaultValue,

      set: (obj) => {
        return obj || defaultValue;
      },

      equals: (val1, val2) => JSON.stringify(val1) === JSON.stringify(val2),
    };
  },

  String(schema: StringSchema) {
    const defaultValue = ifUndef(schema.defaultValue, '');
    return {
      schema,
      defaultValue,
      set: (value: any) => {
        value = typeof value === 'string' ? value : defaultValue;
        if (schema.maxLength) {
          const maxLengthAsNum = Number(schema.maxLength);
          if (!Number.isNaN(maxLengthAsNum))
            value = value.substr(0, maxLengthAsNum);
        }
        return value;
      },
    };
  },

  Strings(schema: StringsSchema) {
    return {
      schema,
      defaultValue: schema.defaultValue || [],
      set: (value: any) => {
        if (!Array.isArray(value)) return [];
        return value;
      },
    };
  },

  Uuid(schema: UuidSchema) {
    const defaultValue = uuid();

    return {
      schema,
      defaultValue,
      set: (value: any) => {
        return value || uuid();
      },
    };
  },

  Vec2(schema: Vec2Schema) {
    let min = ifUndef(schema.min, -Number.MAX_VALUE);
    let max = ifUndef(schema.max, Number.MAX_VALUE);
    const defaultValue = initVector2(schema.defaultValue);

    if (typeof min === 'object') min = initVector2(min);
    else if (typeof min === 'number') min = new Vector2().setScalar(min);
    if (typeof max === 'object') max = initVector2(max);
    else if (typeof max === 'number') max = new Vector2().setScalar(max);

    const clampX = getClampFn(min.x, max.x);
    const clampY = getClampFn(min.y, max.y);

    const set = (val: any) => {
      const vec = initVector2(val || defaultValue);
      vec.set(clampX(vec.x), clampY(vec.y));
      return vec;
    };

    return {
      schema,
      defaultValue,

      set,

      get: (val: Vector2) => {
        return set(val).clone();
      },

      equals: (val1, val2) => val1 && val2 && val1.equals(val2),
    };
  },

  Vec3(schema: Vec3Schema) {
    let min = ifUndef(schema.min, -Number.MAX_VALUE);
    let max = ifUndef(schema.max, Number.MAX_VALUE);
    const defaultValue = initVector3(schema.defaultValue);

    if (typeof min === 'object') min = initVector3(min);
    else if (typeof min === 'number') min = new Vector3().setScalar(min);
    if (typeof max === 'object') max = initVector3(max);
    else if (typeof max === 'number') max = new Vector3().setScalar(max);

    const clampX = getClampFn(min.x, max.x);
    const clampY = getClampFn(min.y, max.y);
    const clampZ =
      typeof min.z === 'number' && typeof max.z === 'number'
        ? getClampFn(min.z, max.z)
        : (z: any) => z;

    const set = (val: any) => {
      const vec = initVector3(val || defaultValue);
      vec.set(clampX(vec.x), clampY(vec.y), clampZ(vec.z));
      return vec;
    };
    return {
      schema,
      defaultValue,

      set,

      get: (val: Vector3) => {
        return set(val).clone();
      },

      equals: (val1, val2) => val1 && val2 && val1.equals(val2),
    };
  },

  Pricing(schema: CustomSchema) {
    return {
      schema,
      defaultValue: {},
      set: (value: any) => value,
    };
  },

  DefaultsEqualAsset(schema: AssetSchema) {
    // Behaves like Asset, but considers all default values to be equal
    const assetSchemaInstance = schemaTypes['Asset'](schema);
    return {
      ...assetSchemaInstance,
      equals: (a: any, b: any) =>
        assetSchemaInstance.equals!(a, b) ||
        (a.assetId || '') === (b.assetId || ''),
    };
  },
};

// this is the same as SchemaTypes, unify them?
export type SchemaType = keyof typeof schemaTypes;
