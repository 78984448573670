import ObjectsByName from '../../generic/container/ObjectsByName';
import { Color, Vector2, Vector3, Vector4 } from 'three';

export default function PolyMaps() {
  ObjectsByName.call(this);
}

PolyMaps.prototype = Object.create(ObjectsByName.prototype);
PolyMaps.prototype.constructor = PolyMaps;

// Pretty much an alias to ObjectsByName, adding a static interface
// and a custom iterator for uniform access of maps within a mesh...

Object.assign(PolyMaps, {
  TypePosition: 1,
  TypeNormal: 2,
  TypeTangent: 4,
  TypeUV: 8,
  TypeColor: 16,

  id: function (typeId, name) {
    const anchorName = PolyMaps.TypeInfo[typeId].AnchorName;
    return name != null ? anchorName + '.' + name : anchorName;
  },

  checkId: function (id) {
    const anchorName = PolyMaps.getAnchorName(id);
    const mapName = PolyMaps.getMapName(id, anchorName);
    const typeInfo = PolyMaps.getTypeInfo(anchorName);

    if (typeInfo === null || mapName === '')
      throw Error("Invalid PolyMap ID '" + id + "'!");

    return id;
  },

  getAnchorName: function (id) {
    const dot = id.indexOf('.');
    return dot === -1 ? id : id.slice(0, dot);
  },

  getMapName: function (id, anchorName) {
    return id === anchorName ? null : id.slice(anchorName.length + 1);
  },

  getTypeInfo: function (anchorName) {
    const info = PolyMaps.TypeInfo;
    for (let i = 0, n = info.length; i !== n; ++i) {
      const entry = info[i];
      if (entry !== null && entry.AnchorName === anchorName) return info[i];
    }

    return null;
  },

  resolveMap: function (mesh, id) {
    const anchorName = PolyMaps.getAnchorName(id);
    if (id === anchorName) return mesh[anchorName];

    const maps = mesh[anchorName];
    return maps && maps.byName[id.slice(anchorName.length + 1)];
  },

  assignMap: function (mesh, id, map) {
    const anchorName = PolyMaps.getAnchorName(id);
    const mapName = PolyMaps.getMapName(id, anchorName);

    if (mapName === null) {
      mesh[anchorName] = map;
    } else {
      let maps = mesh[anchorName];

      if (!maps) {
        maps = new PolyMaps();
        mesh[anchorName] = maps;
      }

      maps.set(mapName, map);
    }
  },

  Iterator: function (mesh) {
    ObjectsByName.Iterator.call(this);
    this.reset(mesh || null);
  },
});

PolyMaps.TypeInfo = [
  null,
  {
    // 1
    Type: PolyMaps.TypePosition,
    AnchorName: 'positions',
    ElementType: Vector3,
  },
  {
    // 2
    Type: PolyMaps.TypeNormal,
    AnchorName: 'normalMap',
    ElementType: Vector3,
  },
  null,
  {
    // 4
    Type: PolyMaps.TypeTangent,
    AnchorName: 'tangentMap',
    ElementType: Vector4,
  },
  null,
  null,
  null,
  {
    // 8
    Type: PolyMaps.TypeUV,
    AnchorName: 'uvMaps',
    ElementType: Vector2,
  },
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  {
    Type: PolyMaps.TypeColor,
    AnchorName: 'colorMaps',
    ElementType: Color,
  },
];

Object.assign(PolyMaps, {
  IdPositions: PolyMaps.id(PolyMaps.TypePosition),
  IdNormals: PolyMaps.id(PolyMaps.TypeNormal),
  IdTangents: PolyMaps.id(PolyMaps.TypeTangent),
});

PolyMaps.Iterator.prototype = Object.assign(
  Object.create(ObjectsByName.Iterator.prototype),
  {
    constructor: PolyMaps.Iterator,
    _super: ObjectsByName.Iterator.prototype,

    reset: function (optionalMesh) {
      this._super.reset.call(this, null);

      const mesh = optionalMesh || null;
      this.mesh = mesh;
      this.byName = mesh;
      this.typeId = 0;
    },

    getId: function () {
      return PolyMaps.id(this.typeId, this.name);
    },

    next: function () {
      // Note: Not using dynamic resolution here because it may impact
      // compiler optimizations in a negative way.

      // If iterating a collection, keep going:

      if (this._super.next.call(this)) return true;

      // Otherwise count up the id:

      const mesh = this.mesh;
      let type = this.typeId;

      let elem = null;
      let object = null;

      type <<= 1;

      switch (type) {
        case 0:
          type = 1;
        // v-v-v

        case PolyMaps.TypePosition:
          elem = mesh.positions;
          break;

        case PolyMaps.TypeNormal:
          elem = mesh.normalMap;
          break;

        case PolyMaps.TypeTangent:
          elem = mesh.tangentMap;
          break;

        case PolyMaps.TypeUV:
          object = mesh.uvMaps;
          break;

        case PolyMaps.TypeColor:
          object = mesh.colorMaps;
          break;

        default:
          this.reset(null);
          return false;
      }

      this.typeId = type;

      if (object !== null && object !== undefined) {
        this.byName = object.byName;
        return this._super.reset.call(this, object).next();
      } else if (elem !== null && elem !== undefined) {
        this.element = elem;
        this.index = type;
        this.name = null;
        return true;
      }

      return this.next();
    },
  }
);
