import Orders from './Orders';

/**
 * @typedef {(function(new:Array)|Array)} ArrayOrType
 */

/**
 * Various utilities for working with arrays.
 *
 * @namespace
 */
const Arrays = {
  /**
   * Empty arrays.
   *
   * @namespace
   */
  Empty: Object.freeze({
    /** @const @type {!Array} */
    Array: Object.freeze(new Array(0)),

    // Note: Typed arrays can't - and don't have to frozen, since
    // they have an immutable length.

    /** @const @type {!Array<!number>} */
    Float64Array: new Float64Array(0),
    /** @const @type {!Array<!number>} */
    Float32Array: new Float32Array(0),
    /** @const @type {!Array<!number>} */
    Uint32Array: new Uint32Array(0),
    /** @const @type {!Array<!number>} */
    Int32Array: new Int32Array(0),
  }),

  //

  rangeCopy: function (src, srcOffset, dst, dstOffset, length) {
    for (let i = 0; i !== length; ++i) dst[dstOffset + i] = src[srcOffset + i];

    return dst;
  },

  rangeEquals: function (a, aStart, b, bStart, length) {
    for (let i = 0; i !== length; ++i)
      if (a[aStart + i] !== b[bStart + i]) return false;

    return true;
  },

  stridedCopy: function (
    src,
    srcOffset,
    srcStride,
    dst,
    dstOffset,
    dstStride,
    elementLength,
    numElements
  ) {
    for (let i = 0; i !== numElements; ++i)
      for (let j = 0; j !== elementLength; ++j)
        dst[dstOffset + i * dstStride + j] = src[srcOffset + i * srcStride + j];

    return dst;
  },

  /**
   * For each element add the previous element.
   *
   * @param {!Array<!number>} array
   */
  accumulate: function (array) {
    for (let i = 1, n = array.length; i < n; ++i) array[i] += array[i - 1];

    return array;
  },

  reverseRange: function (array, start, end) {
    start = start != null ? start : 0;
    end = end != null ? end : array.length;

    const last = end - 1;

    const count = end - start;

    const midCount = Math.floor(count - 1 / 2);

    for (let i = 0; i <= midCount; i++) {
      const iS = start + i;
      const iL = last - i;

      const tmp = array[iS];
      array[iS] = array[iL];
      array[iL] = tmp;
    }

    return array;
  },

  slice: function (array, from, optionalTo) {
    // Not all browsers have .slice for typed arrays...

    return array.slice !== undefined
      ? array.slice(from, optionalTo)
      : new array.constructor(array.subarray(from, optionalTo));
  },

  clone: function (array) {
    // Not all browsers have .slice for typed arrays...

    return array !== null
      ? array.slice !== undefined
        ? array.slice(0)
        : new array.constructor(array)
      : null;
  },

  binarySearch: function (array, elem, optionalBegin, optionalUntil) {
    let left = optionalBegin === undefined ? 0 : optionalBegin,
      right = optionalUntil === undefined ? array.length - left : optionalUntil,
      rightBound = right;

    while (left < right) {
      let mid = (left + right) >>> 1,
        midVal = array[mid];

      if (midVal < elem) left = mid + 1;
      else right = mid;
    }

    return left < rightBound && array[left] === elem ? left : ~left;
  },

  sort: function (array, optionalOrder) {
    // TODO: Optimized sorting - a likely and improvable bottleneck:
    //
    // - A customized sort beats Chrome by a factor of 18 at block
    //   sizes of 10.000 elements, where the performance is roughly
    //   equal for a few elements. At this scale Chrome is already
    //   3-4 times slower than Firefox - the more elements there are
    //   the worse it gets.
    //
    // - The same routine beats Firefox by a factor of 5 with small
    //   block sizes, where the performance becomes roughly equal at
    //   10.000 elements.
    //
    // My test code did not contain duplicate elements (and does not
    // handle them well, in the moment) and used a Quicksort with a
    // median index pivot (which is rather lousy). It did implement
    // a decent partitioner based on Hoare's algorithm, though.

    if (array.sort !== undefined) {
      return array.sort(optionalOrder || Orders.Numeric);
    } else {
      // some browsers may not yet support the above

      return Array.prototype.sort.call(array, optionalOrder || Orders.Numeric);
    }

    return array;
  },

  unique: function (sortedArray, optionalOrder) {
    const n = sortedArray.length;

    if (n <= 1) return sortedArray.length;

    let writeIndex = 0,
      prevValue = sortedArray[0];

    if (optionalOrder === undefined) {
      for (let i = 1; i !== n; ++i) {
        const value = sortedArray[i];
        if (value !== prevValue) {
          prevValue = value;

          if (++writeIndex !== i) sortedArray[writeIndex] = value;
        }
      }
    } else {
      const compare = optionalOrder;

      if (!optionallyBuiltMap) {
        for (let i = 1; i !== n; ++i) {
          const value = sortedArray[i];
          if (compare(value, prevValue) !== 0) {
            prevValue = value;

            if (++writeIndex !== i) sortedArray[writeIndex] = value;
          }
        }
      }
    }

    return writeIndex + 1; // new length
  },

  //

  /**
   * Utiltiy function for taking an argument that is either an existing
   * array or an array type / constructor.
   *
   * Example:
   * <pre>
   * const array = Arrays.maybeCreate( arg || Uint32Array, n );
   * </pre>
   *
   * @param {!ArrayOrType} existingArrayOrType
   * @param {number=} requiredLength
   * @throws Error when argument missing or an array and too short
   */
  maybeCreate: function (existingArrayOrType, requiredLength) {
    if (!existingArrayOrType) throw Error('Missing array!');

    const validLength = requiredLength !== null && !isNaN(requiredLength);

    // Safari returns "object" for typeof Uint32Array, Float32Array, etc, so use instanceof
    if (
      typeof existingArrayOrType === 'function' ||
      existingArrayOrType instanceof Function
    ) {
      if (!validLength) throw Error('Missing or invalid length!');

      // Note: Browsers show wEirD behavior when it comes to
      // argument checks of typed array constructors...

      return new existingArrayOrType(requiredLength);
    }

    // otherwise assume we got an existing array

    if (validLength && existingArrayOrType.length < requiredLength)
      throw Error(
        'Array too short: ' +
          existingArrayOrType.length +
          ' < ' +
          requiredLength
      );

    return existingArrayOrType;
  },

  copyObjects: function (objects, array, optionalStride) {
    if (!objects) return null;

    const nObjects = objects.length;
    if (nObjects === 0) return array;

    const stride = objects[0].constructor.InstanceScalarSize || optionalStride;

    if (stride === undefined)
      throw Error('Arrays.fromObjects: Cannot determine stride!');

    const result = Arrays.maybeCreate(arrayOrType, nObjects * stride);

    for (let i = 0, offset = 0; i !== nObjects; ++i, offset += stride) {
      const object = objects[i];

      if (object === undefined) {
        console.warn('Arrays.fromObjects: Undefined object.');
        array.fill(0, offset, offset + stride);
        continue;
      }

      object.toArray(array, offset);
    }

    return array;
  },
};

export default Arrays;
