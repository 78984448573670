import { OperatorData, OperatorDefinition, PropertySchema } from '../../types';

export const arExportable: PropertySchema = {
  type: 'Options',
  label: 'Exportable to AR',
  values: [3, 2, 1, 0],
  labels: ['All', 'Android', 'iOS', 'None'],
  defaultValue: 3,
};

const Default: OperatorDefinition = {
  schema: {
    visible: {
      label: 'Visible',
      type: 'Boolean',
      defaultValue: true,
      animatable: true,
    },
    arExportable,
    selectable: { label: 'Selectable', type: 'Boolean', defaultValue: true },
  },
};

export default Default;
