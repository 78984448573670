import { Vector3 } from 'three';
// FIXME: is this not exported from three?
const DEG2RAD = 0.017453292519943295;

import ObjectArrayView from '../../generic/container/ObjectArrayView';
import PolyMap from '../model/PolyMap';
import PolyMesh from '../model/PolyMesh';

function getIndexOnFaceFromValue(
  mesh: PolyMesh,
  vertexIndex: number,
  face: number
) {
  const faceRangeOffsets = mesh.faceRangeOffsets;
  const faceValueIndices = mesh.positions.faceValueIndices;
  for (let i = faceRangeOffsets[face]; i < faceRangeOffsets[face + 1]; i++) {
    if (faceValueIndices[i] === vertexIndex) {
      return i;
    }
  }
  return -1;
}

export function flatNormalMap(mesh: PolyMesh) {
  const faceRangeOffsets = mesh.positions.faceRangeOffsets;
  const faceValueIndices = mesh.positions.faceValueIndices;
  const values = mesh.positions.values;
  const normalFaceValueIndices = new Uint32Array(faceValueIndices.length);
  const normals = new ObjectArrayView(Vector3, faceRangeOffsets.length - 1);

  const v1 = new Vector3();
  const v2 = new Vector3();
  const currentValue = new Vector3();

  let lowerLimit = faceRangeOffsets[0];
  for (let i = 1; i < faceRangeOffsets.length; ++i) {
    const upperLimit = faceRangeOffsets[i];
    if (lowerLimit + 3 <= upperLimit) {
      // the face is valid; at least 3 vertices
      values.getAt(faceValueIndices[lowerLimit], currentValue);
      values.getAt(faceValueIndices[lowerLimit + 1], v1);
      values.getAt(faceValueIndices[lowerLimit + 2], v2);

      v1.subVectors(v1, currentValue);
      v2.subVectors(v2, currentValue);

      currentValue.crossVectors(v1, v2).normalize();

      const idx = i - 1;
      normals.setAt(idx, currentValue);

      // fill with the indices and that flat normal
      for (let j = lowerLimit; j < upperLimit; ++j) {
        normalFaceValueIndices[j] = idx;
      }
    }
    lowerLimit = upperLimit;
  }

  const normalMap = new PolyMap({
    faceRangeOffsets: mesh.positions.faceRangeOffsets,
    faceValueIndices: normalFaceValueIndices,
    values: normals,
  });
  return new PolyMesh(mesh, { normalMap });
}

export function areaWeightedNormalMap(mesh: PolyMesh) {
  const faceValueIndices = mesh.positions.faceValueIndices;
  const values = mesh.positions.values;
  const valueAdjacency = mesh.positions.valueAdjacency;

  const v1 = new Vector3();
  const v2 = new Vector3();
  const accumulation = new Vector3();
  const currentValue = new Vector3();

  const normals = new ObjectArrayView(Vector3, values.length);

  for (let i = 0; i < values.length; i++) {
    const numFacesOnVertex = valueAdjacency.getNumFacesForVertex(i);
    const facesOnVertex = [];

    // get faces on vertex
    for (let j = 0; j < numFacesOnVertex; j++) {
      facesOnVertex.push(valueAdjacency.getFaceForVertexId(i, j));
    }

    values.getAt(i, currentValue);
    accumulation.set(0.0, 0.0, 0.0);

    // get weighted accumulation of face normals for all faces on vertex
    for (const vertex of facesOnVertex) {
      const adjacentValues = valueAdjacency.getAdjacentFaceValuesIndicesOnFace(
        getIndexOnFaceFromValue(mesh, i, vertex),
        vertex
      );
      if (adjacentValues.length === 2) {
        values.getAt(faceValueIndices[adjacentValues[0]], v1);
        values.getAt(faceValueIndices[adjacentValues[1]], v2);
        v1.subVectors(currentValue, v1);
        v2.subVectors(currentValue, v2);
        accumulation.add(v2.cross(v1));
      }
    }

    accumulation.normalize();
    normals.setAt(i, accumulation);
  }

  const normalMap = new PolyMap({
    faceRangeOffsets: mesh.positions.faceRangeOffsets,
    faceValueIndices: mesh.positions.faceValueIndices,
    values: normals,
  });
  return new PolyMesh(mesh, { normalMap });
}

export function autoSmooth(mesh: PolyMesh, smoothingAngle?: number) {
  smoothingAngle = smoothingAngle !== undefined ? smoothingAngle : 30;
  const polyMesh = mesh;
  const smoothingCosineAngle = Math.cos(smoothingAngle * DEG2RAD);
  const faceRangeOffsets = polyMesh.faceRangeOffsets;
  const faceValueIndices = polyMesh.positions.faceValueIndices;
  const values = polyMesh.positions.values;
  const normalMapFaceValueIndices = new Uint32Array(
    polyMesh.positions.faceValueIndices.length
  );
  const normalMapValuesArray = [];

  const v1 = new Vector3();
  const v2 = new Vector3();
  const currentValue = new Vector3();
  const currentN = new Vector3();
  const nextN = new Vector3();

  const valueAdjacency = polyMesh.positions.valueAdjacency;
  const faceFaceAdjacency = polyMesh.positions.faceFaceAdjacency;

  let normalIndex = 0;

  function setNormalIndex(vertexIndex: number, face: number, index: number) {
    const faceArray = [];
    for (let j = faceRangeOffsets[face]; j < faceRangeOffsets[face + 1]; j++) {
      faceArray.push(faceValueIndices[j]);
      if (faceValueIndices[j] === vertexIndex) {
        normalMapFaceValueIndices[j] = index;
        return;
      }
    }

    console.error(
      'index ' + vertexIndex + ' is not on face ' + face,
      faceArray
    );
  }

  // for every vertex
  for (let i = 0; i < values.length; i++) {
    const numFacesOnVertex = valueAdjacency.getNumFacesForVertex(i);
    const facesOnVertex = [];
    const faceNormalMapping = [];
    values.getAt(i, currentValue);

    // get faces on vertex
    for (let j = 0; j < numFacesOnVertex; j++) {
      facesOnVertex.push(valueAdjacency.getFaceForVertexId(i, j));
      faceNormalMapping.push(-1);
    }

    // for every face around vertex
    for (let j = 0; j < facesOnVertex.length; j++) {
      if (faceNormalMapping[j] > -1) continue;

      const startFace = facesOnVertex[j];
      const startFaceAdjacentFaces = faceFaceAdjacency.adjacentFacesOnVertex(
        facesOnVertex[j],
        i
      );

      const facesInGroup = [];
      facesInGroup.push(startFace);

      let current = startFace;
      let next = startFaceAdjacentFaces[0];
      let nextNext = -1;

      // walk around the left, and then the right
      // tslint:disable:prefer-for-of
      for (let k = 0; k < startFaceAdjacentFaces.length; ++k) {
        // walk around the faces around the vertex
        for (let f = 0; f < facesOnVertex.length; ++f) {
          // tslint:enable:prefer-for-of
          if (
            next === startFace ||
            faceNormalMapping[facesOnVertex.indexOf(next)] > -1
          ) {
            // full circle, already alocated or hard edge
            break;
          }

          const adjacentValuesCurrent = valueAdjacency.getAdjacentFaceValuesIndicesOnFace(
            getIndexOnFaceFromValue(polyMesh, i, current),
            current
          );
          values.getAt(faceValueIndices[adjacentValuesCurrent[0]], v1);
          values.getAt(faceValueIndices[adjacentValuesCurrent[1]], v2);
          v1.subVectors(currentValue, v1);
          v2.subVectors(currentValue, v2);
          currentN.crossVectors(v2, v1).normalize();

          const adjacentValuesNext = valueAdjacency.getAdjacentFaceValuesIndicesOnFace(
            getIndexOnFaceFromValue(polyMesh, i, next),
            next
          );
          values.getAt(faceValueIndices[adjacentValuesNext[0]], v1);
          values.getAt(faceValueIndices[adjacentValuesNext[1]], v2);
          v1.subVectors(currentValue, v1);
          v2.subVectors(currentValue, v2);
          nextN.crossVectors(v2, v1).normalize();

          if (currentN.dot(nextN) < smoothingCosineAngle) {
            // angle not smooth enough; dot product of unity length vectors is the cosine of the angle between them
            break;
          }
          // else face add to smoothing group
          facesInGroup.push(next);
          const nextAdjacentFaces = faceFaceAdjacency.adjacentFacesOnVertex(
            next,
            i
          );
          // edge found
          if (nextAdjacentFaces.length < 2) break;

          nextNext =
            nextAdjacentFaces[0] === current
              ? nextAdjacentFaces[1]
              : nextAdjacentFaces[0];
          current = next;
          next = nextNext;
        }

        // walk the other way on the next
        current = startFace;
        next = startFaceAdjacentFaces[1];
        nextNext = -1;
      }
      // tslint:enable:prefer-for-of
      const accumulation = new Vector3();
      for (const face of facesInGroup) {
        setNormalIndex(i, face, normalIndex);
        faceNormalMapping[facesOnVertex.indexOf(face)] = normalIndex;

        // accumulate weighted normal
        const adjacentValues = valueAdjacency.getAdjacentFaceValuesIndicesOnFace(
          getIndexOnFaceFromValue(polyMesh, i, face),
          face
        );
        if (adjacentValues.length === 2) {
          values.getAt(faceValueIndices[adjacentValues[0]], v1);
          values.getAt(faceValueIndices[adjacentValues[1]], v2);
          v1.subVectors(currentValue, v1);
          v2.subVectors(currentValue, v2);
          accumulation.add(v2.cross(v1));
        }
      }

      accumulation.normalize();
      normalMapValuesArray.push(accumulation);
      if (facesInGroup.length > 0) normalIndex++;
    }
  }

  const normalMapValues = new ObjectArrayView(
    Vector3,
    normalMapValuesArray.length
  );
  for (let i = 0; i < normalMapValuesArray.length; i++) {
    normalMapValues.setAt(i, normalMapValuesArray[i]);
  }

  const normalMap = new PolyMap(
    {
      faceRangeOffsets: polyMesh.faceRangeOffsets,
      faceValueIndices: normalMapFaceValueIndices,
      values: normalMapValues,
    },
    undefined
  );

  return new PolyMesh(polyMesh, { normalMap });
}

export function recalculateNormalsFromConnectivity(mesh: PolyMesh) {
  if (!mesh || !mesh.normalMap || !mesh.normalMap.faceValueIndices) return mesh;

  const faceRangeOffsets = mesh.faceRangeOffsets;
  const faceValueIndices = mesh.positions.faceValueIndices;
  const normalFaceValueIndices = mesh.normalMap.faceValueIndices;
  const values = mesh.positions.values;
  const newNormalValues = new ObjectArrayView(
    Vector3,
    mesh.normalMap.values.length
  );

  const v0 = new Vector3();
  const v1 = new Vector3();
  const v2 = new Vector3();

  const nbFaceRange = faceRangeOffsets.length;
  let faceBegin = faceRangeOffsets[0];
  for (let i = 1; i < nbFaceRange; ++i) {
    const faceEnd = faceRangeOffsets[i];
    if (faceBegin + 3 <= faceEnd) {
      values.getAt(faceValueIndices[faceBegin], v1);
      values.getAt(faceValueIndices[faceBegin + 1], v0);
      values.getAt(faceValueIndices[faceBegin + 2], v2);

      v1.subVectors(v0, v1);
      v2.subVectors(v0, v2);
      v0.crossVectors(v2, v1).normalize();

      for (let j = faceBegin; j < faceEnd; ++j) {
        const idx = normalFaceValueIndices[j];
        newNormalValues.getAt(idx, v1);
        newNormalValues.setAt(idx, v1.add(v0));
      }
    }
    faceBegin = faceEnd;
  }

  for (let i = 0; i < newNormalValues.length; ++i) {
    newNormalValues.getAt(i, v0);
    newNormalValues.setAt(i, v0.normalize());
  }

  const normalMap = new PolyMap(mesh.normalMap, {
    values: newNormalValues,
  });
  return new PolyMesh(mesh, { normalMap });
}
