const Orders = {
  Numeric: function(a, b) {
    return a - b;
  },

  Natural: function(a, b) {
    return a < b ? -1 : b < a ? 1 : 0;
  },

  // Note: Unstable compare functions are not an optimization.
  // Some operations require to detect equality!

  derefedNumbersIn: function(array) {
    return function compare(a, b) {
      return array[a] - array[b];
    };
  },

  derefedTuplesIn: function(array, elementSize, optionalStride) {
    if (!elementSize) throw Error('Invalid element size!');

    const stride = optionalStride || elementSize;

    if (stride === 1 && elementSize === 1) return Orders.derefNumbersIn(array);

    return function compare(a, b) {
      const offsetA = a * stride;
      const offsetB = b * stride;

      let result = array[offsetA] - array[offsetB];

      for (let i = 1; i !== elementSize && result === 0; ++i)
        result = array[offsetA + i] - array[offsetB + i];

      return result;
    };
  },

  derefedNumbersStableIn: function(array) {
    return function compare(a, b) {
      const result = array[a] - array[b];
      return result !== 0 ? result : a - b;
    };
  },

  derefedTuplesStableIn: function(array, elementSize, optionalStride) {
    if (!elementSize) throw Error('Invalid element size!');

    const stride = optionalStride || elementSize;

    if (stride === 1 && elementSize === 1)
      return Orders.derefNumbersStableIn(array);

    return function compare(a, b) {
      const offsetA = a * stride;
      const offsetB = b * stride;

      let result = array[offsetA] - array[offsetB];

      for (let i = 1; i !== elementSize && result === 0; ++i)
        result = array[offsetA + i] - array[offsetB + i];

      return result !== 0 ? result : a - b;
    };
  },
};

export default Orders;
