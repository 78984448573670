import { Box3, Vector3 } from 'three';
import PolyMap from '../model/PolyMap';

/**
 *
 * @param {PolyMap|ObjectArrayView} positions
 * @param {Array|TypedArray} optionalIndices
 */
export default function findBoundingBox(positions, optionalIndices) {
  const indices =
    optionalIndices ||
    (positions instanceof PolyMap && positions.faceValueIndices);
  const values = positions instanceof PolyMap ? positions.values : positions;
  const boundingBox = new Box3();

  const vertex = new Vector3();

  if (indices) {
    // get positions associated with specified indices
    for (let i = 0; i < indices.length; i++) {
      values.getAt(indices[i], vertex);
      boundingBox.expandByPoint(vertex);
    }
  } else {
    // use all provided positions for bbox
    for (let i = 0; i < positions.length; i++) {
      values.getAt(i, vertex);
      boundingBox.expandByPoint(vertex);
    }
  }
  return boundingBox;
}
