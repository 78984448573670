import { v4 as uuid } from 'uuid';
import {
  hasId,
  isArraySchema,
  isObjectSchema,
  isPrimitiveSchema,
  schemas,
} from './schemas';
import { Lookups } from './lookups';

export function initObject(
  schemaType: string,
  attrs: any,
  parentKey?: string,
  lookup?: Lookups
) {
  // console.log('init Object', schemaType, attrs);
  if (schemaType === 'String') return attrs || '';
  if (schemaType === 'Primitive') return attrs || {};
  if (schemaType === 'Boolean') return attrs || false;

  const schema = schemas[schemaType];
  if (!schema) throw new Error(`Unknown schema type: ${schemaType}`);

  const obj: any = {};

  if (hasId(schema)) obj.id = attrs.id || uuid();

  if (isPrimitiveSchema(schema)) {
    // console.log('lookup?', schemaType, parentKey, attrs.type, lookup);

    if (!parentKey && schemaType === 'Operator') {
      throw new Error('Operator requires parent plug');
    }

    // Ensure we init the ID if none is given by using the one on obj.
    Object.assign(obj, attrs);
    if (lookup && lookup[schemaType]) {
      // console.log('looking up operator', parentKey, attrs.type);
      const operator = lookup[schemaType](parentKey, obj.type);
      return operator ? operator.init(obj, obj.type, obj.name) : obj;
    } else {
      return obj;
    }
  } else if (isObjectSchema(schema)) {
    if (schema.keys) {
      const keyKeys = Object.keys(schema.keys);
      for (const key of keyKeys) {
        const optional = schema.optionalKeys && schema.optionalKeys[key];

        // console.log('key', key, attrs && attrs[key]);
        const subSchema = schemas[schema.keys[key]];
        if (isArraySchema(subSchema)) {
          obj[key] = ((attrs && attrs[key]) || []).map((v: any) =>
            initObject(subSchema.of, v, key, lookup)
          );
        } else {
          if (!optional || (attrs && attrs[key])) {
            obj[key] = initObject(
              schema.keys[key],
              (attrs && attrs[key]) || undefined,
              key,
              lookup
            );
          }
        }
      }
    }
    if (schema.as) {
      const attrKeys = attrs ? Object.keys(attrs) : [];
      for (const key of attrKeys) {
        obj[key] = initObject(schema.as, attrs[key], key, lookup);
      }
    }
  } else if (isArraySchema(schema)) {
    return attrs.map((v: any) => initObject(schema.of, v, parentKey, lookup));
  }

  return obj; // { id, children, name, type, parent, plugs, warnings, _v: 0 };
}
