import { Vector3 } from 'three';
import ObjectArrayView from '../../generic/container/ObjectArrayView';

/**
 * To compute the normal of a face
 *
 * @param values - an ObjectArrayView of the vertices
 * @param indices - an array of the indices
 * @param faceBegin - the index where the face indices begin
 * @param faceUntil - the index where the following face begins
 * @param dontNormalize - to not normalize the result
 * @param optional??? - to provide preallocated Vector3 needed to compute
 * @return the face normal
 */
export function computeFaceNormal(
  values,
  indices,
  faceBegin,
  faceUntil,
  dontNormalize,
  optionalFaceNormal,
  optionalFirstVertex,
  optionalCurrVector,
  optionalNextVector,
  optionalCrossVector
) {
  const firstVertex = optionalFirstVertex || new Vector3();
  let currVector = optionalCurrVector || new Vector3();
  let nextVector = optionalNextVector || new Vector3();
  const crossVector = optionalCrossVector || new Vector3();

  // reuse the face normal, if provided, and reset it for accurate computation
  const faceNormal = optionalFaceNormal || new Vector3();
  if (optionalFaceNormal) faceNormal.set(0.0, 0.0, 0.0);

  let swapper, index;

  // read the first vertex
  index = indices[faceBegin];
  values.getAt(index, firstVertex);

  // read the second vertex and substract the first to get the first vector
  index = indices[faceBegin + 1];
  values.getAt(index, currVector);
  currVector.sub(firstVertex);

  for (let fi = faceBegin + 2; fi < faceUntil; ++fi) {
    // read the new vertex and compute the new vector
    index = indices[fi];
    values.getAt(index, nextVector);
    nextVector.sub(firstVertex);

    // compute the cross product and add it to the accumulated face normal sum
    crossVector.crossVectors(currVector, nextVector);
    faceNormal.add(crossVector);

    // swap currVector and nextVector
    swapper = currVector;
    currVector = nextVector;
    nextVector = swapper;
  }

  if (!dontNormalize) faceNormal.normalize();
  return faceNormal;
}

export default function FaceNormals(polyMap) {
  this.polyMap = polyMap;

  this._compute();
}

FaceNormals.prototype = {
  constructor: FaceNormals,

  getNormalForFaceId: function (faceIndex, optionalTarget) {
    const result = optionalTarget || new Vector3();
    this.faceNormalValues.getAt(faceIndex, result);
    return result;
  },

  _compute: function () {
    const polyMap = this.polyMap;

    const faceRangeOffsets = polyMap.faceRangeOffsets;
    const faceValueIndices = polyMap.faceValueIndices;
    const values = polyMap.values;

    const faceCount = faceRangeOffsets.length - 1;
    const faceNormalValues = new ObjectArrayView(Vector3, faceCount);

    const firstVertex = new Vector3();
    const currVector = new Vector3();
    const nextVector = new Vector3();
    const crossVector = new Vector3();
    const faceNormal = new Vector3();

    for (let f = 0; f < faceCount; ++f) {
      const faceBegin = faceRangeOffsets[f];
      const faceUntil = faceRangeOffsets[f + 1];
      computeFaceNormal(
        values,
        faceValueIndices,
        faceBegin,
        faceUntil,
        false,
        faceNormal,
        firstVertex,
        currVector,
        nextVector,
        crossVector
      );
      faceNormalValues.setAt(f, faceNormal);
    }

    this.faceNormalValues = faceNormalValues;
  },
};
