export default function MergeSort(arrayType, maxRangeLength) {
  this.ping = new arrayType(maxRangeLength);
  this.pong = new arrayType(maxRangeLength);
}

MergeSort.apply = function(array) {
  const sorter = new MergeSort(array.constructor, array.length);
  sorter.sortRange(array, 0, array.length);
};

MergeSort.prototype = {
  constructor: MergeSort,

  sortRange: function(array, begin, until) {
    const length = until - begin;

    if (length <= 4) {
      // this implementation requires at least three elements and also
      // has a high base cost when it comes to very short ranges

      if (length >= 2) this._handleShortRange(array, begin, length);
      return;
    }

    let read = array;
    let readBegin = begin;
    let readUntil = until;

    let write = this.ping;
    let writeOffset = 0;

    let blockSize = 1;
    let blockPairSize = 2;

    for (let run = true; run; ) {
      let nMerges = ((length + blockSize - 1) / blockSize) >> 1,
        l = readBegin,
        endR = readBegin + blockPairSize;

      for (let last = 0; last <= 1; ++last) {
        for (
          let i = 1;
          i !== nMerges;
          ++i, l += blockSize, endR += blockPairSize
        ) {
          let r = l + blockSize,
            endL = r,
            valL = read[l],
            valR = read[r];

          for (let merge = true; merge; ) {
            if (valR < valL) {
              write[writeOffset++] = valR;

              if (++r === endR) {
                write[writeOffset++] = valL;
                while (++l !== endL) write[writeOffset++] = read[l];
                merge = false;
              } else valR = read[r];
            } else {
              write[writeOffset++] = valL;

              if (++l === endL) {
                write[writeOffset++] = valR;
                while (++r !== endR) write[writeOffset++] = read[r];
                merge = false;
              } else valL = read[l];
            }
          }
        }

        // Handle edge cases before the last pass:

        if (last === 0) {
          nMerges = 2; // one more merge (1-based, exclusive)

          if (endR > readUntil) endR = readUntil;
          else if (endR !== readUntil) {
            let writeOffsetTemp = writeOffset + blockPairSize;

            for (let j = endR; j !== readUntil; ++j)
              write[writeOffsetTemp++] = read[j];
          }
        }
      }

      if (write !== array) {
        // not done yet? prepare next level

        blockSize = blockPairSize;
        blockPairSize <<= 1;

        read = write;
        readBegin = 0;
        readUntil = length;

        if (blockPairSize < length) {
          // at least two more levels? destination is temporary

          const ping = this.ping;
          write = write === ping ? this.pong : ping;
          writeOffset = 0;
        } else {
          // next will be the last level -> write back to source

          write = array;
          writeOffset = begin;
        }
      } else run = false;
    }
  },

  _handleShortRange: function(array, begin, length) {
    let valA = array[begin];
    let valB = array[begin + 1];

    switch (length) {
      case 2:
        if (valB < valA) {
          array[begin] = valB;
          array[begin + 1] = valA;
        }

        break;

      case 3: {
        let valC = array[begin + 2];

        if (valB < valA) {
          const tmp = valA;
          valA = valB;
          valB = tmp;
        }

        if (valC < valB) {
          const tmp = valB;
          valB = valC;
          valC = tmp;
        }

        if (valB < valA) {
          const tmp = valA;
          valA = valB;
          valB = tmp;
        }

        array[begin] = valA;
        array[begin + 1] = valB;
        array[begin + 2] = valC;

        break;
      }

      case 4: {
        let valC = array[begin + 2];
        let valD = array[begin + 3];

        if (valB < valA) {
          const tmp = valA;
          valA = valB;
          valB = tmp;
        }

        if (valD < valC) {
          const tmp = valC;
          valC = valD;
          valD = tmp;
        }

        if (valC < valA) {
          const tmp = valA;
          valA = valC;
          valC = tmp;
        }

        if (valD < valB) {
          const tmp = valB;
          valB = valD;
          valD = tmp;
        }

        if (valC < valB) {
          const tmp = valB;
          valB = valC;
          valC = tmp;
        }

        array[begin] = valA;
        array[begin + 1] = valB;
        array[begin + 2] = valC;
        array[begin + 3] = valD;
      }
    }
  },
};
