import { DiffObjects } from './diffTree';

export interface ReferencesType {
  [key: string]: string;
}

export function diffReferences(
  toReferences: ReferencesType,
  fromReferences: ReferencesType = {},
  secondReferences: ReferencesType = {}
): DiffObjects {
  const arrResult = [];

  for (const refKey of Object.keys(toReferences)) {
    arrResult.push({
      action: 'SET',
      type: 'Reference',
      path: JSON.parse(refKey),
      value: toReferences[refKey],
    });
    // if (fromReferences[refKey] !== references[refKey]) {
    // }
  }
  for (const refKey of Object.keys(secondReferences)) {
    if (secondReferences[refKey] !== toReferences[refKey]) {
      arrResult.push({
        action: 'SET',
        type: 'Reference',
        path: JSON.parse(refKey),
        value: toReferences[refKey],
      });
    }
  }
  for (const refKey of Object.keys(fromReferences)) {
    if (!toReferences[refKey] && !secondReferences[refKey]) {
      arrResult.push({
        action: 'SET',
        type: 'Reference',
        path: JSON.parse(refKey),
        value: null,
      });
    }
  }

  return arrResult;
}
