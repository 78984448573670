export interface Option<T> {
  value: T;
  label: string;
}

export function ToOptions<T>(x: { [key: string]: T }): Array<Option<T>> {
  return Object.entries(x).map(([label, value]) => ({ label, value }));
}

export type KeyedOperator<T> = { [P in keyof T]: any };
