import { Matrix4, Vector3 } from 'three';
import ObjectArrayView from '../../generic/container/ObjectArrayView';
import ScalarArrayView from '../../generic/container/ScalarArrayView';
import Arrays from '../../generic/utility/Arrays';
import PolyMaps from '../model/PolyMaps';
import PolyMap from '../model/PolyMap';
import PolyMesh from '../model/PolyMesh';
import BlendShapeData from '../model/BlendShapeData';
import Skinning from '../model/Skinning';
import ImportConversions from '../algorithm/ImportConversions';

export default function Importer() {
  this.defaultFaceArity = 3; // triangles
  this.collapsePositions = true; // re-index positions
  this.collapseMapValues = true; // join equal map values at the same vertex

  this.faceArities = null;
  this.materialIds = null;

  this.mapIds = [];
  this.mapValues = {};
  this.mapIndices = {};

  this.skinning = null;
  this.creases = null;
}

Importer.prototype = {
  constructor: Importer,

  setFaceArities: function (
    array,
    optionalNumberOfElements,
    optionalOffset,
    optionalStride
  ) {
    this.faceArities = new ScalarArrayView(
      optionalNumberOfElements || null,
      array,
      optionalOffset,
      optionalStride
    );

    return this;
  },

  setMaterialIds: function (materialIds) {
    for (let i = 0; i < materialIds.length; i++) {
      //only add to the polymesh if array contains a non zero value
      if (materialIds[i] > 0) {
        this.materialIds = materialIds;
        break;
      }
    }

    return this;
  },

  setMapValues: function (
    mapId,
    array,
    optionalNumberOfElements,
    optionalOffset,
    optionalStride
  ) {
    const id = PolyMaps.checkId(mapId);
    const ids = this.mapIds;

    if (ids.indexOf(id) === -1) ids.push(id);

    this.mapValues[id] = new ObjectArrayView(
      PolyMaps.getTypeInfo(PolyMaps.getAnchorName(mapId)).ElementType,
      optionalNumberOfElements || null,
      !optionalOffset ? array : array.subarray(optionalOffset),
      optionalStride
    );

    return this;
  },

  setMapIndices: function (
    mapId,
    array,
    optionalNumberOfElements,
    optionalOffset,
    optionalStride
  ) {
    this.mapIndices[PolyMaps.checkId(mapId)] = new ScalarArrayView(
      optionalNumberOfElements || null,
      array,
      optionalOffset,
      optionalStride
    );

    return this;
  },

  initializeBlendShape: function () {
    this.blendShapes = {};

    return this;
  },

  setBlendShapes: function (name, positions, normals, weights) {
    this.blendShapes[name] = new BlendShapeData(
      new ObjectArrayView(Vector3, null, positions),
      !!normals ? new ObjectArrayView(Vector3, null, normals) : null,
      !!weights ? new ScalarArrayView(weights.length, weights) : null
    );
    return this;
  },

  setSkinning: function (
    positionSkinRange,
    skinWeights,
    skinBoneIndices,
    poseSkinToPoseBoneTransform
  ) {
    this.skinning = {};

    this.skinning.positionSkinRange = positionSkinRange;
    this.skinning.skinWeights = skinWeights;
    this.skinning.skinBoneIndices = skinBoneIndices;

    this.skinning.poseSkinToPoseBoneTransform = new ObjectArrayView(
      Matrix4,
      null,
      poseSkinToPoseBoneTransform
    );

    return this;
  },

  setCreases: function (weights, edges) {
    this.creases = { weights, edges };
  },

  setSmoothGroup: function (smoothGroup) {
    this.smoothGroup = smoothGroup;
  },

  toMeshAndClear: function () {
    const mapIds = this.mapIds;
    const mapValueData = this.mapValues;
    const mapIndexData = this.mapIndices;

    const positionMapId = PolyMaps.IdPositions;
    let positions = mapValueData[positionMapId];

    let faceArities = this.faceArities;
    let materialIds = this.materialIds;

    if (!positions) throw Error('No positions!');

    const positionIndices = mapIndexData[positionMapId] || null;

    const nFaceVertices = positionIndices
      ? positionIndices.length
      : positions.length;

    let faceOffsets = null;
    //  -----------

    if (faceArities === null) {
      const fixedArity = this.defaultFaceArity;
      const nFaces = (nFaceVertices / fixedArity) | 0;

      faceArities = new Uint32Array(nFaces + 1).fill(fixedArity, 1);
    } else {
      faceArities = faceArities.toArray(Uint32Array, 1);
    }

    // Here faceOffsets contains the face arities after a zero at index 0,
    // their accumulation yields the offsets (including length sentinel):
    faceOffsets = Arrays.accumulate(faceArities); // (in-place)

    for (let i = 0; i !== mapIds.length; ++i) {
      // avoid aliasing with the map indices in case it's the same array

      const mapId = mapIds[i];
      if (mapId === positionMapId) continue;

      const mapIndexView = mapIndexData[mapId];

      if (mapIndexView !== undefined) mapIndexView.forceRepack(Uint32Array);
    }

    let indices = null;
    if (this.collapsePositions) {
      // re-index values
      if (positionIndices !== null)
        indices = positionIndices.repack(Uint32Array).data;

      positions = ImportConversions.collapsePositions(positions, indices);
    } else if (positionIndices !== null) {
      // use given indices as-is
      indices = positionIndices.repack(Uint32Array).data;
    }

    const mesh = PolyMesh.fromData(
      faceOffsets,
      positions,
      indices,
      materialIds
    );

    for (let i = 0; i !== mapIds.length; ++i) {
      const mapId = mapIds[i];
      if (mapId === positionMapId) continue;

      const mapIndexView = mapIndexData[mapId];
      const mapValueView = mapValueData[mapId];

      let polyMap;
      try {
        polyMap = PolyMap.fromData(
          faceOffsets,
          mapIndexView.data,
          mapValueView
        );
      } catch (e) {
        console.error(e);
        continue;
      }

      if (this.collapseMapValues) {
        ImportConversions.collapseMapVertexValues(mesh, polyMap);

        polyMap.compactValues();
      }

      PolyMaps.assignMap(mesh, mapId, polyMap);
    }

    if (this.skinning) mesh.skinning = new Skinning(this.skinning);

    if (this.creases) {
      const { weights, edges } = this.creases;
      // if edges array exists, need to calculate edge indices from the vertex pairs
      if (edges) {
        // generate edge indices from vertex pairs
        const edgeVertexAdjacency = mesh.positions.edgeVertexAdjacency;
        const edgeWeights = new Float32Array(edgeVertexAdjacency.getNumEdges());
        for (let i = 0, e = 0; i < edges.length; i += 2, e++) {
          edgeWeights[edgeVertexAdjacency.findEdgeId(edges[i], edges[i + 1])] =
            weights[e];
        }

        mesh.edgeCreaseWeights = edgeWeights;
      } else mesh.edgeCreaseWeights = weights; // no edges array, assume weight index = edge index
    }

    Importer.call(this); // re-run constructor to clear state

    if (this.blendShapes) {
      mesh.blendShapes = this.blendShapes;
    }

    if (this.smoothGroup) {
      mesh.smoothGroup = this.smoothGroup;
    }

    return mesh;
  },
};
