import ObjectsByName from '../../generic/container/ObjectsByName';
import PolyMap from '../model/PolyMap';
import PolyMesh from '../model/PolyMesh';

type PolyMapFunction = (
  polyMap: PolyMap<any>,
  component: string,
  args: any
) => PolyMap<any>;

export default function applyFunctionToPolyMap(
  polyMesh: PolyMesh,
  applyToPolyMapFunction: PolyMapFunction,
  args: any = {},
  replacements: any = {}
) {
  const resultMesh = new PolyMesh(polyMesh);
  resultMesh.positions = applyToPolyMapFunction(
    resultMesh.positions,
    'positions',
    args
  );
  if (resultMesh.normalMap) {
    resultMesh.normalMap = applyToPolyMapFunction(
      resultMesh.normalMap,
      'normalMap',
      args
    );
  }

  const uvMaps = new ObjectsByName();
  for (let i = 0; i < polyMesh.uvMaps.length; i++) {
    const name = polyMesh.uvMaps.namesByIndex[i];
    const uvMap = applyToPolyMapFunction(
      polyMesh.uvMaps.byName[name],
      name,
      args
    );
    uvMaps.set(name, uvMap);
  }

  resultMesh.uvMaps = uvMaps;
  resultMesh.faceRangeOffsets = resultMesh.positions.faceRangeOffsets;

  const finalMesh = new PolyMesh(resultMesh, replacements);

  return finalMesh;
}
