import Arrays from '../utility/Arrays';

export default function BlockBuffer(arrayType, nIntraBlockIndexBits) {
  this.blocks = [];
  this.lastBlock = null;
  this.arrayType = arrayType;

  const nBits = nIntraBlockIndexBits || 12;
  this.intraBlockIndexBits = nBits;
  this.intraBlockIndexMask = (1 << nBits) - 1;

  this.length = 0;
}

BlockBuffer.prototype = {
  constructor: BlockBuffer,

  elementSize: 1, // in scalars, constant default

  toArrayAndClear: function() {
    const result = this.toArray();
    this.clear();
    return result;
  },

  newRangeArray: function(optionalNumberOfElements) {
    const n = optionalNumberOfElements || 1;
    return new this.arrayType(n * this.elementSize);
  },

  rangeToArray: function(index, n, optionalArray, optionalWriteOffset) {
    const intraBlockIndexMask = this.intraBlockIndexMask;
    const elementSize = this.elementSize;

    let blockIndex = index >> this.intraBlockIndexBits;
    let blockOffset = index & intraBlockIndexMask;

    let block = this.blocks[blockIndex];

    const array = optionalArray || new this.arrayType(n * elementSize);
    let writeOffset =
      optionalWriteOffset === undefined ? 0 : optionalWriteOffset;

    for (let i = 0; i < n; ++i) {
      for (let j = blockOffset * elementSize, s = j + elementSize; j !== s; ++j)
        array[writeOffset++] = block[j];

      if ((++blockOffset & intraBlockIndexMask) === 0)
        block = this.blocks[++blockIndex];
    }

    return array;
  },

  arrayToRange: function(array, index, n, optionalReadOffset) {
    const intraBlockIndexMask = this.intraBlockIndexMask;
    const elementSize = this.elementSize;

    let blockIndex = index >> this.intraBlockIndexBits;
    let blockOffset = index & intraBlockIndexMask;

    let block = this.blocks[blockIndex];

    let readOffset = optionalReadOffset === undefined ? 0 : optionalReadOffset;

    for (let i = 0; i < n; ++i) {
      for (let j = blockOffset * elementSize, s = j + elementSize; j !== s; ++j)
        block[j] = array[readOffset++];

      if ((++blockOffset & intraBlockIndexMask) === 0)
        block = this.blocks[++blockIndex];
    }

    return this;
  },

  pushArrayRange: function(array, n, optionalReadOffset) {
    const intraBlockIndexMask = this.intraBlockIndexMask;
    const elementSize = this.elementSize;

    let blockOffset = this.length; // masked later

    let block = this.lastBlock;

    let readOffset = optionalReadOffset === undefined ? 0 : optionalReadOffset;

    for (let i = 0; i < n; ++i) {
      if ((blockOffset &= intraBlockIndexMask) === 0) {
        const blockSize = intraBlockIndexMask + 1;
        block = new this.arrayType(blockSize * elementSize);
        this.lastBlock = block;
        this.blocks.push(block);
      }

      for (
        let j = blockOffset++ * elementSize, s = j + elementSize;
        j !== s;
        ++j
      )
        block[j] = array[readOffset++];
    }

    return (this.length += n);
  },

  clear: function() {
    this.blocks = [];
    this.length = 0;
    this.lastBlock = null;

    return this;
  },

  toArray: function(optionalArrayOrType, optionalOffset) {
    let writeOffset = optionalOffset === undefined ? 0 : optionalOffset;
    const elementSize = this.elementSize;
    const minArraySize = this.length * elementSize + writeOffset;

    const array = Arrays.maybeCreate(
      optionalArrayOrType || this.arrayType,
      minArraySize
    );

    let blocks = this.blocks;
    const blockSize = (1 << this.intraBlockIndexBits) * elementSize;
    const nFullBlocks = Math.max(blocks.length - 1, 0);

    for (let i = 0; i !== nFullBlocks; ++i) {
      array.set(blocks[i], writeOffset);
      writeOffset += blockSize;
    }

    const lastBlockUse = minArraySize - writeOffset;

    if (lastBlockUse !== 0)
      array.set(blocks[nFullBlocks].subarray(0, lastBlockUse), writeOffset);

    return array;
  },
};
