import { Importer, PolyMaps, PolyMesh } from '.';

function validateAndRetype(data) {
  if (data instanceof Uint32Array) return data;
  else if (data instanceof ArrayBuffer) return new Uint32Array(data);
  else if (typeof Buffer !== 'undefined' && data instanceof Buffer) {
    return new Uint32Array(new Uint8Array(data).buffer);
  }

  throw new Error('Invalid bingeom type: ' + typeof data);
}

function extractFooter(data) {
  const jsonLength = data[data.length - 2];
  if (!jsonLength) throw new Error('No footer in bingeom');
  let charData = new Uint8Array(data.buffer);
  const len = charData.byteLength - 8;
  charData = charData.subarray(len - jsonLength, len);
  return JSON.parse(String.fromCharCode.apply(null, charData));
}

function validateDataInfo(dataInfo) {
  return (
    dataInfo !== undefined &&
    typeof dataInfo.o === 'number' &&
    typeof dataInfo.l === 'number'
  );
}

export function validatePolymeshData(jsonData) {
  return (
    jsonData &&
    validateDataInfo(jsonData.faceCount) &&
    validateDataInfo(jsonData.faces) &&
    validateDataInfo(jsonData.positions)
  );
}

function assert(truthy, message) {
  if (!truthy) throw new Error(message);
}

const RawUintByteSize = { 1: 'RawUint8', 2: 'RawUint16', 4: 'RawUint32' };

const types = {
  RawUint8: { shift: 0, arr: Uint8Array },
  RawUint16: { shift: 1, arr: Uint16Array },
  RawUint32: { shift: 2, arr: Uint32Array },
  RawInt32: { shift: 2, arr: Int32Array },
  RawFloat32: { shift: 2, arr: Float32Array },
};

function readArray(buffer, jsonData, dataType = null) {
  if (!jsonData) return dataType ? new types[dataType].arr(0) : [];
  const typeinfo = types[dataType || RawUintByteSize[jsonData.b || 4]];
  return new typeinfo.arr(buffer, jsonData.o, jsonData.l >> typeinfo.shift);
}

function noTriangulation(faces, faceSize) {
  return faces;
}

function staticTriangulation(faces, faceSize) {
  if (faces.length % faceSize !== 0)
    throw new Error('Invalid face index array');
  const trianglePerFace = faceSize - 2;
  const nbFaces = (3 * (faces.length * trianglePerFace)) / faceSize; // number of triangulated faces

  const newFaces = new faces.constructor(nbFaces);
  for (let inn = 0, out = 0, il = faces.length; out < il; ) {
    let first = faces[out++];
    let second = faces[out++];
    for (let j = trianglePerFace; j > 0; --j) {
      newFaces[inn++] = first;
      newFaces[inn++] = second;
      second = faces[out++];
      newFaces[inn++] = second;
    }
  }

  return newFaces;
}

function dynamicTriangulation(faces, faceCount) {
  let fl = faceCount.length;
  let nbFaces = -(fl << 1); // starts at -2*fl because the number of triangle in on face 'x' is faceCount[x]-2
  for (let i = fl - 1; i >= 0; --i) nbFaces += faceCount[i];

  const newFaces = new faces.constructor(nbFaces * 3); // can reuse the same constructor as faces because the max index is the same
  for (let inn = 0, out = 0, f = 0, fl = faceCount.length; f < fl; ++f) {
    let first = faces[out++];
    let second = faces[out++];
    for (let j = faceCount[f] - 2; j > 0; --j) {
      newFaces[inn++] = first;
      newFaces[inn++] = second;
      second = faces[out++];
      newFaces[inn++] = second;
    }
  }

  return newFaces;
}

let geometryCache = {};
let polyMeshCaches = {};

export function checkGeometryCache(hash) {
  return geometryCache[hash];
}

export function clearGeometryCache() {
  geometryCache = {};
  polyMeshCaches = {};
}

/*
 * cache geometry by file hash. This might break when we start adding
 * on operators, so verify that.
 */
export function cacheGeometry(hash, geometry) {
  geometryCache[hash] = geometry;
  return geometry;
}

export function convertToPolyMesh(data) {
  const binaryData = validateAndRetype(data);
  const jsonData = extractFooter(binaryData);
  //console.log('extracted footer: ', jsonData);
  const buffer = binaryData.buffer;
  assert(validatePolymeshData(jsonData), 'Invalid Polymesh Data?');

  const positions = readArray(buffer, jsonData.positions, 'RawFloat32');
  const fc = readArray(buffer, jsonData.faceCount);
  let faceCount = fc;
  if (fc.length === 1) faceCount = fc[0];
  const triangulationFunction =
    fc.length === 1
      ? fc[0] !== 3
        ? staticTriangulation
        : noTriangulation
      : dynamicTriangulation;
  const faces = triangulationFunction(
    readArray(buffer, jsonData.faces),
    faceCount
  );

  // normalMap
  const normalMap = jsonData.normalMap && {
    faces: triangulationFunction(
      readArray(buffer, jsonData.normalMap.faces),
      faceCount
    ),
    values: readArray(buffer, jsonData.normalMap.values, 'RawFloat32'),
  };

  let uvs = null;

  // uvMaps
  if (jsonData.uvMaps && Object.keys(jsonData.uvMaps).length) {
    uvs = {};
    for (let attr in jsonData.uvMaps) {
      if (jsonData.uvMaps.hasOwnProperty(attr)) {
        const map = jsonData.uvMaps[attr];
        // TODO for vray?: if (options.uvsToVec3Array) throw new Error('vec3ForUvs');

        uvs[attr] = {
          faces: triangulationFunction(readArray(buffer, map.faces), faceCount),
          values: readArray(buffer, map.values, 'RawFloat32'),
        };
      }
    }
  }

  // // extra data
  // if (tmp = jsonData.materialIds) {//this.materialIds = reader.readArray(tmp, "RawUint32");
  //   let rawMtl = reader.readArray(tmp, "RawUint32");
  //   if (triangulationFunction !== noTriangulation) {  // noTriangulation, material id already correct!
  //     let nbTriangulatedFaces = this.faces.length / 3;    // already know the right size of the material ids array
  //
  //     let materialTriangulationFunction = (triangulationFunction === staticTriangulation) ? staticMaterialTriangulation : dynamicMaterialTriangulation;
  //     rawMtl = materialTriangulationFunction(rawMtl, faceCount, nbTriangulatedFaces);
  //   }
  //   this.materialIds = rawMtl;
  // }
  // return this;
  return { positions, faces, normalMap, uvs };
}

const BIN_VERSION = 1000;
//Things to change when we eventually upgrade the format
// - remove all references to poseBoneToWorldTransform
// - materialIds should be a Uint8Array or at least a Uint16Array
// - materialIds could also be deprecated in favor of material ranges

/*
 * Convert PolyMesh to bingeom
 *
 */
export function convertToBingeom(polyMesh, options = {}) {
  //{ positions, faces, normalMap, uvs }) {
  const jsonFooter = { blendShapes: {}, colorMaps: {} }; //, uvMaps: { 'default': {}} };
  let byteLength = 0;
  const buffers = [];
  const bufferFooters = [];

  function extract(key, typedArray, footer, readInto) {
    const sourceBuf = readInto ? new readInto(typedArray) : typedArray;
    let buf = new Uint8Array(
      sourceBuf.buffer,
      sourceBuf.byteOffset,
      typedArray.byteLength
    );

    // pad buffer if necessary to maintain 4-byte alignment
    const overBytes = buf.byteLength % 4;
    let padBytes = 0;
    if (overBytes) {
      padBytes = 4 - overBytes;
      const paddedBuf = new Uint8Array(buf.byteLength + padBytes);
      paddedBuf.set(buf);
      buf = paddedBuf;
    }

    let reuseFooter = null;
    for (let i = 0; i < buffers.length; i++) {
      if (equalsArrayBuffers(buf, buffers[i])) {
        reuseFooter = bufferFooters[i];
        break;
      }
    }

    if (reuseFooter !== null) {
      footer[key] = reuseFooter;
    } else {
      const bytesPerElement = readInto
        ? readInto.BYTES_PER_ELEMENT
        : typedArray.BYTES_PER_ELEMENT;
      footer[key] = {
        b: bytesPerElement,
        l: buf.byteLength - padBytes,
        o: byteLength,
      };
      byteLength += buf.byteLength;
      buffers.push(buf);
      bufferFooters.push(footer[key]);
    }
    //console.log( "extract: key", key, "result", footer[key], (reuseFooter !== null ) ? 'deduped' : '');
  }

  function equalsArrayBuffers(a, b) {
    // a and b must be Uint8Arrays.

    if (a.length !== b.length) {
      return false;
    }

    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false;
      }
    }

    return true;
  }

  function offsetsSum(offsets) {
    const arr = new Uint32Array(offsets.length - 1);
    for (let i = 0; i < offsets.length - 1; i++) {
      arr[i] = offsets[i + 1] - offsets[i];
    }

    return arr;
  }

  extract('faces', polyMesh.positions.faceValueIndices, jsonFooter);
  extract('faceCount', offsetsSum(polyMesh.faceRangeOffsets), jsonFooter);
  extract(
    'positions',
    polyMesh.positions.values.data,
    jsonFooter,
    Float32Array
  );

  if (polyMesh.normalMap) {
    jsonFooter.normalMap = {};
    extract('faces', polyMesh.normalMap.faceValueIndices, jsonFooter.normalMap);
    extract(
      'values',
      polyMesh.normalMap.values.data,
      jsonFooter.normalMap,
      Float32Array
    );
  }

  if (polyMesh.tangentMap && polyMesh.tangentMap.length) {
    jsonFooter.tangentMap = {};
    extract(
      'faces',
      polyMesh.tangentMap.faceValueIndices,
      jsonFooter.tangentMap
    );
    extract(
      'values',
      polyMesh.tangentMap.values.data,
      jsonFooter.tangentMap,
      Float32Array
    );
  }

  if (polyMesh.uvMaps && polyMesh.uvMaps.length) {
    jsonFooter.uvMaps = {};
    polyMesh.uvMaps.namesByIndex.forEach((uvName) => {
      const uvMap = polyMesh.uvMaps.byName[uvName];
      jsonFooter.uvMaps[uvName] = {};
      extract('faces', uvMap.faceValueIndices, jsonFooter.uvMaps[uvName]);
      extract(
        'values',
        uvMap.values.data,
        jsonFooter.uvMaps[uvName],
        Float32Array
      );
    });
  }

  if (polyMesh.edgeCreaseWeights && polyMesh.edgeCreaseWeights.length) {
    extract(
      'edgeCreaseWeights',
      polyMesh.edgeCreaseWeights,
      jsonFooter,
      Float32Array
    ); // don't need to convert, already Float32Array...?
  }

  if (polyMesh.skinning) {
    jsonFooter.skinning = {};
    extract(
      'positionSkinRange',
      polyMesh.skinning.positionSkinRange,
      jsonFooter.skinning
    );
    extract('skinWeights', polyMesh.skinning.skinWeights, jsonFooter.skinning);
    extract(
      'poseSkinToPoseBoneTransform',
      polyMesh.skinning.poseSkinToPoseBoneTransform.data,
      jsonFooter.skinning,
      Float32Array
    );
    extract(
      'skinBoneIndices',
      polyMesh.skinning.skinBoneIndices,
      jsonFooter.skinning
    );
  }

  if (polyMesh.materialIds) {
    extract('materialIds', polyMesh.materialIds, jsonFooter, Uint32Array);
  }

  if (polyMesh.blendShapes) {
    for (let name in polyMesh.blendShapes) {
      jsonFooter.blendShapes[name] = {};
      extract(
        'positions',
        polyMesh.blendShapes[name].positions.data,
        jsonFooter.blendShapes[name],
        Float32Array
      );
      if (polyMesh.blendShapes[name].normals) {
        extract(
          'normals',
          polyMesh.blendShapes[name].normals.data,
          jsonFooter.blendShapes[name],
          Float32Array
        );
      }
      if (polyMesh.blendShapes[name].weights) {
        extract(
          'weights',
          polyMesh.blendShapes[name].weights.data,
          jsonFooter.blendShapes[name],
          Float32Array
        );
      }
    }
  }

  if (polyMesh.smoothGroup) {
    extract('smoothGroup', polyMesh.smoothGroup, jsonFooter, Int32Array);
  }

  let jsonFooterString = JSON.stringify(jsonFooter);
  jsonFooterString += { 0: '', 1: '   ', 2: '  ', 3: ' ' }[
    jsonFooterString.length % 4
  ];

  let currentByte = 0;
  const result = new Uint8Array(byteLength + jsonFooterString.length + 8);
  for (let i = 0; i < buffers.length; i++) {
    result.set(buffers[i], currentByte);
    currentByte += buffers[i].byteLength;
  }

  for (let i = 0; i < jsonFooterString.length; i++) {
    result[currentByte++] = jsonFooterString.charCodeAt(i);
  }

  let currentDWord = currentByte >> 2;
  let newBytes32 = new Uint32Array(result.buffer);
  newBytes32[currentDWord] = jsonFooterString.length;
  newBytes32[currentDWord + 1] = BIN_VERSION;
  if (options.fbx) return new Uint8Array(result.buffer);
  return new Uint32Array(result.buffer);
}

export function convertToCNSPolyMesh(data, refId) {
  if (refId && polyMeshCaches[refId]) return polyMeshCaches[refId];

  const binaryData = validateAndRetype(data);
  const jsonData = extractFooter(binaryData);
  const buffer = binaryData.buffer;

  const has = Object.prototype.hasOwnProperty;

  if (
    jsonData &&
    !jsonData.faceCount &&
    !jsonData.faces &&
    !jsonData.positions
  ) {
    console.warn('Warning: PolyMesh is empty.');
    return new PolyMesh();
  }

  assert(validatePolymeshData(jsonData), 'Invalid Polymesh Data?');

  const fc = readArray(buffer, jsonData.faceCount);
  //console.log( 'CNS', CNS );
  const importer = new Importer();
  //console.log( 'importer', importer );
  if (fc.length === 1) {
    importer.defaultFaceArity = fc[0];
  } else {
    importer.setFaceArities(fc);
  }

  importer.collapsePositions = false;
  importer.collapseMapValues = false;

  const positionFaceIndices = readArray(buffer, jsonData.faces);
  const positionValues = readArray(buffer, jsonData.positions, 'RawFloat32');

  const positionsId = PolyMaps.id(PolyMaps.TypePosition);

  importer.setMapIndices(positionsId, positionFaceIndices);
  importer.setMapValues(positionsId, positionValues);

  // normalMap
  // if normals are not present in final polyMesh (see further down), we default to a flat normal map
  if (jsonData.normalMap) {
    const normalFaceIndices = readArray(buffer, jsonData.normalMap.faces);
    const normalValues = readArray(
      buffer,
      jsonData.normalMap.values,
      'RawFloat32'
    );

    const normalsId = PolyMaps.id(PolyMaps.TypeNormal);

    importer.setMapIndices(normalsId, normalFaceIndices);
    importer.setMapValues(normalsId, normalValues);
  }

  // uvMaps
  if (jsonData.uvMaps && Object.keys(jsonData.uvMaps).length) {
    for (let attr in jsonData.uvMaps) {
      if (has.call(jsonData.uvMaps, attr)) {
        const map = jsonData.uvMaps[attr];

        const uvFaceIndices = readArray(buffer, map.faces);
        const uvValues = readArray(buffer, map.values, 'RawFloat32');

        const uvsId = PolyMaps.id(PolyMaps.TypeUV, attr);

        importer.setMapIndices(uvsId, uvFaceIndices);
        importer.setMapValues(uvsId, uvValues);
      }
    }
  }

  // tangentMap
  if (jsonData.tangentMap) {
    const tangentFaceIndices = readArray(buffer, jsonData.tangentMap.faces);
    const tangentValues = readArray(
      buffer,
      jsonData.tangentMap.values,
      'RawFloat32'
    );

    const tangentsId = PolyMaps.id(PolyMaps.TypeTangent);

    importer.setMapIndices(tangentsId, tangentFaceIndices);
    importer.setMapValues(tangentsId, tangentValues);
  }

  // blendShapes
  if (jsonData.blendShapes) {
    importer.initializeBlendShape();

    for (const name in jsonData.blendShapes) {
      if (has.call(jsonData.blendShapes, name)) {
        const jsonBlendShape = jsonData.blendShapes[name];

        const positions = readArray(
          buffer,
          jsonBlendShape.positions,
          'RawFloat32'
        );
        const normals = !!jsonBlendShape.normals
          ? readArray(buffer, jsonBlendShape.normals, 'RawFloat32')
          : null;
        const weights = !!jsonBlendShape.weights
          ? readArray(buffer, jsonBlendShape.weights, 'RawFloat32')
          : null;

        importer.setBlendShapes(name, positions, normals, weights);
      }
    }
  }

  // skinning
  if (jsonData.skinning) {
    importer.setSkinning(
      readArray(buffer, jsonData.skinning.positionSkinRange, 'RawUint32'),
      readArray(buffer, jsonData.skinning.skinWeights, 'RawFloat32'),
      readArray(buffer, jsonData.skinning.skinBoneIndices, 'RawUint16'),
      readArray(
        buffer,
        jsonData.skinning.poseSkinToPoseBoneTransform,
        'RawFloat32'
      )
    );
  }

  // edge creases
  // Can come in 2 forms:
  //  1) 'creases': Imported from fbx. Contains an edge crease weights array and an array of
  //                vertex index pairs for the corresponding creased edges
  //  2) 'edgeCreaseWeights': A single array of weights, where the edge is implied by the index, which
  //                corresponds to the V2 polyMesh edge index. This form is preferred.
  if (jsonData.edgeCreaseWeights)
    importer.setCreases(
      readArray(buffer, jsonData.edgeCreaseWeights, 'RawFloat32')
    );
  else if (jsonData.creases) {
    const weights = readArray(buffer, jsonData.creases.weights, 'RawFloat32');
    const edges = readArray(
      buffer,
      jsonData.creases.edgeVertexIndices,
      'RawUint32'
    );
    importer.setCreases(weights, edges);
  }

  if (jsonData.materialIds) {
    importer.setMaterialIds(
      readArray(buffer, jsonData.materialIds, 'RawUint32')
    );
  }

  if (jsonData.smoothGroup) {
    importer.setSmoothGroup(
      readArray(buffer, jsonData.smoothGroup, 'RawInt32')
    );
  }

  // TODO: add support for UVs.
  // TODO: add support for Colors.

  let polyMesh = importer.toMeshAndClear();
  //console.log('bingeom jsonDate ', jsonData);
  //console.log('bingeom.js convertToCNSPolyMesh ', polyMesh );

  if (refId) polyMeshCaches[refId] = polyMesh;
  return polyMesh;
}
