import { ConfigurationType, EvaluatedAsset } from '../types';

export default function setConfiguration(
  evalAsset: EvaluatedAsset,
  configuration: ConfigurationType
) {
  const { defaultConfiguration, operon } = evalAsset;
  const conf = { ...defaultConfiguration };
  operon.merge(conf, configuration || {});
  return conf;
}
