import { OperatorSchema } from '../../types';
import { makeMap, MapModifierType } from './matOperatorUtils';
import { RenderCategories } from '../constants';
import { ToOptions } from '../utils';
import { UnlitPhysicalCommon, PhysicalMaterialPlug } from './MaterialPlug';

export const unlitPhysicalCommonSchema: OperatorSchema = {
  ...makeMap('base', MapModifierType.Color, {
    defaultValue: { r: 1, g: 1, b: 1 },
  }),

  baseMapTransparent: {
    label: 'Image Transparent',
    type: 'Boolean',
    defaultValue: false,
  },

  renderCategory: {
    label: 'Opacity Mode',
    type: 'Options',
    options: ToOptions({
      ['Infer From Properties']: RenderCategories.AUTO,
      ['Opaque']: RenderCategories.OPAQUE,
      ['Transparent']: RenderCategories.TRANSPARENT,
      ['Cut-out Transparent']: RenderCategories.CUTOUT,
    }),
    defaultValue: RenderCategories.AUTO,
  },

  alphaTest: { label: 'Alpha Test', type: 'Boolean', defaultValue: false },
  alphaTestMinimum: {
    label: 'Alpha Test Minimum',
    type: 'Number',
    defaultValue: 0.0,
    min: 0.0,
    max: 1.0,
    step: 0.01,
  },

  depthWrite: { label: 'Depth Write', type: 'Boolean', defaultValue: true },
  depthTest: { label: 'Depth Test', type: 'Boolean', defaultValue: true },

  ...makeMap('opacity', MapModifierType.Factor, {
    defaultValue: 1,
    factorName: 'Opacity',
  }),

  invertOpacity: {
    label: 'Invert Opacity',
    type: 'Boolean',
    defaultValue: false,
  },
};

export function inferRenderCategory(plug: PhysicalMaterialPlug) {
  const {
    baseMap,
    baseMapTransparent,
    invertOpacity,
    opacityMap,
    opacityFactor,
  } = plug;

  const transparencyFactor = plug.transparencyFactor;
  const enableTransmission = plug.enableTransmission;
  //   plug.type === 'Physical' ? plug.transparencyFactor : undefined;
  // const enableTransmission =
  //   plug.type === 'Physical' ? plug.enableTransmission : undefined;

  let { renderCategory } = plug;

  if (renderCategory !== RenderCategories.AUTO) return renderCategory;

  const isTransparent =
    opacityMap ||
    (baseMap && baseMapTransparent) ||
    (invertOpacity ? opacityFactor > 0.0 : opacityFactor < 1.0) ||
    (transparencyFactor != undefined &&
      transparencyFactor > 0.0 &&
      !enableTransmission); // enableTransmission transforms this property from alpha blending to transmission

  return isTransparent ? RenderCategories.TRANSPARENT : RenderCategories.OPAQUE;
}
