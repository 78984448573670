export default function ValueAdjacency(polyMap) {
  this.polyMap = polyMap;

  this._compute();
}

ValueAdjacency.prototype = {
  constructor: ValueAdjacency,

  // V -> E, part 1
  getNumEdgesForVertex: function(valueIndex) {
    const valueBegin = this.valueEdgeOffsets[valueIndex];
    const valueUntil = this.valueEdgeOffsets[valueIndex + 1];
    const numValueEdges = valueUntil - valueBegin;

    return numValueEdges;
  },

  // V -> E, part 2
  getEdgeForVertexId: function(valueIndex, edgeOffset) {
    const valueBegin = this.valueEdgeOffsets[valueIndex];
    return this.valueEdgeIndices[valueBegin + edgeOffset];
  },

  //returns an array of edgeId's in CC order for a given Vertex
  getEdgeOrderForVertexId: function(valueIndex) {
    const vertexValence = this.getNumEdgesForVertex(valueIndex);
    const edgeVertexAdjacency = this.polyMap.edgeVertexAdjacency;
    const faceEdgeAdjacency = this.polyMap.faceEdgeAdjacency;
    const faceRangeOffsets = this.polyMap.faceRangeOffsets;
    const faceValueIndices = this.polyMap.faceValueIndices;

    let startEdgeId = this.getEdgeForVertexId(valueIndex, 0);
    let startFaceId = faceEdgeAdjacency.getNumFacesForEdgeId(startEdgeId, 0);
    let complete = false;
    let i = 0;

    //Checks if the vertex has a gap in adjacent faces
    //if gap present, it starts at an edge there to find the ordering of edges
    while (!complete && i < vertexValence) {
      const edgeId = this.getEdgeForVertexId(valueIndex, i);
      const edgeFaceCount = faceEdgeAdjacency.getNumFacesForEdgeId(edgeId);

      if (edgeFaceCount === 1) {
        const tempFaceId = faceEdgeAdjacency.getFaceForEdgeId(edgeId, 0);
        const faceBegin = faceRangeOffsets[tempFaceId];
        const faceUntil = faceRangeOffsets[tempFaceId + 1];
        const faceValuesCount = faceUntil - faceBegin;

        let nextValue = faceValueIndices[faceBegin];
        let nextNextValue = faceValueIndices[faceBegin + 1];

        for (let fv = 0; fv < faceValuesCount; ++fv) {
          const value = nextValue;
          nextValue = nextNextValue;
          nextNextValue =
            faceValueIndices[faceBegin + ((fv + 2) % faceValuesCount)];

          if (value === valueIndex) {
            const tempEdgeId = edgeVertexAdjacency.findEdgeId(value, nextValue);

            if (tempEdgeId === edgeId) {
              startEdgeId = edgeId;
              startFaceId = tempFaceId;
              complete = true;
            }
          }
        }
      }

      ++i;
    }

    //If no gap present, find the starting face Id
    if (!complete) {
      const faceBegin = faceRangeOffsets[startFaceId];
      const faceUntil = faceRangeOffsets[startFaceId + 1];
      const faceValuesCount = faceUntil - faceBegin;

      let nextValue = faceValueIndices[faceBegin];
      let nextNextValue = faceValueIndices[faceBegin + 1];

      for (let fv = 0; fv < faceValuesCount; ++fv) {
        const value = nextValue;
        nextValue = nextNextValue;
        nextNextValue =
          faceValueIndices[faceBegin + ((fv + 2) % faceValuesCount)];

        if (value === valueIndex) {
          const tempEdgeId = edgeVertexAdjacency.findEdgeId(value, nextValue);

          if (tempEdgeId !== startEdgeId) {
            startFaceId = faceEdgeAdjacency.getFaceForEdgeId(startEdgeId, 1);
          }
        }
      }
    }

    //start here to go around the vertex in CC order at startEdgeId
    const orderedEdges = new Uint32Array(vertexValence);
    let edgeId = startEdgeId;
    let faceId = startFaceId;

    for (let e = 0; e < vertexValence; ++e) {
      orderedEdges[e] = edgeId;
      const faceBegin = faceRangeOffsets[faceId];
      const faceUntil = faceRangeOffsets[faceId + 1];
      const faceValuesCount = faceUntil - faceBegin;

      let nextValue = faceValueIndices[faceBegin];
      let nextNextValue = faceValueIndices[faceBegin + 1];

      for (let fv = 0; fv < faceValuesCount; ++fv) {
        const value = nextValue;
        nextValue = nextNextValue;
        nextNextValue =
          faceValueIndices[faceBegin + ((fv + 2) % faceValuesCount)];

        if (value === valueIndex) {
          const previousValue =
            faceValueIndices[
              faceBegin + ((fv + faceValuesCount - 1) % faceValuesCount)
            ];
          edgeId = edgeVertexAdjacency.findEdgeId(previousValue, value);
          const tempFaceId = faceEdgeAdjacency.getFaceForEdgeId(edgeId, 0);

          if (
            faceId === tempFaceId &&
            faceEdgeAdjacency.getNumFacesForEdgeId(edgeId) === 2
          ) {
            faceId = faceEdgeAdjacency.getFaceForEdgeId(edgeId, 1);
          } else {
            faceId = tempFaceId;
          }
        }
      }
    }

    return orderedEdges;
  },

  //V->VV returns the adjacent values to a given vertex
  getAdjacentValues: function(valueIndex) {
    const edgeVertexAdjacency = this.polyMap.edgeVertexAdjacency;
    const adjacentValues = [];
    const numEdges = this.getNumEdgesForVertex(valueIndex);

    for (let i = 0; i < numEdges; i++) {
      const edge = this.getEdgeForVertexId(valueIndex, i);
      const values = edgeVertexAdjacency.getVerticesForEdge(edge);
      const adjacentValue = values.v === valueIndex ? values.vNext : values.v;
      adjacentValues.push(adjacentValue);
    }

    return adjacentValues;
  },

  //V->VV for a given faceValueIndex and face
  //returns the (0-2) adjacent faceValueIndices on that face
  getAdjacentFaceValuesIndicesOnFace: function(faceValueIndex, face) {
    const adjacentValuesOnFace = [];
    const faceBegin = this.polyMap.faceRangeOffsets[face];
    const faceEnd = this.polyMap.faceRangeOffsets[face + 1];
    const faceSize = faceEnd - faceBegin;
    if (faceSize < 3) return adjacentValuesOnFace;

    const next =
      faceValueIndex === faceEnd - 1 ? faceBegin : faceValueIndex + 1;
    const prev =
      faceValueIndex === faceBegin ? faceEnd - 1 : faceValueIndex - 1;

    adjacentValuesOnFace.push(prev);
    adjacentValuesOnFace.push(next);

    return adjacentValuesOnFace;
  },

  // V -> F, part 1
  getNumFacesForVertex: function(faceValueIndex) {
    const valueBegin = this.valueFaceOffsets[faceValueIndex];
    const valueUntil = this.valueFaceOffsets[faceValueIndex + 1];
    const numValueFace = valueUntil - valueBegin;

    return numValueFace;
  },

  // V -> F, part 2
  getFaceForVertexId: function(faceValueIndex, faceOffset) {
    const valueBegin = this.valueFaceOffsets[faceValueIndex];
    return this.valueFaceIndices[valueBegin + faceOffset];
  },

  _compute: function() {
    const polyMap = this.polyMap;

    const faceRangeOffsets = polyMap.faceRangeOffsets;
    const faceValueIndices = polyMap.faceValueIndices;

    const edgeVertexAdjacency = polyMap.edgeVertexAdjacency;

    const valueFaceCount = new Uint32Array(polyMap.values.length);
    const valueEdgeCount = new Uint32Array(polyMap.values.length);
    //const numFaces = faceRangeOffsets.length - 1;

    const numEdges = edgeVertexAdjacency.getNumEdges();
    const edgeUnique = new Uint8Array(numEdges);

    // histogram.
    for (let f = 0; f < polyMap.faceRangeOffsets.length - 1; f++) {
      const faceBegin = polyMap.faceRangeOffsets[f];
      const faceUntil = polyMap.faceRangeOffsets[f + 1];
      const numFaceValues = faceUntil - faceBegin;

      let vNext = polyMap.faceValueIndices[faceBegin];

      for (let fv = 0; fv < numFaceValues; fv++) {
        const v = vNext;
        vNext =
          polyMap.faceValueIndices[faceBegin + ((fv + 1) % numFaceValues)];

        const e = edgeVertexAdjacency.findEdgeId(v, vNext);

        valueFaceCount[v]++;

        // is this the first time processing this edge
        if (edgeUnique[e] === 0) {
          edgeUnique[e] = 1;

          valueEdgeCount[v]++;
          valueEdgeCount[vNext]++;
        }
      }
    }

    // create offset arrays.
    let faceSum = 0,
      edgeSum = 0;
    const valueFaceOffsets = new Uint32Array(valueFaceCount.length + 1);
    const valueEdgeOffsets = new Uint32Array(valueEdgeCount.length + 1);
    for (let i = 0; i < valueFaceCount.length; i++) {
      valueFaceOffsets[i] = faceSum;
      faceSum += valueFaceCount[i];
      valueFaceCount[i] = 0;

      valueEdgeOffsets[i] = edgeSum;
      edgeSum += valueEdgeCount[i];
      valueEdgeCount[i] = 0;
    }

    valueFaceOffsets[valueFaceCount.length] = faceSum;
    valueEdgeOffsets[valueEdgeCount.length] = edgeSum;

    // fill in the values
    const valueFaceIndices = new Uint32Array(faceSum);
    const valueEdgeIndices = new Uint32Array(edgeSum);

    for (let f = 0; f < polyMap.faceRangeOffsets.length - 1; f++) {
      const faceBegin = polyMap.faceRangeOffsets[f];
      const faceUntil = polyMap.faceRangeOffsets[f + 1];
      const numFaceValues = faceUntil - faceBegin;

      let vNext = polyMap.faceValueIndices[faceBegin];

      for (let fv = 0; fv < numFaceValues; fv++) {
        const v = vNext;
        vNext =
          polyMap.faceValueIndices[faceBegin + ((fv + 1) % numFaceValues)];

        const e = edgeVertexAdjacency.findEdgeId(v, vNext);

        valueFaceIndices[valueFaceOffsets[v] + valueFaceCount[v]++] = f;

        // is this the first time processing this edge
        if (edgeUnique[e] === 1) {
          edgeUnique[e] = 2;

          valueEdgeIndices[valueEdgeOffsets[v] + valueEdgeCount[v]++] = e;
          valueEdgeIndices[
            valueEdgeOffsets[vNext] + valueEdgeCount[vNext]++
          ] = e;
        }
      }
    }

    this.valueFaceOffsets = valueFaceOffsets;
    this.valueFaceIndices = valueFaceIndices;

    this.valueEdgeOffsets = valueEdgeOffsets;
    this.valueEdgeIndices = valueEdgeIndices;
  },
};
