import { OperatorData, OperatorDefinition, ProxyPrimitive } from '../../types';

const Proxy: OperatorDefinition = {
  schema: {
    asset: { type: 'Asset' },
  },
  update: (operator: OperatorData, primitive: ProxyPrimitive) => {
    primitive.link = operator.asset;
    return primitive;
  },
};
export default Proxy;
