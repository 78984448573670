// Adapted from:
// http://stackoverflow.com/questions/1406029/how-to-calculate-the-volume-of-a-3d-mesh-object-the-surface-of-which-is-made-up/1568551#1568551
// See also referenced paper at: http://chenlab.ece.cornell.edu/Publication/Cha/icip01_Cha.pdf

import { Vector3 } from 'three';
import triangulateMesh from './triangulateMesh';

/**
 * The volume calculated will generally be correct for *closed* meshes. The volume calculated for some open
 * meshes can be correct under certain situations (see refPt below), but generally will exhibit a range
 * of error depending on the nature of the mesh's holes and their orientation with respect to the refPt.
 * @param {*} polyMesh
 */
export default function calculateVolume(polyMesh) {
  const triMesh = triangulateMesh(polyMesh); // easy way to check if mesh already tri's?
  const positions = triMesh.positions.values;
  const indices = triMesh.positions.faceValueIndices;

  const numFaces = triMesh.getNumFaces();

  let volume = 0;

  // const refPt = new Vector3(0, 0, 0); // TODO: allow argument for non-origin ref point, which
  // can improve volume estimate for open meshes (make pt planar with mesh opening) or meshes extremely
  // far from the origin

  const p1 = new Vector3();
  const p2 = new Vector3();
  const p3 = new Vector3();
  const tmp = new Vector3();
  for (let i = 0; i < numFaces * 3; i += 3) {
    positions.getAt(indices[i], p1);
    positions.getAt(indices[i + 1], p2);
    positions.getAt(indices[i + 2], p3);

    // calculate volume of tetrahedron defined by face triangle and point at origin
    volume += p1.dot(tmp.crossVectors(p2, p3)) / 6;
  }

  return volume;
}
