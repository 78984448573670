import { AssetState, SceneGraphNode } from '../types';

// TODO: This stuff belongs elsewhere
function pathToRegExp(query: RegExp | string): RegExp {
  if (query instanceof RegExp) return query;
  query = query
    .replace(/\(/, '\\(')
    .replace(/\)/, '\\)')
    .replace(/\*/g, '(.*)')
    .replace(/^\"|\"$/g, '');
  return new RegExp('^' + query + '$', 'i');
}
function filterNodesWithTag(state: AssetState, tag: string) {
  const regExp = pathToRegExp(tag);
  const nodes = Object.values(state.nodes).filter((node) => {
    const propPlug = node.plugs.Properties;
    const tagOp = propPlug && propPlug.find((op) => op.type === 'Tags');
    const tags = tagOp && tagOp.tags;
    return tags ? tags.find((t: string) => regExp.test(t)) : null;
  });
  return nodes;
}

export default function resolveNodeTags(
  state: AssetState,
  nodes: string[] // node references, tag or id
) {
  return nodes.reduce((acc: Array<SceneGraphNode>, id: string) => {
    if (id?.length && id[0] === '#') {
      const tag = id.slice(1);
      const taggedNodes = filterNodesWithTag(state, tag);
      return acc.concat(taggedNodes);
    } else {
      if (state.nodes[id]) return acc.concat(state.nodes[id]);
    }
    return acc;
  }, []);
}
