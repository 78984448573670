import * as assets from './assets';
import * as cas from './cas';
import * as files from './files';

const queries = {
  assets,
  cas,
  files,
};
export default queries;
