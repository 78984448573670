import { FileAttrs, ImageUrlOptions, ThreekitSource } from '../types';
import extname from './extname';

function urlFor(source: ThreekitSource, file: FileAttrs) {
  //, fallback?: AssetFileType | false) {
  const filesApiRoot = source.apiRoot('files');
  // const fallbackHash = fallback && fallbacks[fallback];
  const ext = extname(file.filename);
  const useGz = compressedFormats.includes(ext);
  return `${filesApiRoot}/files/hash/${file.hash}${useGz ? '-gz' : ''}`;
}

const compressedFormats = [
  '.bingeom',
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.exr',
  '.zip',
  '.7z',
  '.rar',
  '.gzip',
  '.xr',
  '.exr',
  '.tiff',
  '.tif',
  '.tga',
  '.hdr',
  '.gltfzip',
  '.vrscenezip',
  '.objzip',
  '.pbrzip',
  '.mp4',
];

export async function getBingeom(source: ThreekitSource, file: FileAttrs) {
  let url = urlFor(source, file); //, useFallbacks && type);
  const res = await source.httpClient.get(url, { responseType: 'arraybuffer' });
  return res.data;
}

export function imageUrl(
  source: ThreekitSource,
  file: FileAttrs,
  opts?: ImageUrlOptions
) {
  //, opts?: ImageUrlOptions) {
  const filesApiRoot = source.apiRoot('files');
  const fileUrl = `${filesApiRoot}/files/hash/${file.hash}`;
  if (!opts) return fileUrl;

  const imageApiRoot = source.apiRoot('images');
  let prefix = `${imageApiRoot}/images/webp`;
  const { width, height, quality, format, power2 } = opts;
  if (power2) {
    prefix += `/power2-${power2}`;
  } else if (width || height) {
    prefix += `/${width || 0}x${height || 0}`;
  }
  if (format) {
    prefix += `/filters:format(${format})`;
  }
  if (quality != null && quality < 100) {
    prefix += format ? `:quality(${quality})` : `/filters:quality(${quality})`;
  }
  return `${prefix}/${encodeURI(fileUrl)}`;
}
