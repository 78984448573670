import { Attribute } from '../types';

function readAttributeId(attributeId?: string | string[]) {
  const [attrId, attrIndexString] = Array.isArray(attributeId)
    ? attributeId
    : [attributeId, undefined];

  let attrIndex: 'any' | 'all' | string | undefined = undefined;
  if (attrIndexString != null) {
    if (attrIndexString === 'any' || attrIndexString === 'all') {
      attrIndex = attrIndexString;
    } else if (!Number.isNaN(Number(attrIndexString))) {
      attrIndex = attrIndexString;
    }
  }

  return { attrId, attrIndex };
}

export default function findAttribute(
  attributes: Attribute[],
  id?: string | string[]
): { attr?: Attribute; attrIndex?: string | number } {
  if (!id) return {};
  const { attrId, attrIndex } = readAttributeId(id);
  const attr = attributes.find((attr: Attribute) => attr.id === id);
  return { attr, attrIndex };
}
