import PolyMesh from '../model/PolyMesh';

// Calculate maximum number of triangles and the maximum number of triangles per face.
// Scenes with bad geometry (i.e., with self-intersecting polygons) will have fewer
// triangles than this because earCut will throw some out.
export function maxMeshTriangles(mesh: PolyMesh) {
  const faceOffsets = mesh.faceRangeOffsets;

  let maxNumTriangles = 0;
  let maxNumVerticesPerFace = 0;

  let faceBegin = faceOffsets[0];
  for (let i = 1, n = faceOffsets.length; i < n; ++i) {
    const faceUntil = faceOffsets[i];
    const nVertices = faceUntil - faceBegin;

    // In case of degenerate faces with 1 or 2 vertices
    if (nVertices >= 3) maxNumTriangles += nVertices - 2;

    maxNumVerticesPerFace = Math.max(maxNumVerticesPerFace, nVertices);

    faceBegin = faceUntil;
  }

  return {
    maxNumTriangles,
    maxNumVerticesPerFace,
  };
}
