import { Vector3 } from 'three';
import IndexMappings from '../../generic/algorithm/IndexMappings';
import ObjectArrayView from '../../generic/container/ObjectArrayView';
import Arrays from '../../generic/utility/Arrays';
import EdgeVertexAdjacency from './adjacency/EdgeVertexAdjacency';
import FaceEdgeAdjacency from './adjacency/FaceEdgeAdjacency';
import ValueAdjacency from './adjacency/ValueAdjacency';
import FaceFaceAdjacency from './adjacency/FaceFaceAdjacency';

/**
 * @template T
 * @constructor
 *
 * @param {!PolyMap|!Object} from object to copy the properties from
 */
export default function PolyMap(from, replacements) {
  if (!from) {
    this.faceRangeOffsets = new Uint32Array(1);
    this.faceValueIndices = new Uint32Array(0);
    this.values = new ObjectArrayView(Vector3, 0);
  } else {
    this.faceRangeOffsets = from.faceRangeOffsets; //Uint32Array
    this.faceValueIndices = from.faceValueIndices; //Uint32Array
    this.values = from.values; //ObjectArrayView contains Three.Vector3 or Three.Vector2 usually

    // Cached transient information (can be rebuilt from the above):

    // Inverse index (value indices -> faces):
    const valueFaceIndices = from.valueFaceIndices || null;
    this.valueFaceIndices = valueFaceIndices;
    this.valueFaceIndexOffsets = // only reuse when complete
      (valueFaceIndices && from.valueFaceIndexOffsets) || null;

    // The low value index acts as the pivot for edge enumeration, so
    // the following array is indexed by edge UIDs:
    this._edgeVertexAdjacency = null;
    this._faceEdgeAdjacency = null;
    this._valueAdjacency = null;
    this._valueValueAdjacency = null;
    this._faceFaceAdjacency = null;

    if (replacements) {
      Object.assign(this, replacements);
    }
  }
}

PolyMap.fromData = function (faceOffsets, indices, values) {
  const result = new PolyMap({
    faceRangeOffsets: faceOffsets,

    faceValueIndices:
      indices || IndexMappings.identity(values.length, Uint32Array),

    values: values.repack(Float32Array),
  });

  const nFaceVertices = faceOffsets[faceOffsets.length - 1];

  if (result.faceValueIndices.length < nFaceVertices)
    throw Error(
      'Number of position indices or unindexed ' +
        'data values does not match the number of face vertices!'
    );
  else if (result.faceValueIndices.length > nFaceVertices) {
    console.warn('PolyMap has more position indices than face vertices');
  }

  return result;
};

PolyMap.prototype = {
  constructor: PolyMap,
  isPolyMap: true,

  findValueIndexOffset: function (faceIndex, valueIndex) {
    const offsets = this.faceRangeOffsets;
    const indices = this.faceValueIndices;

    const begin = offsets[faceIndex];
    const until = offsets[faceIndex + 1];

    for (let i = begin; i !== until; ++i)
      if (indices[i] === valueIndex) return i;

    return -1;
  },

  // adjacency structures, computed on-demand adjacency structures.

  get edgeVertexAdjacency() {
    if (!this._edgeVertexAdjacency)
      this._edgeVertexAdjacency = new EdgeVertexAdjacency(this);

    return this._edgeVertexAdjacency;
  },

  get faceEdgeAdjacency() {
    if (!this._faceEdgeAdjacency)
      this._faceEdgeAdjacency = new FaceEdgeAdjacency(this);

    return this._faceEdgeAdjacency;
  },

  get valueAdjacency() {
    if (!this._valueAdjacency) this._valueAdjacency = new ValueAdjacency(this);

    return this._valueAdjacency;
  },

  get valueValueAdjacency() {
    if (!this._valueValueAdjacency)
      this._valueValueAdjacency = new ValueValueAdjacency(this);

    return this._valueValueAdjacency;
  },

  get faceFaceAdjacency() {
    if (!this._faceFaceAdjacency)
      this._faceFaceAdjacency = new FaceFaceAdjacency(this);

    return this._faceFaceAdjacency;
  },

  // Inverse index (value index -> faces) build

  updateInverseIndex: function () {
    if (this.valueFaceIndices === null) {
      const faceOffsets = this.faceRangeOffsets;

      let faceIndex = -1;
      let nextFaceStart = faceOffsets[0];

      const indices = this.faceValueIndices;

      const writeOffsets = this._updateValueFaceIndexOffsets();
      const nDestOffsets = writeOffsets.length;

      const nFaceIndices = indices.length;
      const faceIndices = new Uint32Array(nFaceIndices);

      for (let i = 0; i !== nFaceIndices; ++i) {
        if (i === nextFaceStart) nextFaceStart = faceOffsets[++faceIndex + 1];

        faceIndices[writeOffsets[indices[i]]++] = faceIndex;
      }

      this.valueFaceIndices = faceIndices;

      // Shift the write offsets back to their initial values:

      if (writeOffsets.byteOffset === 0)
        throw Error('Invalid .valueFaceIndexOffsets != null!');

      this.valueFaceIndexOffsets = new Uint32Array(
        writeOffsets.buffer,
        0,
        nDestOffsets
      );
    }

    return this;
  },

  _updateValueFaceIndexOffsets: function () {
    let result = this.valueFaceIndexOffsets;

    if (result === null) {
      const nValues = this.values.length;
      const requiredLength = nValues + 2;

      // Two extra values... Why?
      //
      // 1. For uniform access we keep n+1 offsets, that is
      //    0, o_0, o_1, o_2 ..., <length>
      //
      // 2. The build of the inverse indices is destructive
      //    and shifts the offsets by one index. The result
      //    is something like:
      //
      //    o_0, o_1, o_2, ..., <length>, <length>
      //
      //    Now we will want our leading zero back.

      const buffer = new Uint32Array(requiredLength);
      const histogramArea = buffer.subarray(2);

      result = buffer.subarray(1);

      IndexMappings.histogram(this.faceValueIndices, nValues, histogramArea);

      Arrays.accumulate(histogramArea);

      this.valueFaceIndices = null;
      this.valueFaceIndexOffsets = result;
    }

    return result;
  },

  // Compaction

  compactValues: function () {
    const values = this.values;
    const nValues = values.length;

    if (nValues !== 0) {
      const sourceToTargetMap = new Uint32Array(nValues);
      const nCompactValues = this._compactFaceIndexOffsets(sourceToTargetMap);

      if (nCompactValues !== nValues) {
        this._compactData(sourceToTargetMap, nCompactValues);
        this._compactIndices(sourceToTargetMap);
      }
    }

    return this;
  },

  _compactFaceIndexOffsets: function (outSourceToTargetMap) {
    let faceIndexOffsets = this._updateValueFaceIndexOffsets();
    const nValues = outSourceToTargetMap.length;

    let nCompactValues = 0;
    let offset = 0; // == faceIndexOffsets[ 0 ]

    for (let i = 0; i !== nValues; ++i) {
      let nextOffset = faceIndexOffsets[i + 1];

      outSourceToTargetMap[i] = nCompactValues;
      // Note: Filling in incorrect values at indices that are no longer
      // used, actually. But those allow to replay the compaction of the
      // offsets on the values once the size is known.
      //
      // The value that corresponds to the last index in an equal range
      // is the one to keep.

      if (offset !== nextOffset) {
        if (nCompactValues !== i) faceIndexOffsets[nCompactValues] = offset;

        offset = nextOffset;
        ++nCompactValues;
      }
    }

    faceIndexOffsets[nCompactValues] = offset;

    if (nCompactValues !== nValues) {
      // Note: Since only empty ranges were removed, neither need to null
      // nor rebuild .valueFaceIndices.

      if (faceIndexOffsets.byteOffset !== 0) {
        // this will be our leading zero and we want to keep it, so have
        // a little dance with the typed arrays API...

        const bufferView = new Uint32Array(faceIndexOffsets.buffer),
          slice = Arrays.slice(bufferView, 0, nCompactValues + 2);

        faceIndexOffsets = slice.subarray(1);

        // ... phew!
      } else {
        faceIndexOffsets = Arrays.slice(
          faceIndexOffsets,
          0,
          nCompactValues + 1
        );
      }
    }

    this.valueFaceIndexOffsets = faceIndexOffsets;
    return nCompactValues;
  },

  _compactData: function (sourceToTargetMap, nCompactValues) {
    // Rewrite the values to a new buffer, avoiding to build another
    // temporary map, instead exploit the bogus padding of the STTM:

    const values = this.values;
    const nValues = values.length;
    const newValues = new ObjectArrayView(values.type, nCompactValues);
    let writeIndex = sourceToTargetMap[0];
    const element = newValues.newRangeArray();

    for (let i = 1; i !== nValues; ++i) {
      const nextWriteIndex = sourceToTargetMap[i];

      if (writeIndex !== nextWriteIndex) {
        // now i - 1 is the source position we want

        values.rangeToArray(i - 1, 1, element);
        newValues.arrayToRange(element, writeIndex, 1);

        writeIndex = nextWriteIndex;
      }
    }

    values.rangeToArray(nValues - 1, 1, element);
    newValues.arrayToRange(element, writeIndex, 1);

    this.values = newValues;
  },

  _compactIndices: function (sourceToTargetMap) {
    // The size of the index array does not change - it has already (else
    // there'd be no unused indices), but we don't know when (the typical
    // case would be in a previous transaction), so the same array can be
    // in use elsewhere...

    const indices = this.faceValueIndices;
    const nIndices = indices.length;

    const newIndices = new Uint32Array(nIndices);

    IndexMappings.apply(indices, sourceToTargetMap, newIndices);
    this.faceValueIndices = newIndices;
  },
};
