import PolyMaps from '../model/PolyMaps';

let FOUND_CORRUPTION = false;

export default function RenderIndexing(polyMesh, optionalMapIdWhitelist) {
  var mapIds = [],
    mapStates = [];

  for (var iter = new PolyMaps.Iterator(polyMesh); iter.next(); ) {
    var map = iter.element,
      mapId = iter.getId();

    if (optionalMapIdWhitelist && optionalMapIdWhitelist.indexOf(mapId) === -1)
      continue;

    mapIds.push(mapId);
    mapStates.push({
      map: map,
      faceIndices: map.updateInverseIndex().valueFaceIndices,
      facesOffset: 0,
    });
  }

  // Processing face vertices, an n-way MERGE-like step is performed to
  // identify a previously processed face that uses the same combination
  // of value indices. Forward processing and the sorted nature of the
  // inverse PolyMap indices allow this algorithm to be very efficient.

  var nFaceVertices = polyMesh.getNumFaceVertices(),
    nMaps = mapStates.length,
    renderIndices = new Uint32Array(nFaceVertices),
    valueIndices = new Uint32Array(nFaceVertices * nMaps),
    writeOffset = 0,
    nVertices = 0,
    faceRangeOffsets = polyMesh.faceRangeOffsets,
    faceIndex = -1,
    nextFaceStart = 0,
    pivotState = mapStates[nMaps - 1],
    pivotFaces = pivotState.faceIndices,
    mapScanStart = nMaps - 2;

  for (var i = 0; i !== nFaceVertices; ++i) {
    if (i === nextFaceStart) nextFaceStart = faceRangeOffsets[++faceIndex + 1];

    // Try to find a face before this one that already uses all the
    // values for this vertex:

    var reusePossible = true,
      reuseFromFace = 0;

    for (var j = 0; j !== nMaps; ++j) {
      var mapState = mapStates[j],
        map = mapState.map,
        valueIndex = map.faceValueIndices[i];
      // Speculatively write data:
      valueIndices[writeOffset + j] = valueIndex;

      if (reusePossible) {
        let facesBegin = map.valueFaceIndexOffsets[valueIndex];
        if (facesBegin === undefined) {
          if (!FOUND_CORRUPTION) {
            console.warn(
              'GOT INVALID INDICES, using default index of zero to avoid errors, but this may lead to bad looking meshes'
            );
            FOUND_CORRUPTION = true;
          }
          facesBegin = 0;
        }
        const firstFaceIndex = map.valueFaceIndices[facesBegin];

        // Speculatively prepare the scanning state:
        mapState.facesOffset = facesBegin;

        // Early-out when a value index is used for the first time:
        reusePossible = firstFaceIndex !== faceIndex;
      }
    }
    if (reusePossible) {
      var pivotFaceIdxPos = pivotState.facesOffset,
        pivotFaceIndex = 0,
        pivotFaceGood = true;

      do {
        pivotFaceIndex = pivotFaces[pivotFaceIdxPos++];
        pivotFaceGood = pivotFaceIndex !== faceIndex;
        reusePossible = pivotFaceGood;

        for (var j = mapScanStart; pivotFaceGood && j !== -1; --j) {
          var mapState = mapStates[j],
            faceIdxPos = mapState.facesOffset,
            faceIndices = mapState.faceIndices,
            candidate = faceIndices[faceIdxPos];

          while (candidate < pivotFaceIndex)
            candidate = faceIndices[++faceIdxPos];

          if (candidate !== pivotFaceIndex) pivotFaceGood = false;
          if (candidate === faceIndex) reusePossible = false;
          else mapState.facesOffset = faceIdxPos;

          // Note that the next pivot face will have a higher index
          // and that we'll see the current face when out of input.
          // Also note that the pivot face has a lower index than
          // the current face.
        }
      } while (
        pivotFaceIdxPos < pivotFaces.length &&
        reusePossible &&
        !pivotFaceGood
      );

      reusePossible = pivotFaceGood;
      reuseFromFace = pivotFaceIndex;
    }

    if (reusePossible) {
      var positionMap = mapStates[0].map,
        faceVertexIndex = positionMap.findValueIndexOffset(
          reuseFromFace,
          positionMap.faceValueIndices[i]
        ),
        reuseIndex = renderIndices[faceVertexIndex];

      renderIndices[i] = reuseIndex;
    } else {
      renderIndices[i] = nVertices++;
      writeOffset += nMaps;
    }
  }

  this.mesh = polyMesh;
  this.mapIds = mapIds;
  this.renderIndices = renderIndices;
  this.valueIndices = valueIndices.slice(0, writeOffset);
  this.numVertices = nVertices;
}

RenderIndexing.prototype = {
  constructor: RenderIndexing,

  getMapValues: function(mapId, targetView) {
    var mapIds = this.mapIds,
      mapIndex = mapIds.indexOf(mapId),
      nVertices = this.numVertices,
      valueIndices = this.valueIndices;

    if (mapIndex === -1) throw Error("Map with id '" + mapId + "' not found!");

    var map = PolyMaps.resolveMap(this.mesh, mapId),
      mapValues = map.values;

    if (targetView.length < nVertices) throw Error("Data won't fit!");

    var viOffset = mapIndex,
      viStride = mapIds.length,
      tmp = new targetView.type();

    for (var i = 0; i !== nVertices; ++i) {
      var valueIndex = valueIndices[viOffset + i * viStride];
      targetView.setAt(i, mapValues.getAt(valueIndex, tmp));
    }
  },
};
