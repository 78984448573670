interface PrimitiveSchema {
  type: 'Primitive';
  hasId?: boolean;
  lookup?: boolean;
}

interface ArraySchema {
  type: 'Array';
  of: string;
}

export interface ObjectSchema {
  type: 'Object';
  hasId?: boolean;
  keys?: { [key: string]: string };
  optionalKeys?: { [key: string]: boolean };
  as?: string;
}

// The name primitive was already taken
interface RawSchema {
  type: 'String' | 'Boolean';
}

export type Schema = PrimitiveSchema | ArraySchema | ObjectSchema | RawSchema;

export interface Schemas {
  [key: string]: Schema;
}

export function isPrimitiveSchema(schema: Schema): schema is PrimitiveSchema {
  return schema.type === 'Primitive';
}
export function isArraySchema(schema: Schema): schema is ArraySchema {
  return schema.type === 'Array';
}
export function isObjectSchema(schema: Schema): schema is ObjectSchema {
  return schema.type === 'Object';
}

export function isRawType(type: string): type is RawSchema['type'] {
  const rawTypes: { [key in RawSchema['type']]: true } = {
    Boolean: true,
    String: true,
  };
  return !!rawTypes[type as RawSchema['type']];
}
export function isRawSchema(schema: Schema): schema is RawSchema {
  return isRawType(schema.type);
}

export function hasId(schema: Schema): boolean {
  return (
    (isPrimitiveSchema(schema) || isObjectSchema(schema)) && !!schema.hasId
  );
}

export type Path = Array<string | number>;

export const nodeSchema: ObjectSchema = {
  type: 'Object',
  hasId: true,
  keys: {
    name: 'String',
    type: 'String',
    plugs: 'Plugs',
    children: 'Nodes',
    configurator: 'Configurator',
  },
  optionalKeys: {
    configurator: true,
  },
};

export const schemas: Schemas = {
  Strings: {
    type: 'Array',
    of: 'String',
  },
  String: {
    type: 'String',
  },
  Primitive: {
    type: 'Primitive',
  },
  Node: nodeSchema,
  Nodes: {
    type: 'Array',
    of: 'Node',
  },
  NodeMap: {
    type: 'Object',
    as: 'Node',
  },
  Plugs: {
    type: 'Object',
    as: 'Plug',
  },
  Plug: {
    type: 'Array',
    of: 'Operator',
  },
  Operator: {
    type: 'Primitive',
    hasId: true,
    lookup: true,
  },
  Configurator: {
    type: 'Object',
    keys: {
      rules: 'Rules',
      attributes: 'Attributes',
      metadata: 'Attributes',
      forms: 'Forms',
      script: 'String',
    },
  },
  Attributes: {
    type: 'Array',
    of: 'Attribute',
  },
  Attribute: {
    type: 'Primitive',
    lookup: true,
  },
  AttributeMap: {
    type: 'Object',
    as: 'Attribute',
  },
  Actions: {
    type: 'Array',
    of: 'Action',
  },
  Action: {
    type: 'Primitive',
    lookup: true,
  },
  Forms: {
    type: 'Array',
    of: 'Form',
  },
  Form: {
    type: 'Primitive',
  },
  Rules: {
    type: 'Array',
    of: 'Rule',
  },
  Rule: {
    type: 'Object',
    keys: {
      conditions: 'Conditions',
      actions: 'Actions',
      name: 'String',
      disabled: 'Boolean',
    },
  },
  Conditions: {
    type: 'Array',
    of: 'Condition',
  },
  Condition: {
    type: 'Primitive',
  },
  Boolean: {
    type: 'Boolean', // default value false
  },
};
