import { Vector3 } from 'three';
import BitSet from '../../generic/container/BitSet';
import ObjectArrayView from '../../generic/container/ObjectArrayView';
import PolyMap from '../model/PolyMap';

export default function Renormalizer(newMesh) {
  this.mesh = newMesh;
  this.dirtyFaces = new BitSet(newMesh.getNumFaces());
  this.areaWeighted = true;
  this.needNewNormalMap = true;
  this.needNewNormalValues = true;

  newMesh.positions.updateInverseIndex();
}

Renormalizer.prototype = {
  constructor: Renormalizer,

  markPositionsDirty: function (positionIndices) {
    var dirtyFaces = this.dirtyFaces,
      positions = this.mesh.positions,
      faceIndexOffsets = positions.valueFaceIndexOffsets,
      positionFaceIndices = positions.valueFaceIndices;

    for (var i = 0, n = positionIndices.length; i !== n; ++i) {
      var positionIndex = positionIndices[i];

      dirtyFaces.includeFromArray(
        positionFaceIndices,
        faceIndexOffsets[positionIndex],
        faceIndexOffsets[positionIndex + 1]
      );
    }
  },

  updateMesh: function () {
    var mesh = this.mesh,
      dirtyFaces = this.dirtyFaces,
      areaWeighted = this.areaWeighted,
      nFaces = dirtyFaces.length,
      positionsMap = mesh.positions,
      positions = positionsMap.values,
      indices = positionsMap.faceValueIndices,
      faceNormalCache = new ObjectArrayView(Vector3, nFaces),
      cachedFaceNormals = new BitSet(nFaces),
      vertexNormal = new Vector3(),
      faceNormal = new Vector3(),
      b = new Vector3(),
      ba = new Vector3(),
      faceOffsets = mesh.faceRangeOffsets,
      normalMap = this.ownNormalMap().updateInverseIndex(),
      normals = normalMap.values,
      faceIndices = normalMap.valueFaceIndices,
      faceIndexOffsets = normalMap.valueFaceIndexOffsets,
      facesBegin = faceIndexOffsets[0];

    for (var i = 0, n = normals.length; i !== n; ++i) {
      var facesUntil = faceIndexOffsets[i + 1];

      if (dirtyFaces.containsAnyIn(faceIndices, facesBegin, facesUntil)) {
        vertexNormal.setScalar(0);

        for (var j = facesBegin; j !== facesUntil; ++j)
          vertexNormal.add(calculateFaceNormal(faceIndices[j]));

        normals.setAt(i, vertexNormal.normalize());
      }

      facesBegin = facesUntil;
    }

    return mesh;

    function calculateFaceNormal(faceIndex) {
      if (cachedFaceNormals.contains(faceIndex)) {
        faceNormalCache.getAt(faceIndex, faceNormal);
      } else {
        var vertsBegin = faceOffsets[faceIndex],
          vertsUntil = faceOffsets[faceIndex + 1];

        if (vertsUntil - vertsBegin >= 3) {
          var bc = faceNormal;

          positions.getAt(indices[vertsBegin], b);

          positions.getAt(indices[vertsUntil - 1], ba).sub(b);
          positions.getAt(indices[vertsBegin + 1], bc).sub(b);

          bc.cross(ba);

          if (!areaWeighted) faceNormal.normalize();
        } else {
          faceNormal.setScalar(0);
        }

        faceNormalCache.setAt(faceIndex, faceNormal);
        cachedFaceNormals.include(faceIndex);
      }

      return faceNormal;
    }
  },

  ownNormalMap: function () {
    var mesh = this.mesh,
      normalMap = mesh.normalMap;

    if (this.needNewNormalMap)
      mesh.normalMap = normalMap = new PolyMap(normalMap);

    if (this.needNewNormalValues) normalMap.values = normalMap.values.clone();

    return normalMap;
  },
};
