import { NullPrimitive, OperatorData, OperatorDefinition } from '../../types';

export const Model: OperatorDefinition = {
  schema: {
    // asset: { type: 'Node', assetType: 'Model', for: 'Model', label: 'Asset' },
    // FIXME: for not part of type?
    asset: { type: 'Node', assetType: 'Model', label: 'Asset' },
  },
  update: (operator: OperatorData, primitive: NullPrimitive) => {
    if (operator.asset) {
      primitive.link = operator.asset;
    }
    return primitive;
  },
};
