import Arrays from '../../generic/utility/Arrays';
import GlobalCompaction from '../../generic/algorithm/GlobalCompaction';

const ImportConversions = {
  collapsePositions: function(positions, indices) {
    var compaction = new GlobalCompaction(positions),
      compactPositions = compaction.compactedValues(positions);

    if (indices) compaction.transcribeIndices(indices);

    return compactPositions;
  },

  collapseMapVertexValues: function(polyMesh, polyMap) {
    var positions = polyMesh.positions.updateInverseIndex(),
      indexRanges = polyMesh.faceRangeOffsets,
      positionIndices = positions.faceValueIndices,
      faceRangeOffsets = positions.faceRangeOffsets,
      currFaceIndex = -1,
      nextFaceStart = 0,
      faceIndexOffsets = positions.valueFaceIndexOffsets,
      faceIndices = positions.valueFaceIndices,
      values = polyMap.values,
      data = values.data,
      elementSize = values.elementSize,
      valueIndices = polyMap.faceValueIndices;

    for (var i = 0, n = polyMesh.getNumFaceVertices(); i !== n; ++i) {
      var positionIndex = positionIndices[i],
        currValueIndex = valueIndices[i],
        currValueOffset = currValueIndex * elementSize;

      if (i === nextFaceStart)
        nextFaceStart = faceRangeOffsets[++currFaceIndex + 1];

      var facesStart = faceIndexOffsets[positionIndex],
        facesUntil = faceIndexOffsets[positionIndex + 1];

      for (var j = facesStart; j !== facesUntil; ++j) {
        var faceIndex = faceIndices[j];
        if (faceIndex === currFaceIndex) break;

        var valueIndexOffset = positions.findValueIndexOffset(
            faceIndex,
            positionIndex
          ),
          valueIndex = valueIndices[valueIndexOffset];

        if (valueIndex !== currValueIndex) {
          if (
            Arrays.rangeEquals(
              data,
              currValueOffset,
              data,
              valueIndex * elementSize,
              elementSize
            )
          )
            valueIndices[i] = valueIndex;
        }
      }
    }
  },
};

export default ImportConversions;
