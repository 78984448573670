/**
 * This class works around the problem that {@code for .. in} loops poison
 * the surrounding function and inhibit all optimizations with V8. This is
 * of course undesirable, especially since some data processing may happen
 * in the same function, and redundant workarounds would obscure the code.
 *
 * Therefore we use an extra data structure with an array of names.
 *
 * A noteworthy property is that there is no reliance on the indices, so
 * {@code .namesByIndex} can be sorted (unless within an ongoing iteration,
 * of course) in-place, e.g. alphabetically or by use frequency for UI or
 * caching purposes.
 *
 * Further, we provide a nested {@code Iterator} class that treats {@code
 * null} values gracefully.
 *
 * Example:
 * <pre>
 * // Create an iterator
 * const iterator = new ObjectsByName.Iterator( objects );
 *
 * // Iterate when this.objects is an ObjectsByName instance, do nothing
 * // in case it is null
 * for ( iterator.next(); ) {
 *     // [... use iterator.name, iterator.index and iterator.element ...]
 * }
 * </pre>
 *
 * @template T
 * @constructor
 */
export default function ObjectsByName() {
  /** @const @type {!Object<!string,T>} */
  this.byName = {};

  /** @const @type {!Array<T>} */
  this.namesByIndex = [];
}

ObjectsByName.prototype = {
  constructor: ObjectsByName,

  /** @type {!number} */
  get length() {
    return this.namesByIndex.length;
  },

  set: function (name, object) {
    const byName = this.byName;
    if (name in byName === false) this.namesByIndex.push(name);
    byName[name] = object;
    return this;
  },
};

/**
 * @template T
 * @constructor
 * @param {ObjectsByName<T>=} optionalContainer
 */
ObjectsByName.Iterator = function (optionalContainer) {
  this.reset(optionalContainer || null);
};

ObjectsByName.Iterator.prototype = {
  constructor: ObjectsByName.Iterator,

  /**
   * @param {ObjectsByName<T>=} optionalContainer
   * @return {!ObjectsByName.Iterator<T>}
   */
  reset: function (optionalContainer) {
    if (optionalContainer !== undefined) this.container = optionalContainer;

    this.name = null;
    this.index = -1;
    this.element = null;
    return this;
  },

  /**
   * @return {!boolean}
   */
  next: function () {
    const map = this.container;
    if (map === null) return false;

    const names = map.namesByIndex;
    const index = ++this.index;

    if (index >= names.length) {
      this.name = null;
      return false;
    }

    this.element = map.byName[(this.name = names[index])];
    return true;
  },

  // NOTE: This feature might not be used. It is necessary to remove it to convert to generators, so ensure no one uses it first! (Move to ObjectsByName class?)
  /**
   * @param {!string} name
   * @param {T} element
   * @return {!ObjectsByName<T>}
   */
  add: function (name, element) {
    // TODO if ( this === null ) this = new ObjectsByName();

    const namesByIndex = this.namesByIndex;

    if (namesByIndex.indexOf(name) !== -1)
      throw Error("ObjectsByName: Name '" + name + "' not unique!");

    namesByIndex.push(name);
    this.byName[name] = element;

    return this;
  },
};

ObjectsByName.shallowClone = (function () {
  const i = new ObjectsByName.Iterator();

  return function shallowClone(that) {
    const newMap = new ObjectsByName();

    if (!that) return newMap;

    const byName = newMap.byName;
    const names = newMap.namesByIndex;

    names.length = that.namesByIndex.length;

    for (i.reset(that); i.next(); ) {
      const name = i.name;
      byName[name] = i.element;
      names[i.index] = name;
    }

    i.reset(null); // (!)

    return newMap;
  };
})();
