import {
  MaterialMap,
  MaterialReferencePrimitive,
  MaterialPrimitive,
  OperatorData,
  OperatorDefinition,
  OperatorSchema,
} from '../../types';

import { Physical } from './Physical';
// import { SceneGraphUpdateOptions } from '../../utils/evalPlug';

const maps = [
  //   'baseMap', // TODO: for testing only, remove this in production
  'opacityMap',
  'bumpMap',
  'normalMap',
  'aoMap',
  'lightMap',
];

const schema = maps.reduce((acc, mapName) => {
  acc[mapName] = {
    label: (Physical.schema as any)[mapName].label,
    type: 'Node',
    assetType: 'Texture',
  };
  return acc;
}, {} as OperatorSchema);

export const MapOverride: OperatorDefinition = {
  label: 'Map Override',
  schema,

  update: (operator: any, primitive: MaterialReferencePrimitive) => {
    const textureMaps = [
      ['aoMap', 'aoMap'],
      ['opacityMap', 'opacityMap'],
      ['normalMap', 'normalMap'],
      ['bumpMap', 'bumpMap'],
      ['lightMap', 'lightMap'],
    ];
    primitive.maps = textureMaps.reduce(
      (acc: Array<MaterialMap>, [k, mapName]) => {
        if (!operator[mapName]) return acc;
        return acc.concat([{ ref: operator[mapName], attachTo: k }]);
      },
      []
    );
    return primitive;
  },
};

export default MapOverride;
