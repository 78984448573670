import MergeSort from '../../../generic/algorithm/MergeSort';
import Arrays from '../../../generic/utility/Arrays';

/**
 * @template T
 * @constructor
 *
 */
export default function EdgeVertexAdjacency(polyMap) {
  this.polyMap = polyMap;

  // The low value index acts as the pivot for edge enumeration, so
  // the following array is indexed by edge UIDs:
  this.edgeHighVertexIndices = null;

  // Low -> high value index mapping (describes ranges of edge UIDs):
  this.edgePivotRangeOffsets = null;

  this.edgeVertices = null;

  this._compactEdgeLowToHighMap(this._buildEdgeLowToHighMap());

  this._inverseMapping();
}

EdgeVertexAdjacency.prototype = {
  constructor: EdgeVertexAdjacency,

  getNumEdges: function() {
    return this.edgeHighVertexIndices.length;
  },

  // VV -> E
  findEdgeId: function(faceValueIndexA, faceValueIndexB) {
    let loVertex = faceValueIndexA;
    let hiVertex = faceValueIndexB;

    if (hiVertex < loVertex) {
      loVertex = faceValueIndexB;
      hiVertex = faceValueIndexA;
    }

    const rangeOffsets = this.edgePivotRangeOffsets;

    return Arrays.binarySearch(
      this.edgeHighVertexIndices,
      hiVertex,
      rangeOffsets[loVertex],
      rangeOffsets[loVertex + 1]
    );
  },

  // E -> VV
  getVerticesForEdge: function(edgeId, optionalResult) {
    if (optionalResult) {
      optionalResult[0] = this.edgeVertices[edgeId * 2];
      optionalResult[1] = this.edgeVertices[edgeId * 2 + 1];
      return optionalResult;
    } else {
      return {
        v: this.edgeVertices[edgeId * 2],
        vNext: this.edgeVertices[edgeId * 2 + 1],
      };
    }
  },

  _inverseMapping: function() {
    const numEdges = this.getNumEdges();

    const edgeVertices = new Uint32Array(numEdges * 2);

    for (let v0 = 0; v0 < this.edgePivotRangeOffsets.length - 1; v0++) {
      const edgeBegin = this.edgePivotRangeOffsets[v0];
      const edgeEnd = this.edgePivotRangeOffsets[v0 + 1];

      for (let i = edgeBegin; i <= edgeEnd; i++) {
        const v1 = this.edgeHighVertexIndices[i];

        edgeVertices[i * 2 + 0] = v0;
        edgeVertices[i * 2 + 1] = v1;
      }
    }

    this.edgeVertices = edgeVertices;
  },

  _buildEdgeLowToHighMap: function() {
    const faceOffsets = this.polyMap.faceRangeOffsets;
    const valueIndices = this.polyMap.faceValueIndices;

    const nValues = this.polyMap.values.length;
    const offsets = new Uint32Array(nValues + 2);
    const histogramArea = offsets.subarray(2);

    let faceBegin = faceOffsets[0];
    let maxEdgesSharingPivot = 0;

    // Construct histogram and accumulate:

    for (let i = 1, n = faceOffsets.length; i !== n; ++i) {
      const faceUntil = faceOffsets[i];
      let vertexA = valueIndices[faceUntil - 1];

      for (let j = faceBegin; j !== faceUntil; ++j) {
        const vertexB = valueIndices[j];
        const loVertex = vertexB < vertexA ? vertexB : vertexA;

        const nEdges = ++histogramArea[loVertex];

        if (nEdges > maxEdgesSharingPivot) maxEdgesSharingPivot = nEdges;

        vertexA = vertexB;
      }

      faceBegin = faceUntil;
    }

    Arrays.accumulate(histogramArea);

    // Build the indices array (shifting the offsets):

    const writeOffsets = offsets.subarray(1);
    const indices = new Uint32Array(writeOffsets[nValues]);

    faceBegin = faceOffsets[0];

    for (let i = 1, n = faceOffsets.length; i !== n; ++i) {
      const faceUntil = faceOffsets[i];
      let vertexA = valueIndices[faceUntil - 1];

      for (let j = faceBegin; j !== faceUntil; ++j) {
        const vertexB = valueIndices[j];

        let loVertex = vertexA;
        let hiVertex = vertexB;

        if (hiVertex < loVertex) {
          loVertex = vertexB;
          hiVertex = vertexA;
        }

        indices[writeOffsets[loVertex]++] = hiVertex;

        vertexA = vertexB;
      }

      faceBegin = faceUntil;
    }

    this.edgePivotRangeOffsets = offsets;
    this.edgeHighVertexIndices = indices;

    return maxEdgesSharingPivot;
  },

  _compactEdgeLowToHighMap: function(maxEdgesSharingPivot) {
    const offsets = this.edgePivotRangeOffsets;
    const indices = this.edgeHighVertexIndices;

    const sorter = new MergeSort(Uint32Array, maxEdgesSharingPivot);

    const nOffsets = this.polyMap.values.length + 1;

    let rangeUntil = 0;
    let rangeBegin = 0;
    let writeOffset = 0;

    for (let i = 1; i !== nOffsets; ++i) {
      rangeUntil = offsets[i];

      if (rangeBegin !== rangeUntil) {
        sorter.sortRange(indices, rangeBegin, rangeUntil);

        // Unique-compact indices within each range:

        let prevElement = indices[rangeBegin];
        indices[writeOffset++] = prevElement;

        for (let j = rangeBegin + 1; j < rangeUntil; ++j) {
          const element = indices[j];
          if (element !== prevElement) {
            indices[writeOffset++] = element;
            prevElement = element;
          }
        }
      }

      offsets[i] = writeOffset; // re-write compact offsets
      rangeBegin = rangeUntil; // certainly >= writeOffset
    }

    // Shrink to fit (offsets only has 2 extra elements, so view it):

    this.edgeHighVertexIndices = Arrays.slice(indices, 0, writeOffset);

    this.edgePivotRangeOffsets = offsets.subarray(0, nOffsets);
  },
};
