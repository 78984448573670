import {
  OperatorData,
  OperatorDefinition,
  MaterialReferencePrimitive,
} from '../../types';

export const Reference: OperatorDefinition = {
  schema: {
    assetDepr: {
      type: 'Label',
      defaultValue: '"Asset Combined" is deprecated, use "Reference"',
    },
    defaultColor: {
      label: 'Default Color',
      type: 'Color',
      defaultValue: 0xb0b0b0,
    },
    asset: { type: 'Asset', assetType: 'Material', label: 'Asset Combined' },
    reference: {
      type: 'Plug',
      plug: 'Material',
      label: 'Reference',
      assetType: 'Material',
    },
  },
  update(operator: OperatorData, primitive: MaterialReferencePrimitive) {
    if (operator.reference) {
      primitive.reference = operator.reference;
      return primitive;
    }
    return primitive;
    // return { reference: operator.reference };
    // if (operator.asset && operator.asset.assetId !== '') {
    //   return { ...operator.asset.Material };
    // }
    // return { defaultColor: operator.defaultColor };
  },
};
