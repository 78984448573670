import {
  OperatorData,
  OperatorDefinition,
  TransformPrimitive,
} from '../../types';
import { Euler, MathUtils, Matrix4, Quaternion, Vector3 } from 'three';
import {
  getAxis,
  getLocalTransform,
  getQuaternionAxis,
  TransformOperator,
} from '../../transform';
const { degToRad } = MathUtils;

const Transform: OperatorDefinition = {
  label: 'Transform',

  schema: {
    translation: {
      label: 'Translation',
      type: 'Vec3',
      step: 0.1,
      defaultValue: { x: 0, y: 0, z: 0 },
      animatable: true,
    },
    rotation: {
      label: 'Rotation',
      type: 'Vec3',
      step: 1.0,
      defaultValue: { x: 0, y: 0, z: 0 },
      animatable: true,
    },
    scale: {
      label: 'Scale',
      type: 'Vec3',
      step: 0.01,
      defaultValue: { x: 1, y: 1, z: 1 },
      animatable: true,
    }, // Scale's step must be 0.01 as 1 percent
    shear: {
      label: 'Shear',
      type: 'Vec3',
      step: 0.1,
      defaultValue: { x: 0, y: 0, z: 0 },
      animatable: true,
    },
    rotateOrder: {
      label: 'Rotate Order',
      type: 'Options',
      values: ['XYZ', 'YZX', 'ZXY', 'XZY', 'YXZ', 'ZYX'],
      defaultValue: 'ZYX',
    },
    preRotation: {
      label: 'Pre-Rotation',
      type: 'Vec3',
      step: 1.0,
      defaultValue: { x: 0, y: 0, z: 0 },
    },
    rotateAxis: {
      label: 'Rotate Axis',
      type: 'Vec3',
      step: 1.0,
      defaultValue: { x: 0, y: 0, z: 0 },
    },
    rotatePivotOffset: {
      label: 'Rotate Pivot Offset',
      type: 'Vec3',
      step: 0.1,
      defaultValue: { x: 0, y: 0, z: 0 },
    },
    scalePivotOffset: {
      label: 'Scale Pivot Offset',
      type: 'Vec3',
      step: 0.1,
      defaultValue: { x: 0, y: 0, z: 0 },
    },
    localRotatePivot: {
      label: 'Local Rotate Pivot',
      type: 'Vec3',
      step: 0.1,
      defaultValue: { x: 0, y: 0, z: 0 },
    },
    localScalePivot: {
      label: 'Local Scale Pivot',
      type: 'Vec3',
      step: 0.1,
      defaultValue: { x: 0, y: 0, z: 0 },
    },
  },

  update: (operator: OperatorData, primitive: TransformPrimitive) => {
    primitive.transform = getLocalTransform(<TransformOperator>operator);
    primitive.transform.decompose(
      primitive.position,
      primitive.rotation,
      primitive.scale
    );
    primitive.euler = new Euler().setFromQuaternion(primitive.rotation, 'YXZ');
    return primitive;
  },
};

export default Transform;
