import {
  AssetModel,
  Comparator,
  ThreekitSource,
  QueryResponse,
} from '../types';
import { stringify } from 'query-string';
import { omit } from 'lodash';

export async function get(
  source: ThreekitSource,
  id: string
): Promise<AssetModel | undefined> {
  const url = `${source.apiRoot('assets')}/assets/${id}`;
  // console.log('queries.cas.get', id);
  const result = await source.httpClient.get(url);
  return result.status === 200 ? result.data : undefined;
}

interface MetadataOptions {
  key: string;
  comparator: Comparator;
  value: any;
}

export interface AssetFindOptions {
  id?: string; // id query, most specific
  name?: string;
  nameLike?: string;
  type?: string; // asset type
  metadata?: Array<MetadataOptions>;
  orgId?: string;
  tags?: Array<string>;
  state?: 'published' | 'draft';
}

type AssetQueryResponse = QueryResponse & { assets: AssetModel[] };

const nullQueryResponse = { count: 1, page: 1, perPage: 1 };
const emptyQueryResponse = { count: 0, page: 0, perPage: 1, assets: [] };

export async function find(
  source: ThreekitSource,
  query: AssetFindOptions
): Promise<AssetQueryResponse> {
  if (query.id) {
    const asset = await get(source, query.id);
    return asset
      ? { assets: [asset], ...nullQueryResponse }
      : emptyQueryResponse;
  }

  let opts: { [key: string]: string | string[] | undefined } = {
    ...omit(query, 'metadata'),
  };
  if (query.metadata) opts.metadata = JSON.stringify(query.metadata);
  const url = `${source.apiRoot('assets')}/assets?${stringify(opts)}`;
  // console.log('find', url);

  const result = await source.httpClient.get(url);
  return result.data;
}
