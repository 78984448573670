import Arrays from '../utility/Arrays';

/**
 * Utilties for working with arrays of indices that are broadly useful and not
 * tailored towards a specific purpose.
 *
 * @namespace
 */
const IndexMappings = {
  /**
   * Create or populate an array with elements equal to their indices.
   *
   * @param {!number} n
   * @param {ArrayOrType=} arrayOrType defaults to Uint32Array
   * @return {!Array<!number>}
   */
  identity: function(n, arrayOrType) {
    const result = Arrays.maybeCreate(arrayOrType || Uint32Array, n);
    for (let i = 0; i !== n; ++i) result[i] = i;
    return result;
  },

  /**
   * Get an identity map as a view onto a globally shared buffer which
   * must not be changed.
   *
   * @param {!number} n
   * @return {!Uint32Array}
   */
  identityForReading: function(n) {
    let proto = IndexMappings._identityProto;

    if (n > proto.length) {
      proto = IndexMappings.identity(((n * 5) / 4) | 0);
      IndexMappings._identityProto = proto;
    }

    return proto.subarray(0, n);
  },

  _identityProto: Arrays.Empty.Uint32Array,

  /**
   * Create an array where each index corresponds to the values and the
   * values map back to the index of the source array.
   *
   * For non-surjective mappings, the missing indices are not written to.
   * For non-injective mappings, duplicate indices will map to their last
   * position of occurence in the input.
   *
   * @param {!Array<!number>} source integer indices
   * @param {number} n
   *    maximum entry in source map + 1
   *    can be {@code null} when an existing array is given
   * @param {ArrayOrType=} arrayOrType defaults to Uint32Array
   * @return {!Array<!number>}
   */
  inverse: function(source, n, arrayOrType) {
    const result = Arrays.maybeCreate(arrayOrType || Uint32Array, n);

    for (let i = 0, e = source.length; i !== e; ++i) result[source[i]] = i;

    return result;
  },

  /**
   * Transform one mapping with another, in-place unless an explicit
   * destination is given.
   *
   * @param {!Array<!number>} map indices to transform
   * @param {!Array<!number>} transform mapping to apply
   * @param {Array<!number>=} optionalDestination
   * @return {!Array<!number>} same as {@code map}
   */
  apply: function(map, transform, optionalDestination) {
    const dst = optionalDestination || map;

    for (let i = 0, n = map.length; i !== n; ++i) dst[i] = transform[map[i]];

    return map;
  },

  /**
   * Count the equal elements.
   *
   * When an existing array is used for the destination, it must be
   * filled with zeroes.
   *
   * @param {!Array<!number>} source integer indices
   * @param {!number} n maximum entry in source map + 1
   * @param {ArrayOrType=} arrayOrType defaults to Uint32Array
   * @return {!Array<!number>}
   */
  histogram: function(source, n, arrayOrType) {
    const result = Arrays.maybeCreate(arrayOrType || Uint32Array, n);

    for (let i = 0, e = source.length; i !== e; ++i) ++result[source[i]];

    return result;
  },
};
export default IndexMappings;
