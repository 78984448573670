// Note:  Do not import from this file in scene-graph, it's only used to export the enums for other pacakges.
//        If you import from here, it will potentially result in a circular dependency.
export { UVMapType } from '../polyMesh/geometric/operator/uvMap';
export { CUSTOM_FILM_SIZE } from './filmSizes';
// export { VrayLightUnits, VrayOverrides } from './VrayLight';

import { Color } from 'three';

import { ToOptions } from './utils';

export enum BackgroundStyles {
  Transparent = 0,
  Color = 1,
  Image = 2,
  CubeMap = 3,
  Environement = 4,
}

export enum BackgroundPosition {
  Fill = 0,
  Fit = 1,
  Stretch = 2,
  Center = 3,
}

export enum ScaleMode {
  Scale = 0,
  Tiling = 1,
}

export enum WrapType {
  NONE = 0,
  WRAP = 1,
  MIRROR = 2,
}

export const MISSING_ORIG_SIZE = -1;

export enum RenderCategories {
  AUTO = 0,
  OPAQUE = 1,
  TRANSPARENT = 2,
  CUTOUT = 3,
}

export const UV_TRIPLANAR = 'triplanar';

export enum Projection {
  PERSPECTIVE = 'Perspective',
  ORTHOGRAPHIC = 'Orthographic',
}

export enum ViewControl {
  FoV = 0,
  FocalLength = 1,
}

export enum CameraControlsMode {
  ORBIT = 'orbit',
  FIRST_PERSON = 'firstPerson',
  NODE_TURNTABLE = 'nodeTurntable',
  NODE_TRACKBALL = 'nodeTrackball',
  STATIC = 'static',
}

export enum ResetNodeRotationMode {
  NEVER = 0,
  ON_BEGIN = 1,
  ON_END = 2,
  ALWAYS = 3,
}

export enum InitialFramingAction {
  OFF = 0,
  FRAME = 1,
  CENTER = 2,
}

export enum LongitudeConstraintMode {
  OFF = 0,
  WORLD = 1,
  RELATIVE = 2,
}

export enum LatitudeConstraintMode {
  OFF = 0,
  WORLD = 1,
  RELATIVE = 2,
}

export enum ZoomConstraintMode {
  OFF = 0,
  DISTANCE_OFFSET = 1,
}

export enum WallPlacements {
  HORIZONTAL = 0,
  VERTICAL_WALL_TO_WALL = 1,
  VERTICAL_GROUND_TO_WALL = 2,
}

const MAX_UV = 10;
const NumberedUvChannelObject: { [u: string]: NumberedUvChannelsType } = {};
for (let idx = 0; idx < MAX_UV; ++idx) {
  NumberedUvChannelObject[`UV${idx}`] = `${idx + 1}` as NumberedUvChannelsType;
}
const AllUvChannelObject = {
  ['Triplanar Mapping']: UV_TRIPLANAR,
  ...NumberedUvChannelObject,
};

// prettier-ignore
export type NumberedUvChannelsType = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10';
export type AllUvChannelsType = NumberedUvChannelsType | typeof UV_TRIPLANAR;

export const NumberedUvChannels = ToOptions(NumberedUvChannelObject);
export const AllUvChannels = ToOptions(AllUvChannelObject);

// export { VertexSpace } from './polyMesh/MergeVertices';

// import { operator } from '../Shape';
// export const { BooleanType } = operator;

export enum ComponentType {
  VERT = 'vertex',
  EDGE = 'edge',
  FACE = 'face',
}

export enum CoordinateSpace {
  LOCAL = 'local',
  WORLD = 'world',
}

export const DEFAULT_FLAKES_COLOR = new Color(0.76, 0.76, 0.76);

// import { constants as imageConstants } from '@threekit/image';
// const { FontTypes, WarpMode } = imageConstants;
// declare type FontTypes = imageConstants.FontTypes;
// declare type WarpMode = imageConstants.WarpMode;
// export { FontTypes, WarpMode };

export enum SpriteScaleMode {
  WorldFixed = 0,
  WorldPixel = 1,
}

export enum ThinFilmLUTs {
  Custom = 0,
  Default = 1, // can change this later to have more options like 'Version 1.0', 'Version 1.1 (Increased Saturation)', 'Version 1.1 [IOR=Oil]', etc...
}

export const ThinFilmLUTFiles: { [lut in ThinFilmLUTs]?: string } = {
  // original shader with fresnel factored in https://glslsandbox.com/e#74329.3
  [ThinFilmLUTs.Default]:
    'sha256-91b417a4b05c692c8ec33bad8fb35dadc0ca39a93f975c1c5f67bea0f046b32e', // https://main.threekit.dev/o/publicstages/assets/04c67913-6d62-499f-97e6-9e85ddec177e // https://glslsandbox.com/e#74329.18
};
