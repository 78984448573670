import PolyMap from '../model/PolyMap';
import PolyMesh from '../model/PolyMesh';
import ScalarArrayView from '../../generic/container/ScalarArrayView';
import { TypedArray, arrayCtor } from '../../generic/utility/ArrayTypes';
// @ts-ignore
import EarCutTriangulation from '../algorithm/EarCutTriangulation';
import applyFunctionToPolyMap from './applyFunctionToPolyMap';

function triangulateMap(
  polyMap: PolyMap<any>,
  name: string,
  { faceRangeOffsets, triangulation }: any
) {
  const result = new ScalarArrayView(triangulation.numTriangles * 3);
  triangulation.translateIndices(polyMap.faceValueIndices, result);

  return new PolyMap({
    faceRangeOffsets,
    faceValueIndices: result.data,
    values: polyMap.values,
  });
}

function perFaceArrayTriangulation<T extends TypedArray>(
  array: T,
  faceRangeOffsets: Uint32Array,
  numTriangles: number
) {
  const result = new (arrayCtor(array))(numTriangles);

  let readIdx = 0;
  let writeIdx = 0;
  let faceBegin = faceRangeOffsets[0];
  for (let f = 1; f < faceRangeOffsets.length; ++f, ++readIdx) {
    const faceEnd = faceRangeOffsets[f];
    const currValue = array[readIdx];

    const nbTriangles = faceEnd - faceBegin - 2;
    for (let t = 0; t < nbTriangles; ++t, ++writeIdx) {
      result[writeIdx] = currValue;
    }
    faceBegin = faceEnd;
  }
  return result;
}

const replacementFields = ['materialIds', 'smoothGroup'];

export default function triangulateMesh(polyMesh: PolyMesh) {
  const triangulation = new EarCutTriangulation(polyMesh);

  const froSize = triangulation.numTriangles + 1;
  const faceRangeOffsets = new Uint32Array(froSize);
  for (let i = 1, off = 3; i < froSize; ++i, off += 3) {
    faceRangeOffsets[i] = off;
  }

  const replacements = replacementFields.reduce((acc: any, field) => {
    // @ts-ignore
    const value = polyMesh[field] as TypedArray;
    if (value) {
      acc[field] = perFaceArrayTriangulation(
        value,
        polyMesh.faceRangeOffsets,
        triangulation.numTriangles
      );
    }
    return acc;
  }, {});

  const args = { faceRangeOffsets, triangulation };
  return applyFunctionToPolyMap(polyMesh, triangulateMap, args, replacements);
}
