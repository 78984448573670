import React from 'react';
import { pick } from 'lodash';
import Asset from './Asset';
import Node from './Node';
import { AssetOrNodeReference, AssetState, ConfigurationType } from '../types';
import isUuid from 'is-uuid';

export interface NodeOrAssetProps {
  reference: AssetOrNodeReference;
  assetState: AssetState;
  configuration: ConfigurationType;
  attachTo?: string;
  overrides?: any;
}

export function isValidReference(reference: AssetOrNodeReference) {
  if (!reference) return false;
  if (typeof reference === 'string') {
    return isUuid.anyNonNil(reference);
  }
  if (reference.assetId) {
    return true;
  }
  return false;
}

/**
 *  Given a ref that is either a node reference (string), or an Asset reference
 * ({ assetId, ... }) dispatch it to the correct component (Node or Asset).
 * There should not be a need for code like `if (ref.assetId) {} else {}` anywhere
 * else.
 */
export default function NodeOrAsset(props: NodeOrAssetProps) {
  const { reference, assetState } = props;
  if (!reference) return null;
  // console.log('Node or asset ref', reference);

  const propagateProps = pick(
    props,
    'attachTo',
    'configuration',
    'assetState',
    'overrides'
  );
  if (typeof reference === 'string') {
    return <Node {...propagateProps} node={assetState.nodes[reference]} />;
  } else if (reference.assetId) {
    const refConf =
      reference.configuration && typeof reference.configuration === 'string'
        ? JSON.parse(reference.configuration)
        : reference.configuration;
    // config propagation has preference over reference configuration
    const configuration = { ...refConf, ...props.configuration };

    return (
      <Asset
        key={reference.assetId}
        {...propagateProps}
        id={reference.assetId}
        configuration={configuration}
        fromId={assetState.id}
      />
    );
  }
  return null;
}
