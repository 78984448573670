import earcut from 'earcut';

import ObjectsByName from '../../generic/container/ObjectsByName';
import findBoundingBox from '../operator/findBoundingBox';
import calculateVolume from '../operator/calculateVolume';
import PolyMaps from './PolyMaps';
import PolyMap from './PolyMap';
import FaceNormals from './FaceNormals';
import setNormalsFromSmoothingGroups from '../operator/setNormalsFromSmoothingGroups';
import { SmoothingGroupIds } from './ShapeSet';

export default function PolyMesh(from, replacements) {
  if (!from) {
    this.faceRangeOffsets = new Uint32Array(1);
    this.positions = new PolyMap();

    this.colorMaps = new ObjectsByName();
    this.uvMaps = new ObjectsByName();
  } else {
    if (!from.colorMaps)
      console.error('Polymesh must have initialized colorMaps container.');
    if (!from.uvMaps)
      console.error('Polymesh must have initialized uvMaps container.');

    this.faceRangeOffsets = from.faceRangeOffsets; // Uint32Array
    this.positions = from.positions; // PolyMap Object
    this.normalMap = from.normalMap; // PolyMap Object
    this.uvMaps = ObjectsByName.shallowClone(from.uvMaps); // ObjectsByName contains PolyMap Object
    if (from.tangentMap) this.tangentMap = from.tangentMap; // PolyMap Object

    // optional properties discarded topology change:
    if (from.skinning) this.skinning = from.skinning; // Skinning Object
    if (from.edgeCreaseWeights) this.edgeCreaseWeights = from.edgeCreaseWeights; // Float32Array : index === edge, value === weight {0..1}
    if (from.materialIds) this.materialIds = from.materialIds; // Uint32Array  : index === face

    if (from.blendShapes) this.blendShapes = from.blendShapes; // BlendShape Object

    if (from.faceConvexity) this.faceConvexity = from.faceConvexity; // cache of face convexity types

    if (from.smoothGroup) this.smoothGroup = from.smoothGroup;

    // not implemented:
    // this.tangentMap = from.tangentMap;                           // PolyMap Object
    this.colorMaps = ObjectsByName.shallowClone(from.colorMaps); // ObjectsByName contains PolyMap Object

    // this._cachedVolume = (typeof from._cachedVolume === 'number' && !isNaN(from._cachedVolume)) ? from._cachedVolume : null;
    this._boundingBox = from.boundingBox; // re-use incoming bbox if already calculated

    if (replacements) {
      Object.assign(this, replacements);
    }
  }

  this._cachedVolume = null; // if we can have some verification of validity of from._cachedVolume, we can use that
  this._boundingBox = null;
  // this.boundingBox = findBoundingBox(this.positions);
  this._faceNormals = null;

  this.cachedNumThreeTriangles = null;
  this.cachedNumThreeVertices = null;
}

PolyMesh.prototype = {
  constructor: PolyMesh,

  get boundingBox() {
    if (!this._boundingBox) {
      this._boundingBox = findBoundingBox(this.positions);
    }
    return this._boundingBox;
  },

  getNumFaces: function () {
    return this.faceRangeOffsets.length - 1;
  },

  getNumFaceVertices: function () {
    return this.positions.faceValueIndices.length;
  },

  getNumVertices: function () {
    return this.positions.values.length;
  },

  getMapById: function (mapId) {
    return PolyMaps.resolveMap(this, mapId) || null;
  },

  topologyChanged: function (materialIdsValid = false) {
    //this function is only to be called during construction of a new polymesh
    //its purpose is to nullify data that would become invalidated due to a change in topology
    //the data to remain intact would have to also be correctly mapped in each operator
    //in the future operators will instead generate a mapping that can be applied through
    //a general mapping function so such additions can be done in a singular location
    if (this.skinning) this.skinning = null;
    if (this.edgeCreaseWeights) this.edgeCreaseWeights = null;
    if (this.materialIds && !materialIdsValid) this.materialIds = null;
    return this;
  },

  getVolume: function () {
    // Keep in mind that this does is the volume for the base geometry, so you may want to multiply
    // the result by the scaling of its transform (ex. getVolume() * scale.x * scale.y * scale.z)
    if (this._cachedVolume === null) this._cachedVolume = calculateVolume(this);
    return this._cachedVolume;
  },

  // face normal structure, computed on-demand for normals of each face
  get faceNormals() {
    if (!this._faceNormals) this._faceNormals = new FaceNormals(this.positions);

    return this._faceNormals;
  },
};

PolyMesh.fromData = (
  faceRangeOffsets,
  positions,
  optionalIndices,
  optionalMaterialIds
) => {
  const polyMesh = new PolyMesh({
    faceRangeOffsets,
    positions: positions.isPolyMap
      ? positions
      : PolyMap.fromData(faceRangeOffsets, optionalIndices || null, positions),
    uvMaps: new ObjectsByName(), // ObjectsByName< PolyMap<Vector2> >
    colorMaps: new ObjectsByName(), // ObjectsByName< PolyMap<Color> >
  });

  if (optionalMaterialIds) polyMesh.materialIds = optionalMaterialIds;

  return polyMesh;
};

PolyMesh.fromShapeSet = (shapeSet) => {
  // triangulate each faces
  const nbFaces = shapeSet.loopRangeOffsets.length - 1;
  const faceArray = [0];
  const indicesArray = [];
  let smoothGroups = [];

  let faceArrayOffset = 0;
  let faceBegin = shapeSet.loopRangeOffsets[0];
  for (let fi = 0; fi < nbFaces; ++fi) {
    const faceUntil = shapeSet.loopRangeOffsets[fi + 1];

    // triangulate with holes
    const triangles = earcut(
      shapeSet.positions.data.subarray(faceBegin * 3, faceUntil * 3),
      shapeSet.holeOffsets[fi],
      3
    );

    if (!shapeSet.backFaceOffsets) {
      shapeSet.backFaceOffsets = 0;
    }
    const backFaceBegin = faceBegin + shapeSet.backFaceOffsets;

    // copy the triangles into the new indices array
    const nbTriangles = triangles.length;
    for (let t = 0; t < nbTriangles; t += 3) {
      // front face
      faceArrayOffset += 3;
      faceArray.push(faceArrayOffset);

      indicesArray.push(triangles[t] + faceBegin);
      indicesArray.push(triangles[t + 1] + faceBegin);
      indicesArray.push(triangles[t + 2] + faceBegin);
      smoothGroups.push(SmoothingGroupIds.Front);

      // back face
      faceArrayOffset += 3;
      faceArray.push(faceArrayOffset);

      indicesArray.push(triangles[t] + backFaceBegin);
      indicesArray.push(triangles[t + 2] + backFaceBegin);
      indicesArray.push(triangles[t + 1] + backFaceBegin);
      smoothGroups.push(SmoothingGroupIds.Back);
    }
    faceBegin = faceUntil;
  }

  if (shapeSet.bevelSegments) {
    // handle the bevels
    const nbBevelTriangles = shapeSet.bevelSegments.length;
    for (let bi = 0; bi < nbBevelTriangles; bi += 3) {
      faceArrayOffset += 3;
      faceArray.push(faceArrayOffset);

      indicesArray.push(shapeSet.bevelSegments[bi]);
      indicesArray.push(shapeSet.bevelSegments[bi + 1]);
      indicesArray.push(shapeSet.bevelSegments[bi + 2]);
    }

    // add precalculated smoothing groups for the bevel triangles
    smoothGroups = smoothGroups.concat(shapeSet.bevelSmoothingGroups);
  }

  const faceRangeOffsets = new Uint32Array(faceArray);
  const faceValueIndices = new Uint32Array(indicesArray);

  // create position PolyMap
  const positions = PolyMap.fromData(
    faceRangeOffsets,
    faceValueIndices,
    shapeSet.positions
  );

  const from = {
    faceRangeOffsets,
    positions,
    normalMap: new ObjectsByName(),
    uvMaps: new ObjectsByName(),
    colorMaps: new ObjectsByName(),
  };

  // let resultMesh = cleanPolyMesh(new PolyMesh(from)); // interferes with smoothing groups
  let resultMesh = new PolyMesh(from);
  resultMesh = setNormalsFromSmoothingGroups(resultMesh, smoothGroups);

  return resultMesh;
};
