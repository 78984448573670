import earcut from 'earcut';
import { Vector3 } from 'three';
import calculateFaceConvexity, {
  CONVEXITY,
} from '../operator/calculateFaceConvexity';
import { maxMeshTriangles } from './maxPolymeshTriangles';

const MAX_MATERIAL_IDS = 30;

/**
 * Create the fan triangulation from 0th vertex: [0,1,2, 0,2,3, 0,3,4, ...] and
 * insert it into an array.
 *
 * @param faceSize - number of vertices
 * @param triangulationArray - array mapping faces of a mesh to local triangulation
 * @param offset - start index where array items should be entered
 *
 * @return the number of triangles in the triangulation
 */
function addFanTriangulation(faceSize, triangulationArray, offset) {
  const numTriangles = faceSize - 2;

  for (let ti = 0, wi = offset; ti < numTriangles; ti++, wi += 3) {
    triangulationArray[wi] = 0;
    triangulationArray[wi + 1] = ti + 1;
    triangulationArray[wi + 2] = ti + 2;
  }
  return numTriangles;
}

/**
 * Create a triangulation using earcut and insert it into an array.
 *
 * @param vertices - flat 2d array of vertices: [x0, y0, x1, y1, ...]
 * @param triangulationArray - array mapping faces of a mesh to local triangulation
 * @param offset - start index where array items should be entered
 *
 * @return the number of triangles in the triangulation
 */
function addEarCutTriangulation(vertices, triangulationArray, offset) {
  const triangulation = earcut(vertices);

  for (let ti = 0; ti < triangulation.length; ti++) {
    triangulationArray[offset + ti] = triangulation[ti];
  }
  return triangulation.length / 3;
}

/**
 * Triangulate a polygon, inserting the results into an array.
 *
 * @param positionValues - the vertices of the mesh
 * @param faceValueIndices - the indices of the mesh
 * @param faceBegin - index of the first index used for that face
 * @param faceSize - number of vertices on the face
 * @param triangulationArray - array mapping faces of a mesh to local triangulation
 * @param offset - start index where array items should be entered
 * @param convexity - face type indicating which triangulation method to use
 *
 * @return the number of triangles in the resulting triangulation
 */
function addTriangulation(
  positionValues,
  faceValueIndices,
  faceBegin,
  faceSize,
  triangulationArray,
  offset,
  convexity,
  vec1,
  vec2
) {
  if (convexity === CONVEXITY.FAN_TRIANGULATION) {
    return addFanTriangulation(faceSize, triangulationArray, offset);
  }

  const tempVertex = vec1;
  const flatVertices = new Float32Array(faceSize * 2);
  switch (convexity) {
    case CONVEXITY.PROJ_XY:
      for (let i = 0; i < faceSize; i++) {
        positionValues.getAt(faceValueIndices[faceBegin + i], tempVertex);
        flatVertices[i * 2] = tempVertex.x;
        flatVertices[i * 2 + 1] = tempVertex.y;
      }
      break;
    case CONVEXITY.PROJ_XZ:
      for (let i = 0; i < faceSize; i++) {
        positionValues.getAt(faceValueIndices[faceBegin + i], tempVertex);
        flatVertices[i * 2] = tempVertex.x;
        flatVertices[i * 2 + 1] = tempVertex.z;
      }
      break;
    case CONVEXITY.PROJ_YZ:
      for (let i = 0; i < faceSize; i++) {
        positionValues.getAt(faceValueIndices[faceBegin + i], tempVertex);
        flatVertices[i * 2] = tempVertex.y;
        flatVertices[i * 2 + 1] = tempVertex.z;
      }
      break;
    case CONVEXITY.INVALID_MESH:
    default:
      return 0;
  }

  // Calculate the orientation of the polygon by sweeping around 0th vertex and
  // taking sum of cross products.
  let orientation = 0;
  const currEdge = vec1;
  const prevEdge = vec2;

  currEdge.x = flatVertices[2] - flatVertices[0];
  currEdge.y = flatVertices[3] - flatVertices[1];
  for (let i = 2; i < faceSize; i++) {
    prevEdge.copy(currEdge);
    currEdge.x = flatVertices[i * 2] - flatVertices[0];
    currEdge.y = flatVertices[i * 2 + 1] - flatVertices[1];
    orientation += currEdge.x * prevEdge.y - currEdge.y * prevEdge.x;
  }
  // Flip the polygon if it's clockwise.
  if (orientation > 0) {
    for (let i = 0; i < faceSize; i++) {
      flatVertices[i * 2] *= -1;
    }
  }

  return addEarCutTriangulation(flatVertices, triangulationArray, offset);
}

/**
 * Compute the triangulation for 'self'
 *
 * Create mappings between faces and triangulation; e.g., for two triangles and a pentagon:
 *   mapFaceIdToLocalTriangulation = [0,1,2, 0,1,2, 0,1,2,0,2,3,0,3,4]
 *   mapFaceIdToTriangleOffsets    = [0,     1,     2,                 5] -- multiply by 3 to get
 *                                                                           indices of above array
 *   mapTriangulationFaceId        = [0,     1,     2,    2,    2]
 *
 * @param self - must be instance of 'EarCutTriangulation'
 */
function createMapFaceIdToLocalTriangulation(self) {
  // one per 'faceRangeOffsets' item
  const faceOffsets = self.mesh.faceRangeOffsets;
  const numFaces = faceOffsets.length - 1;
  const faceValueIndices = self.mesh.positions.faceValueIndices;
  const positionValues = self.mesh.positions.values;
  const faceConvexity = self.mesh.faceConvexity;

  // Calculate maximum number of triangles so that we can allocate memory efficiently
  // with typed arrays. Some faces may be rejected later.
  // N.B. Scenes with bad geometry (i.e., with self-intersecting polygons) will have fewer
  // triangles than this because earCut will throw some out. Therefore, after allocating
  // memory, reset #triangles to 0 and count them one by one.

  let numTriangles = maxMeshTriangles(self.mesh).maxNumTriangles;
  const mapFaceIdToLocalTriangulation = new Uint32Array(3 * numTriangles);
  const mapFaceIdToTriangleOffsets = new Uint32Array(numFaces + 1);
  const mapTriangulationFaceId = new Uint32Array(numTriangles);
  numTriangles = 0;

  // Allocate vectors for triangulation calls.
  const vec1 = new Vector3();
  const vec2 = new Vector3();

  // ltOffset points to next available index in mapFaceIdToLocalTriangulation; it's equal
  // to 3 * numTriangles, but update it manually to avoid multiplication
  let faceBegin = faceOffsets[0];
  let ltOffset = 0;
  for (let fi = 0; fi < numFaces; fi++) {
    const faceUntil = faceOffsets[fi + 1];
    const faceSize = faceUntil - faceBegin;

    mapFaceIdToTriangleOffsets[fi + 1] = mapFaceIdToTriangleOffsets[fi];
    if (faceSize > 3) {
      const convexity = faceConvexity[fi];
      const triangulationSize = addTriangulation(
        positionValues,
        faceValueIndices,
        faceBegin,
        faceSize,
        mapFaceIdToLocalTriangulation,
        ltOffset,
        convexity,
        vec1,
        vec2
      );

      for (let i = 0; i < triangulationSize; i++) {
        mapTriangulationFaceId[numTriangles + i] = fi;
      }
      mapFaceIdToTriangleOffsets[fi + 1] += triangulationSize;
      numTriangles += triangulationSize;
      ltOffset += 3 * triangulationSize;
    } else if (faceSize === 3) {
      mapTriangulationFaceId[numTriangles] = fi;
      mapFaceIdToTriangleOffsets[fi + 1]++;
      // Mapping for triangles is always [0, 1, 2].
      mapFaceIdToLocalTriangulation[ltOffset + 0] = 0;
      mapFaceIdToLocalTriangulation[ltOffset + 1] = 1;
      mapFaceIdToLocalTriangulation[ltOffset + 2] = 2;
      numTriangles++;
      ltOffset += 3;
    }

    // Move the face index.
    faceBegin = faceUntil;
  }

  // Update the triangle count and return the mapping.
  self.numTriangles = numTriangles;
  self.mapFaceIdToLocalTriangulation = mapFaceIdToLocalTriangulation;
  self.mapFaceIdToTriangleOffsets = mapFaceIdToTriangleOffsets;
  self.mapTriangulationFaceId = mapTriangulationFaceId;
}

/**
 * Compute the triangulation for 'self'
 * @param self - must be instance of 'EarCutTriangulation'
 */
function computeMaterialIds(self) {
  const materialIds = self.mesh.materialIds;
  if (materialIds) {
    // compute the polygonOrder first
    const faceToTriangleOffsets = self.mapFaceIdToTriangleOffsets;
    const numPolygons = faceToTriangleOffsets.length - 1;
    const polygonOrder = new Uint32Array(numPolygons);
    for (var i = 0; i < polygonOrder.length; i++) {
      polygonOrder[i] = i;
    }

    // sort them so can be rendered in order
    polygonOrder.sort((a, b) => materialIds[a] - materialIds[b]);
    self.polygonOrder = polygonOrder;

    // temporary array used to count how much each ID is used
    const tmpGroupCounts = new Uint32Array(MAX_MATERIAL_IDS);
    let maxMaterialIdFound = 0;

    let fi = 0;
    let faceBegin = faceToTriangleOffsets[0];
    for (let materialId of materialIds) {
      // is the material ID higher than the allowed number?
      if (materialId >= MAX_MATERIAL_IDS) {
        // replace it with the largest value allowed
        materialId = MAX_MATERIAL_IDS - 1;
        maxMaterialIdFound = materialId;
      } else if (materialId > maxMaterialIdFound) {
        // is this the highest material ID found so far?
        maxMaterialIdFound = materialId;
      }

      const faceUntil = faceToTriangleOffsets[fi + 1];
      tmpGroupCounts[materialId] += faceUntil - faceBegin;
      faceBegin = faceUntil;
      ++fi;
    }

    // if not all material indices are used, just create a view instead of
    // reallocating a new buffer
    const groupCounts =
      maxMaterialIdFound + 1 === MAX_MATERIAL_IDS
        ? tmpGroupCounts
        : new Uint32Array(tmpGroupCounts.buffer, 0, maxMaterialIdFound + 1);

    // fill the groupStarts by accumulating the counts
    const groupStarts = new Uint32Array(maxMaterialIdFound + 1);
    let groupOffset = 0;
    for (let i = 0; i <= maxMaterialIdFound; ++i) {
      groupStarts[i] = groupOffset;
      // multiply the group count my three to include coordinates and
      // increment the offset
      groupOffset += groupCounts[i] *= 3;
    }

    self.groups = { groupStarts, groupCounts };
  }
}

export default function EarCutTriangulation(mesh) {
  this.mesh = mesh;

  // for material ids
  this.groups = null;
  this.polygonOrder = null;

  // total number of triangles after the triangulation
  this.numTriangles = -1;

  if (!this.mesh.faceConvexity) {
    this.mesh = calculateFaceConvexity(this.mesh);
  }

  // compute the mapping from current face indices to triangulated
  // face indices and the number of triangles
  createMapFaceIdToLocalTriangulation(this);

  // compute the material ids and the polygon order
  computeMaterialIds(this);
}

EarCutTriangulation.prototype = {
  constructor: EarCutTriangulation,

  translateIndices: function (inputArray, outputView) {
    const faceOffsets = this.mesh.faceRangeOffsets;
    const numFaces = faceOffsets.length - 1;

    const outputArray = outputView.data;
    const stride = outputView.stride;
    let writeIndex = outputView.offset;

    const polygonOrder = this.polygonOrder;

    // create the new indices for this map
    for (let fi = 0; fi < numFaces; ++fi) {
      const polygonIndex = polygonOrder ? polygonOrder[fi] : fi;

      const startIndex = faceOffsets[polygonIndex];

      const startSlice = 3 * this.mapFaceIdToTriangleOffsets[polygonIndex];
      const endSlice = 3 * this.mapFaceIdToTriangleOffsets[polygonIndex + 1];
      // map the indices to the triangulation if it is not degenerate
      if (endSlice > startSlice) {
        const localTriangulation = this.mapFaceIdToLocalTriangulation.slice(
          startSlice,
          endSlice
        );
        for (let ti of localTriangulation) {
          outputArray[writeIndex] = inputArray[startIndex + ti];
          writeIndex += stride;
        }
      }
    }
  },

  translateMaterialIds: function (outputView) {
    // NOT USED and cannot trust version from SimpleFanTriangulation because
    // it has wrong arguments
  },

  translateToUnindexedValues: function (inputView, outputView, indices) {
    const faceOffsets = this.mesh.faceRangeOffsets;
    const numFaces = faceOffsets.length - 1;
    const temp = inputView.newRangeArray(1);

    const polygonOrder = this.polygonOrder;

    let writeIndex = 0;

    // create the new indices for this map
    for (let fi = 0; fi < numFaces; ++fi) {
      const polygonIndex = polygonOrder ? polygonOrder[fi] : fi;

      const startIndex = faceOffsets[polygonIndex];

      const startTriangle = 3 * this.mapFaceIdToTriangleOffsets[polygonIndex];
      const endTriangle = 3 * this.mapFaceIdToTriangleOffsets[polygonIndex + 1];
      // map the indices to the triangulation if it is not degenerate
      if (endTriangle > startTriangle) {
        const localTriangulation = this.mapFaceIdToLocalTriangulation.slice(
          startTriangle,
          endTriangle
        );
        for (let ti of localTriangulation) {
          outputView.arrayToRange(
            inputView.rangeToArray(indices[startIndex + ti], 1, temp),
            writeIndex,
            1
          );
          ++writeIndex;
        }
      }
    }
  },
};
