import { Comparator, ThreekitSource } from '../types';
import qs from 'query-string';

export async function fetchOrg(source: ThreekitSource, idOrSlug: string) {
  const usersApiRoot = source.apiRoot('users');
  const url = `${source.apiRoot('users')}/orgs/${idOrSlug}`;
  const result = await source.httpClient.get(url);
  return result.status === 200 ? result.data : undefined;
}

export async function fetchOrgs(source: ThreekitSource) {
  const usersApiRoot = source.apiRoot('users');
  const url = `${source.apiRoot('users')}/orgs`;
  const result = await source.httpClient.get(url);
  return result.data;
}

export async function fetchOrgFromToken(source: ThreekitSource, token: string) {
  const { apiRoot, httpClient } = source;
  const tokenUrl = `${apiRoot('accesstokens')}/accesstokens/${token}`;
  const tokenResult = await httpClient.get(tokenUrl);
  if (!tokenResult || tokenResult.status !== 200) return false;

  const orgId = tokenResult.data.orgId;
  return fetchOrg(source, orgId);
}
