import { OperatorSchema } from '../types';
import { OperatorOperon, makeAllOperators } from '../cas/Operon';

import Image from './Image';
import Material from './Material';
import Null from './Null';
import PolyMesh from './PolyMesh';
import Transform from './Transform';
import Proxy from './Proxy';
import Properties from './Properties';

const commonSchema: OperatorSchema = {
  name: { type: 'String', label: 'Name', hidden: true },
  active: {
    type: 'Boolean',
    label: 'Active',
    defaultValue: true,
    hidden: true,
    configurable: true,
  },
};

const operators: {
  [plugName: string]: { [opName: string]: OperatorOperon };
} = makeAllOperators(
  {
    Image,
    Material,
    Null,
    PolyMesh,
    Properties,
    Proxy,
    Transform,
  },
  { withNone: true, commonSchema }
);

export default operators;
