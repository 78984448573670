import { OperatorSchema } from '../../types';
import {
  LinearFilter,
  LinearMipMapLinearFilter,
  LinearMipMapNearestFilter,
  NearestFilter,
  NearestMipMapLinearFilter,
  NearestMipMapNearestFilter,
  Vector2,
} from 'three';
// FIXME: is this not exported from three?
const DEG2RAD = 0.017453292519943295;

import { KeyedOperator, ToOptions } from '../utils';
import { ImagePlug } from './ImagePlug';

import { ScaleMode, WrapType } from '../constants';

const sampleFilter = ToOptions({
  ['Nearest']: NearestFilter,
  ['Nearest MipMap Nearest']: NearestMipMapNearestFilter,
  ['Nearest MipMap Linear']: NearestMipMapLinearFilter,
  ['Linear']: LinearFilter,
  ['Linear MipMap Nearest']: LinearMipMapNearestFilter,
  ['Linear MipMap Linear']: LinearMipMapLinearFilter,
});

const wrapSchema = ToOptions({
  None: WrapType.NONE,
  Wrap: WrapType.WRAP,
  Mirror: WrapType.MIRROR,
});

const uvTransformSchema: OperatorSchema = {
  uOffset: {
    type: 'Number',
    label: 'U Offset',
    defaultValue: 0,
    step: 0.1,
    animatable: true,
  },
  vOffset: {
    type: 'Number',
    label: 'V Offset',
    defaultValue: 0,
    step: 0.1,
    animatable: true,
  },
  uTile: {
    type: 'Number',
    label: 'U Tile',
    defaultValue: 1,
    step: 0.1,
    animatable: true,
  },
  vTile: {
    type: 'Number',
    label: 'V Tile',
    defaultValue: 1,
    step: 0.1,
    animatable: true,
  },
  scaleMode: {
    label: 'Scale Mode',
    type: 'Options',
    defaultValue: ScaleMode.Tiling,
    values: [ScaleMode.Scale, ScaleMode.Tiling],
    labels: ['Scale', 'Tiling'],
  },
  uScale: {
    type: 'Number',
    label: 'U Scale',
    defaultValue: 1,
    step: 0.1,
    animatable: true,
  },
  vScale: {
    type: 'Number',
    label: 'V Scale',
    defaultValue: 1,
    step: 0.1,
    animatable: true,
  },
  rotation: {
    type: 'Number',
    label: 'Rotation',
    defaultValue: 0,
    min: 0,
    max: 360,
    step: 0.1,
    animatable: true,
  },
};

function updateUvTransform(
  operator: KeyedOperator<typeof uvTransformSchema>,
  primitive: ImagePlug
) {
  const { uOffset, vOffset, uScale, vScale, scaleMode } = operator;

  let { uTile, vTile, rotation } = operator;

  const uvOffset = new Vector2(uOffset, vOffset);

  if (scaleMode === ScaleMode.Scale) {
    uTile = 1 / uScale;
    vTile = 1 / vScale;
  }
  const uvTile = new Vector2(uTile, vTile);

  rotation *= DEG2RAD;

  primitive.uvTransform = {
    uvOffset,
    uvTile,
    rotation,
  };
}

const texelTransformSchema: OperatorSchema = {
  invert: {
    type: 'Boolean',
    label: 'Invert',
    defaultValue: false,
    animatable: true,
  },
  gainPivot: {
    type: 'Number',
    label: 'Gain Pivot',
    defaultValue: 0,
    step: 0.1,
    animatable: true,
  },
  gain: {
    type: 'Number',
    label: 'Gain',
    defaultValue: 1.0,
    step: 0.1,
    animatable: true,
  },
  brightness: {
    type: 'Number',
    label: 'Brightness',
    defaultValue: 0,
    step: 0.1,
    animatable: true,
  },
};

function updateTexelTransform(
  operator: KeyedOperator<typeof texelTransformSchema>,
  primitive: ImagePlug
) {
  const { gain, gainPivot, brightness, invert } = operator;

  primitive.texelTransform = {
    gain,
    gainPivot,
    brightness,
    invert,
  };
}

const flowTransformSchema: OperatorSchema = {
  redGreenLabel: {
    type: 'Label',
    defaultValue: 'Red/Green controls are for anisotropy and normal maps only.',
  },

  redFlip: {
    label: 'Red Channel Flip',
    type: 'Boolean',
    defaultValue: false,
  },
  greenFlip: {
    label: 'Green Channel Flip',
    type: 'Boolean',
    defaultValue: false,
  },

  swapRG: {
    label: 'Swap Red/Green Channels',
    type: 'Boolean',
    defaultValue: false,
  },
};

function updateFlowTransform(
  operator: KeyedOperator<typeof flowTransformSchema>,
  primitive: ImagePlug
) {
  const { redFlip, greenFlip, swapRG } = operator;

  primitive.flowTransform = {
    redFlip,
    greenFlip,
    swapRG,
  };
}
const webGlSettingsSchema: OperatorSchema = {
  magFilter: {
    type: 'Options',
    defaultValue: LinearFilter,
    options: sampleFilter,
    label: 'Magnify Filter',
  },
  minFilter: {
    type: 'Options',
    defaultValue: LinearMipMapLinearFilter,
    options: sampleFilter,
    label: 'Minify Filter',
  },
  generateMipMaps: {
    label: 'Use MipMaps',
    type: 'Boolean',
    defaultValue: true,
  },
  wrapU: {
    type: 'Options',
    defaultValue: WrapType.WRAP,
    options: wrapSchema,
    label: 'U Wrap Style',
  },
  wrapV: {
    type: 'Options',
    defaultValue: WrapType.WRAP,
    options: wrapSchema,
    label: 'V Wrap Style',
  },
  // hdrEncoding: {
  //   type: 'Options',
  //   options: ToOptions({
  //     ['Linear']: LinearEncoding,
  //     ['sRGB']: sRGBEncoding,
  //     ['RGBE/Radiance']: RGBEEncoding,
  //     ['Log LUV']: LogLuvEncoding,
  //     ['RGBM 7']: RGBM7Encoding,
  //     ['RGBM 16']: RGBM16Encoding,
  //   }),
  //   defaultValue: RGBM16Encoding,
  //   label: 'HDR Encoding',
  // },
};

function updateWebGlSettings(
  operator: KeyedOperator<typeof webGlSettingsSchema>,
  primitive: ImagePlug
) {
  const {
    magFilter,
    minFilter,
    generateMipMaps,
    wrapU,
    wrapV,
    // hdrEncoding
  } = operator;

  primitive.webglSettings = {
    magFilter,
    minFilter,
    generateMipMaps,
    wrapU,
    wrapV,
    // hdrEncoding
  };
}

export const imageBaseSchema: OperatorSchema = {
  ...uvTransformSchema,
  ...texelTransformSchema,
  ...flowTransformSchema,
  ...webGlSettingsSchema,

  // Needed for all the 'Image' canvases that have been created on the platform
  //   Eventually we can mark these properties as 'Hidden'
  width: {
    type: 'Number',
    defaultValue: 512,
    min: 16,
    max: 4096,
    step: 1,
    label: 'Canvas Width (old)',
  },
  height: {
    type: 'Number',
    defaultValue: 512,
    min: 16,
    max: 4096,
    step: 1,
    label: 'Canvas Height (old)',
  },
};

export function imageBaseUpdate(
  operator: KeyedOperator<typeof imageBaseSchema>,
  primitive: ImagePlug
) {
  updateUvTransform(operator, primitive);
  updateTexelTransform(operator, primitive);
  updateFlowTransform(operator, primitive);
  updateWebGlSettings(operator, primitive);

  primitive.width = operator.width;
  primitive.height = operator.height;
}
