// @ts-nocheck
import PolyMesh from './geometric/model/PolyMesh';
import PolyMaps from './geometric/model/PolyMaps';
import Exporter from './geometric/tool/Exporter';
import {
  Color,
  BufferGeometry,
  BufferAttribute,
  InterleavedBuffer,
  InterleavedBufferAttribute,
} from 'three';

export enum IndicesMode {
  NoIndices = 0,
  SimpleIndices = 1,
  CompactIndices = 2,
}

interface Spec {
  mapId: number;
  threeName: string;
  itemSize: number;
  normalized: boolean;
}

export interface MeshBufferInfo {
  geometry: BufferGeometry;
  triangulation: any;
  renderIndexing: any;
  triangulationChanged: any;
  valueMap: any;
}

export const polyMeshToMeshBufferGeometry = (
  polyMesh: any,
  indicesMode: IndicesMode,
  interleaved: boolean,
  exportTangents: boolean,
  oldMesh: any,
  prevTriangulation: any,
  prevIndexing: any,
  prevValueMap: any,
  bufferGeometry?: BufferGeometry
): MeshBufferInfo => {
  const result = bufferGeometry || new BufferGeometry();
  const exportSpec: Spec[] = [];
  const { exporter, triangulationChanged, reIndex } = _prepare(
    polyMesh,
    exportSpec,
    indicesMode,
    interleaved,
    exportTangents,
    oldMesh,
    prevTriangulation,
    prevIndexing
  );

  const IdPositions = PolyMaps.IdPositions;
  const valueMap: { [mapId: number]: any } = {};

  if (indicesMode !== IndicesMode.NoIndices) {
    // @ts-ignore
    const indices =
      indicesMode === IndicesMode.SimpleIndices
        ? // @ts-ignore
          exporter.getTriangulatingIndices()
        : // @ts-ignore
          exporter.getMapIndices(IdPositions);

    result.setIndex(new BufferAttribute(indices, 1, false));
  }

  if (!interleaved) {
    if (reIndex) prevValueMap = null;

    for (let i = 0; i !== exportSpec.length; ++i) {
      const attrSpec = exportSpec[i];
      const triangulateValues = indicesMode === IndicesMode.NoIndices;

      let data;
      const newMap = PolyMaps.resolveMap(polyMesh, '' + attrSpec.mapId);
      const oldMap =
        oldMesh && PolyMaps.resolveMap(oldMesh, '' + attrSpec.mapId);

      if (oldMap !== newMap || !prevValueMap) {
        data =
          indicesMode !== IndicesMode.CompactIndices
            ? exporter.getUnindexedMapValues(attrSpec.mapId, triangulateValues)
            : exporter.getMapValues(attrSpec.mapId);
      } else {
        data = prevValueMap[attrSpec.mapId];
      }

      valueMap[attrSpec.mapId] = data;
      const attr = new BufferAttribute(
        data,
        attrSpec.itemSize,
        attrSpec.normalized
      );
      result.setAttribute(attrSpec.threeName, attr);
    }
  } else {
    let stride = 0;
    for (let i = 0; i !== exportSpec.length; ++i)
      stride += exportSpec[i].itemSize;

    let nValuess = 0;
    switch (indicesMode) {
      case IndicesMode.NoIndices: // no. of triangulated vertices
        nValuess = exporter.getNumFaceVertices();
        break;

      case IndicesMode.SimpleIndices: // no. of values
        nValuess = exporter.mesh.getNumFaceVertices();
        break;

      case IndicesMode.CompactIndices: // as calculated
        nValuess = exporter.getNumMapValues(PolyMaps.IdPositions);
    }

    const data = new Float32Array(stride * nValuess);
    const buffer = new InterleavedBuffer(data, stride);
    let offset = 0;

    for (let i = 0; i !== exportSpec.length; ++i) {
      const attrSpec = exportSpec[i];
      const itemSize = attrSpec.itemSize;
      const mapId = attrSpec.mapId;

      if (indicesMode !== IndicesMode.CompactIndices) {
        exporter.getUnindexedMapValues(
          mapId,
          indicesMode === IndicesMode.NoIndices,
          data,
          offset,
          stride
        );
      } else {
        exporter.getMapValues(mapId, data, offset, stride);
      }

      result.setAttribute(
        attrSpec.threeName,
        new InterleavedBufferAttribute(buffer, itemSize, offset)
      );

      offset += itemSize;
    }
  }
  // add group to buffer geometry if there is materialId list in polymesh
  const groups = exporter.getGroups();
  if (polyMesh.materialIds && groups) {
    for (let i = 0; i < groups.groupCounts.length; i++) {
      if (groups.groupCounts[i] > 0) {
        result.addGroup(groups.groupStarts[i], groups.groupCounts[i], i);
      }
    }
  } else {
    // Since ThreeJS does not render Multimaterial geometry if it has no material id's, create
    // default group for all mesh positions to use material at index 0
    result.addGroup(0, exporter.triangulation.numTriangles * 3, 0);
  }

  polyMesh.cachedNumThreeVertices = exporter.getNumFaceVertices();
  polyMesh.cachedNumThreeTriangles = exporter.triangulation.numTriangles;

  return {
    geometry: result,
    triangulation: exporter.triangulation,
    renderIndexing: exporter.renderIndexing,
    triangulationChanged,
    valueMap,
  };
};

export const polyMeshToLineSegmentsBufferGeometry = (
  polyMesh: PolyMesh,
  selectedIndices: number[],
  hoverIndex: number,
  regularColor: Color,
  selectedColor: Color,
  hoverColor: Color
) => {
  {
    const exporter = new Exporter(polyMesh);

    const result = exporter.getEdgeIndicesMapValues();
    const lineBuffer = new BufferGeometry();

    const posAttr = new BufferAttribute(
      result.data,
      result.elementSize,
      result.length
    );
    lineBuffer.setAttribute('position', posAttr);

    const colors = new Float32Array(result.data.length);
    for (let i = 0; i < colors.length; i += 3) {
      colors[i] = regularColor.r;
      colors[i + 1] = regularColor.g;
      colors[i + 2] = regularColor.b;
    }

    for (const i of selectedIndices) {
      const vertIndex = selectedIndices[i] * 2; // map edge index to position index
      colors[vertIndex * 3] = selectedColor.r;
      colors[vertIndex * 3 + 1] = selectedColor.g;
      colors[vertIndex * 3 + 2] = selectedColor.b;
      colors[vertIndex * 3 + 3] = selectedColor.r;
      colors[vertIndex * 3 + 4] = selectedColor.b;
      colors[vertIndex * 3 + 5] = selectedColor.g;
    }
    if (hoverIndex >= 0 && hoverColor) {
      const vertIndex = hoverIndex * 2; // map edge index to position index
      colors[vertIndex * 3] = hoverColor.r;
      colors[vertIndex * 3 + 1] = hoverColor.g;
      colors[vertIndex * 3 + 2] = hoverColor.b;
      colors[vertIndex * 3 + 3] = hoverColor.r;
      colors[vertIndex * 3 + 4] = hoverColor.g;
      colors[vertIndex * 3 + 5] = hoverColor.b;
    }
    const colAttr = new BufferAttribute(colors, 3);
    lineBuffer.setAttribute('color', colAttr);
    lineBuffer.addGroup(0, result.length, 0);
    return lineBuffer;
  }
};

function _prepare(
  polyMesh: any,
  outSpec: Spec[],
  indicesMode: IndicesMode,
  interleaved: boolean,
  exportTangents: boolean,
  oldMesh: any,
  prevTriangulation: any,
  prevIndexing: any
) {
  const exporter = new Exporter(polyMesh);
  const mapsToExport = [];

  const retriangulate =
    !oldMesh ||
    !prevTriangulation ||
    oldMesh.positions.faceValueIndices !== polyMesh.positions.faceValueIndices;
  let reIndex = !oldMesh || !prevIndexing;

  let mapOffset = 0;
  for (const iter = new PolyMaps.Iterator(polyMesh); iter.next(); ) {
    let threeName = '';
    let itemSize = 3;
    let normalized = false;

    switch (iter.typeId) {
      case PolyMaps.TypePosition:
        threeName = 'position';
        break;

      case PolyMaps.TypeTangent:
        // Note: Since tangents are normally ignored by the three.js
        // renderer, they must be requested explicitly.
        if (!exportTangents) break;
        itemSize = 4;
        threeName = 'tangent';
        break;

      case PolyMaps.TypeNormal:
        threeName = 'normal';
        break;

      case PolyMaps.TypeUV:
        // if (iter.index >= 2) break; // max two UV maps
        threeName = 'uv' + (iter.index !== 0 ? iter.index + 1 : '');
        itemSize = 2;
        break;

      case PolyMaps.TypeColor:
        if (iter.index >= 1) break; // use first color map
        threeName = 'color';
        normalized = true;
    }

    if (!threeName || (interleaved && normalized)) continue;

    const mapId = iter.getId();
    mapsToExport.push(mapId);

    outSpec.push({
      mapId,
      threeName,
      itemSize,
      normalized,
    });
    ++mapOffset;
  }

  for (let i = 0; i < outSpec.length && !reIndex; i++) {
    const oldMap = PolyMaps.resolveMap(oldMesh, outSpec[i].mapId);
    const newMap = PolyMaps.resolveMap(polyMesh, outSpec[i].mapId);
    if (!oldMap || oldMap.faceValueIndices !== newMap.faceValueIndices) {
      reIndex = true;
    }
  }

  // console.log(polyMesh===(prevData && prevData.mesh));
  if (indicesMode === IndicesMode.CompactIndices) {
    if (reIndex) {
      exporter.reindexForRendering(mapsToExport);
    } else {
      prevIndexing.mesh = polyMesh;
      exporter.renderIndexing = prevIndexing;
    }
  }

  let triangulationChanged = false;
  if (retriangulate) {
    exporter.triangulate();
    triangulationChanged = true;
  } else {
    exporter.triangulation = prevTriangulation;
  }

  return { exporter, triangulationChanged, reIndex };
}
