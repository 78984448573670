const _ByteBitCount = (function () {
  const lut = new Uint8Array(256);

  for (let i = 0; i !== 256; ++i) {
    let value = 0;
    for (let m = i; m !== 0; m >>>= 1) if ((m & 1) !== 0) ++value;

    lut[i] = value;
  }

  return lut;
})();

export default class BitSet {
  private data: Uint32Array;
  private byteView: null | Uint8Array;

  constructor(private length: number) {
    this.data = new Uint32Array((length + 31) >>> 5);
    this.byteView = null;
  }

  public clear() {
    this.data.fill(0);
    return this;
  }

  public fill(begin = 0, length?: number) {
    const data = this.data;

    const until = length !== undefined ? begin + length : this.length;

    let wordIndexBegin = begin >>> 5;
    const wordIndexUntil = until >>> 5;

    // Smear the highbit with a signed shift for a pattern of MSBs:
    const maskBegin = -0x80000000 >> ~begin;

    // Power of two minus one yields a pattern of LSBs:
    const maskUntil = (1 << until) - 1;

    if (wordIndexBegin === wordIndexUntil)
      data[wordIndexBegin] |= maskBegin & maskUntil;
    else {
      data[wordIndexBegin] |= maskBegin;

      if (++wordIndexBegin !== wordIndexUntil)
        data.fill(~0, wordIndexBegin, wordIndexUntil);

      data[wordIndexUntil] |= maskUntil;
    }

    return this;
  }

  public include(i: number) {
    this.data[i >>> 5] |= 1 << i;
  }

  public exclude(i: number) {
    this.data[i >>> 5] &= ~(1 << i);
  }

  public contains(i: number) {
    return (this.data[i >>> 5] & (1 << i)) !== 0;
  }

  public swap(i: number, j: number) {
    const data = this.data;

    const wordIndexI = i >>> 5,
      maskI = 1 << i;
    const wordIndexJ = j >>> 5,
      maskJ = 1 << j;

    const stateI = (data[wordIndexI] & maskI) !== 0;
    const stateJ = (data[wordIndexJ] & maskJ) !== 0;

    if (stateI !== stateJ) {
      if (stateI) {
        data[wordIndexI] &= ~maskI;
        data[wordIndexJ] |= maskJ;
      } else {
        data[wordIndexI] |= maskI;
        data[wordIndexJ] &= ~maskJ;
      }
    }
  }

  public testAndInclude(i: number) {
    const data = this.data;

    const wordIndex = i >>> 5,
      mask = 1 << i;

    const unchangedWord = data[wordIndex];

    data[wordIndex] = unchangedWord | mask;
    return (unchangedWord & mask) !== 0;
  }

  public includeFromArray(
    array: ArrayLike<number>,
    offset = 0,
    until = array.length
  ) {
    const data = this.data;

    for (let j = offset, e = until; j < e; ++j) {
      const i = array[j];

      data[i >>> 5] |= 1 << i;
    }

    return this;
  }

  public containsAnyIn(
    array: ArrayLike<number>,
    offset = 0,
    until = array.length
  ) {
    let result = false;
    const data = this.data;

    for (let j = offset, e = until; j < e && !result; ++j) {
      const i = array[j];

      result = (data[i >>> 5] & (1 << i)) !== 0;
    }

    return result;
  }

  public countSetBits(begin = 0, until = this.length) {
    let result = 0;

    if (((until - begin) & -0x4000) === 0)
      // that is, 'begin' is less than 'until' and fewer than 2048 bytes

      result = this.countSetBitsInShortRange(begin, until);
    else if (begin < until) {
      // use a LUT - it's several times faster at scale

      let data = this.byteView;
      const lut = _ByteBitCount;

      if (data === null) {
        data = new Uint8Array(this.data.buffer);
        this.byteView = data;
      }

      const wordIndexBegin = begin >>> 3;
      const wordIndexUntil = until >>> 3;

      // Smear the highbit with a signed shift for a pattern of MSBs:
      const maskBegin = -0x80000000 >> (31 - (begin & 7));
      // Power of two minus one yields a pattern of LSBs:
      const maskUntil = (1 << (until & 7)) - 1;

      result = lut[data[wordIndexBegin] & maskBegin];

      for (let j = wordIndexBegin + 1; j !== wordIndexUntil; ++j)
        result += lut[data[j]];

      result += lut[data[wordIndexUntil] & maskUntil];
    }

    return result;
  }

  // FIXME: private this
  public countSetBitsInShortRange(begin: number, until: number) {
    // Note: For some odd reason the data word must be signed to keep
    // V8 from deoptimizing - all unsigned access won't do.

    let result = 0;

    const data = this.data;

    let here = begin;
    let index = here >>> 5;

    let word = data[index] >> here;

    result = word & 1;

    while (++here !== until) {
      if ((here & 31) !== 0) word >>= 1;
      else word = data[++index] | 0; // cast to signed (important)

      result += word & 1;
    }

    return result;
  }

  public toIndexArray(knownLength = this.countSetBits()) {
    const result = new Uint32Array(knownLength);
    let baseIndex = 0;
    let writeOffset = 0;
    const data = this.data;

    for (let j = 0, n = data.length; j !== n; ++j) {
      for (let mask = data[j], i = baseIndex; mask !== 0; mask >>>= 1, ++i)
        if ((mask & 1) !== 0) result[writeOffset++] = i;

      baseIndex += 32;
    }

    return result;
  }
}
