import {
  Euler,
  MathUtils as ThreeMath,
  Matrix4,
  Quaternion,
  Vector3,
} from 'three';

/**
 * Get the local transform for a node.
 *
 * @returns {?Matrix4}
 */

//

const tmpMatrix4 = new Matrix4();
const tmpVector3 = new Vector3();

//

export interface TransformOperator {
  rotateOrder: string;

  // transform stack:
  translation: Vector3;
  rotatePivotOffset: Vector3;
  localRotatePivot: Vector3;
  preRotation: Vector3;
  rotation: Vector3;
  // localRotatePivot ^ -1,
  scalePivotOffset: Vector3;
  localScalePivot: Vector3;
  shear: Vector3;
  scale: Vector3;
  // localScalePivot ^ -1,
}

export function rotationFromQuat(
  quat: Quaternion,
  rotateOrder: string,
  optionalTarget?: Euler
) {
  return (optionalTarget || new Euler())
    .setFromQuaternion(quat, rotateOrder)
    .toVector3()
    .multiplyScalar(ThreeMath.RAD2DEG);
}

export function getPreRotation(
  transform: TransformOperator,
  optionalTarget?: Euler
) {
  const { preRotation, rotateOrder } = transform;
  const preRotationRadians = tmpVector3
    .copy(preRotation)
    .multiplyScalar(ThreeMath.DEG2RAD);

  return (optionalTarget || new Euler()).setFromVector3(
    preRotationRadians,
    rotateOrder
  );
}

export function getRotatePivotOffset(
  transform: TransformOperator,
  optionalTarget?: Vector3
) {
  return (optionalTarget || new Vector3()).copy(transform.rotatePivotOffset);
}

export function getScalePivotOffset(
  transform: TransformOperator,
  optionalTarget?: Vector3
) {
  return (optionalTarget || new Vector3()).copy(transform.scalePivotOffset);
}

export function getLocalRotatePivot(
  transform: TransformOperator,
  optionalTarget?: Vector3
) {
  return (optionalTarget || new Vector3()).copy(transform.localRotatePivot);
}

function getMatrixTranslation(
  transform: TransformOperator,
  optionalTarget?: Matrix4
) {
  const { translation } = transform;
  return (optionalTarget || new Matrix4()).makeTranslation(
    translation.x,
    translation.y,
    translation.z
  );
}

function getMatrixRotatePivotOffset(
  transform: TransformOperator,
  optionalTarget?: Matrix4
) {
  const { rotatePivotOffset } = transform;
  return (optionalTarget || new Matrix4()).makeTranslation(
    rotatePivotOffset.x,
    rotatePivotOffset.y,
    rotatePivotOffset.z
  );
}

function getMatrixLocalRotatePivot(
  transform: TransformOperator,
  optionalTarget?: Matrix4
) {
  const { localRotatePivot } = transform;
  return (optionalTarget || new Matrix4()).makeTranslation(
    localRotatePivot.x,
    localRotatePivot.y,
    localRotatePivot.z
  );
}

function getMatrixPreRotation(
  transform: TransformOperator,
  optionalTarget?: Matrix4
) {
  const { preRotation, rotateOrder } = transform;
  const localRotationRadians = tmpVector3
    .copy(preRotation)
    .multiplyScalar(ThreeMath.DEG2RAD);
  return (optionalTarget || new Matrix4()).makeRotationFromEuler(
    getPreRotation(transform)
  );
}

function getMatrixRotation(
  transform: TransformOperator,
  optionalTarget?: Matrix4
) {
  const { rotation, rotateOrder } = transform;
  const localRotationRadians = tmpVector3
    .copy(rotation)
    .multiplyScalar(ThreeMath.DEG2RAD);
  return (optionalTarget || new Matrix4()).makeRotationFromEuler(
    new Euler(
      localRotationRadians.x,
      localRotationRadians.y,
      localRotationRadians.z,
      rotateOrder
    )
  );
}

function getMatrixInvLocalRotatePivot(
  transform: TransformOperator,
  optionalTarget?: Matrix4
) {
  return (optionalTarget || new Matrix4())
    .copy(getMatrixLocalRotatePivot(transform, tmpMatrix4))
    .invert();
}

function getMatrixScalePivotOffset(
  transform: TransformOperator,
  optionalTarget?: Matrix4
) {
  const { scalePivotOffset } = transform;
  return (optionalTarget || new Matrix4()).makeTranslation(
    scalePivotOffset.x,
    scalePivotOffset.y,
    scalePivotOffset.z
  );
}

function getMatrixLocalScalePivot(
  transform: TransformOperator,
  optionalTarget?: Matrix4
) {
  const { localScalePivot } = transform;
  return (optionalTarget || new Matrix4()).makeTranslation(
    localScalePivot.x,
    localScalePivot.y,
    localScalePivot.z
  );
}

function getMatrixShear(
  transform: TransformOperator,
  optionalTarget?: Matrix4
) {
  const { shear } = transform;
  return (optionalTarget || new Matrix4()).set(
    1,
    shear.x,
    shear.y,
    0,
    0,
    1,
    shear.z,
    0,
    0,
    0,
    1,
    0,
    0,
    0,
    0,
    1
  ); // .makeShear(new Vector3(0, 0, 0));
}

function getMatrixScale(
  transform: TransformOperator,
  optionalTarget?: Matrix4
) {
  const { scale } = transform;
  return (optionalTarget || new Matrix4()).makeScale(scale.x, scale.y, scale.z);
}

function getMatrixInvLocalScalePivot(
  transform: TransformOperator,
  optionalTarget?: Matrix4
) {
  return (optionalTarget || new Matrix4())
    .copy(getMatrixLocalScalePivot(transform, tmpMatrix4))
    .invert();
}

export const getMatrix = {
  translation: getMatrixTranslation,

  rotatePivotOffset: getMatrixRotatePivotOffset,
  localRotatePivot: getMatrixLocalRotatePivot,
  preRotation: getMatrixPreRotation,
  rotation: getMatrixRotation,
  invLocalRotatePivot: getMatrixInvLocalRotatePivot,

  scalePivotOffset: getMatrixScalePivotOffset,
  localScalePivot: getMatrixLocalScalePivot,
  shear: getMatrixShear,
  scale: getMatrixScale,
  invLocalScalePivot: getMatrixInvLocalScalePivot,
};

export function getLocalTransform(transform: TransformOperator) {
  const {
    rotateOrder,
    translation,
    rotatePivotOffset,
    localRotatePivot,
    preRotation,
    rotation,
    // localRotatePivot ^ -1,
    scalePivotOffset,
    localScalePivot,
    shear,
    scale,
    // localScalePivot ^ -1,
  } = transform;
  if (scale.x === 0) scale.x = 0.0000001;
  if (scale.y === 0) scale.y = 0.0000001;
  if (scale.z === 0) scale.z = 0.0000001;
  const zero = new Vector3(0, 0, 0);

  const matrixAccumulation = new Matrix4();

  matrixAccumulation.multiply(getMatrixTranslation(transform, tmpMatrix4));

  if (!zero.equals(rotatePivotOffset)) {
    matrixAccumulation.multiply(
      getMatrixRotatePivotOffset(transform, tmpMatrix4)
    );
  }

  const localRotatePivotM = new Matrix4();
  const invLocalRotatePivotM = new Matrix4();
  let localRotatePivotFlag = false;
  if (!zero.equals(localRotatePivot)) {
    getMatrixLocalRotatePivot(transform, localRotatePivotM);
    getMatrixInvLocalRotatePivot(transform, invLocalRotatePivotM);
    matrixAccumulation.multiply(localRotatePivotM);
    localRotatePivotFlag = true;
  }

  if (!zero.equals(preRotation)) {
    matrixAccumulation.multiply(getMatrixPreRotation(transform, tmpMatrix4));
  }

  matrixAccumulation.multiply(getMatrixRotation(transform, tmpMatrix4));

  if (localRotatePivotFlag) matrixAccumulation.multiply(invLocalRotatePivotM);

  // const scalePivotOffsetM = new Matrix4();
  if (!zero.equals(scalePivotOffset)) {
    matrixAccumulation.multiply(
      getMatrixScalePivotOffset(transform, tmpMatrix4)
    );
  }

  const localScalePivotM = new Matrix4();
  const invLocalScalePivotM = new Matrix4();
  let localScalePivotFlag = false;
  if (!zero.equals(localScalePivot)) {
    getMatrixLocalScalePivot(transform, localScalePivotM);
    matrixAccumulation.multiply(localScalePivotM);
    getMatrixInvLocalScalePivot(transform, invLocalScalePivotM);
    localScalePivotFlag = true;
  }

  matrixAccumulation.multiply(getMatrixShear(transform, tmpMatrix4));

  matrixAccumulation.multiply(getMatrixScale(transform, tmpMatrix4));

  if (localScalePivotFlag) matrixAccumulation.multiply(invLocalScalePivotM);

  return matrixAccumulation;
}

export function getAxis(axisType: number, flipAxis: boolean) {
  if (axisType === 1) {
    return new Vector3(flipAxis ? -1 : 1, 0, 0);
  } else if (axisType === 2) {
    return new Vector3(0, flipAxis ? -1 : 1, 0);
  } else {
    // For Z and unknown axisType
    return new Vector3(0, 0, flipAxis ? -1 : 1);
  }
}

export function getQuaternionAxis(axisType: number, flipAxis: boolean) {
  const quat = new Quaternion();

  if (axisType === 1) {
    quat.setFromAxisAngle(
      new Vector3(0, 1, 0),
      flipAxis ? -Math.PI / 2 : Math.PI / 2
    );
  } else if (axisType === 2) {
    quat.setFromAxisAngle(
      new Vector3(1, 0, 0),
      flipAxis ? Math.PI / 2 : -Math.PI / 2
    );
  } else if (axisType === 3) {
    quat.setFromAxisAngle(new Vector3(0, 1, 0), flipAxis ? 0 : Math.PI);
  }

  return quat;
}
