import Arrays from '../../generic/utility/Arrays';
import PolyMesh from '../model/PolyMesh';
import PolyMaps from '../model/PolyMaps';
import PolyMap from '../model/PolyMap';
import BitSet from '../../generic/container/BitSet';
import ScalarBuffer from '../../generic/container/ScalarBuffer';
import { NumberArray } from '../../generic/utility/ArrayTypes';
import { ComponentType } from '../../../operators/constants';

export type SelectionConverter = (
  mesh: PolyMesh,
  indices: NumberArray,
  mapIdIn?: string,
  mapIdOut?: string
) => NumberArray;

export const DefaultMap = PolyMaps.IdPositions;

export const convFacesToVertices: SelectionConverter = (
  mesh,
  indices,
  _ = DefaultMap,
  mapId = DefaultMap
) => {
  var mapOut = PolyMaps.resolveMap(mesh, mapId),
    result = new BitSet(mapOut.values.length),
    faceRangeOffsets = mesh.faceRangeOffsets,
    mapValueIndices = mapOut.faceValueIndices;

  for (var i = 0, n = indices.length; i !== n; ++i) {
    var faceIndex = indices[i],
      begin = faceRangeOffsets[faceIndex],
      until = faceRangeOffsets[faceIndex + 1];

    result.includeFromArray(mapValueIndices, begin, until);
  }

  return result.toIndexArray();
};

export const convFacesToEdges: SelectionConverter = (
  mesh,
  indices,
  _ = DefaultMap,
  mapId = DefaultMap
) => {
  var mapOut = PolyMaps.resolveMap(mesh, mapId),
    result = new BitSet(mapOut.edgeVertexAdjacency.getNumEdges()),
    faceRangeOffsets = mesh.faceRangeOffsets,
    mapValueIndices = mapOut.faceValueIndices;

  for (var i = 0, n = indices.length; i !== n; ++i) {
    var faceIndex = indices[i],
      begin = faceRangeOffsets[faceIndex],
      until = faceRangeOffsets[faceIndex + 1];

    if (begin !== until) {
      var vertexA = mapValueIndices[until - 1];

      for (var j = begin; j !== until; ++j) {
        var vertexB = mapValueIndices[j];
        result.include(mapOut.edgeVertexAdjacency.findEdgeId(vertexA, vertexB));
        vertexA = vertexB;
      }
    }
  }

  return result.toIndexArray();
};

//

export const convVerticesToFaces: SelectionConverter = (
  mesh,
  indices,
  mapId = DefaultMap,
  _ = DefaultMap
) => {
  var buffer = new ScalarBuffer(),
    faceRangeOffsets = mesh.faceRangeOffsets,
    mapValueIndices = PolyMaps.resolveMap(mesh, mapId).faceValueIndices,
    nFaces = mesh.getNumFaces();

  for (var faceIndex = 0; faceIndex !== nFaces; ++faceIndex) {
    var begin = faceRangeOffsets[faceIndex],
      until = faceRangeOffsets[faceIndex + 1],
      allVerticesFound = true;

    for (var i = begin; i !== until && allVerticesFound; ++i)
      allVerticesFound = -1 < Arrays.binarySearch(indices, mapValueIndices[i]);

    if (allVerticesFound) buffer.push(faceIndex);
  }

  return buffer.toArrayAndClear();
};

export const convVerticesToVertices: SelectionConverter = (
  mesh,
  indices,
  mapIdIn = DefaultMap,
  mapIdOut = DefaultMap
) => {
  if (mapIdIn === mapIdOut) return indices;

  var mapOut = PolyMaps.resolveMap(mesh, mapIdOut),
    result = new BitSet(mapOut.values.length),
    mapOutValueIndices = mapOut.faceValueIndices,
    mapIn = PolyMaps.resolveMap(mesh, mapIdIn).updateInverseIndex(),
    faceIndexOffsets = mapIn.valueFaceIndexOffsets!,
    faceIndices = mapIn.valueFaceIndices!;

  for (var i = 0, n = indices.length; i !== n; ++i) {
    var mapInValueIndex = indices[i],
      facesBegin = faceIndexOffsets[mapInValueIndex],
      facesUntil = faceIndexOffsets[mapInValueIndex + 1];

    for (var j = facesBegin; j !== facesUntil; ++j) {
      var vertexOffset = mapIn.findValueIndexOffset(
        faceIndices[j],
        mapInValueIndex
      );

      result.include(mapOutValueIndices[vertexOffset]);
    }
  }

  return result.toIndexArray();
};

export const convVerticesToEdges: SelectionConverter = (
  mesh,
  indices,
  mapIdIn = DefaultMap,
  mapIdOut = DefaultMap
) => {
  var edgeVertexAdjacencyOut = PolyMaps.resolveMap(mesh, mapIdOut)
      .edgeVertexAdjacency,
    result = new BitSet(edgeVertexAdjacencyOut.getNumEdges()),
    faceRangeOffsets = mesh.faceRangeOffsets,
    mapValueIndices = PolyMaps.resolveMap(mesh, mapIdIn).faceValueIndices,
    nFaces = mesh.getNumFaces();

  for (var faceIndex = 0; faceIndex !== nFaces; ++faceIndex) {
    var begin = faceRangeOffsets[faceIndex],
      until = faceRangeOffsets[faceIndex + 1];

    if (begin !== until) {
      var last = mapValueIndices[--until],
        lastSelected = -1 < Arrays.binarySearch(indices, last),
        vertexA = last,
        prevSelected = lastSelected;

      for (var j = begin; j !== until; ++j) {
        var vertexB = mapValueIndices[j],
          selected = -1 < Arrays.binarySearch(indices, vertexB);

        if (prevSelected && selected)
          result.include(edgeVertexAdjacencyOut.findEdgeId(vertexA, vertexB));

        vertexA = vertexB;
        prevSelected = selected;
      }

      if (prevSelected && lastSelected)
        result.include(edgeVertexAdjacencyOut.findEdgeId(vertexA, last));
    }
  }

  return result.toIndexArray();
};

//

export const convEdgesToFaces: SelectionConverter = (
  mesh,
  indices,
  mapIdIn = DefaultMap,
  _
) => {
  var tmp = convEdgesToVertices(mesh, indices, mapIdIn, mapIdIn);

  return convVerticesToFaces(mesh, tmp, mapIdIn, '');
};

export const convEdgesToVertices: SelectionConverter = (
  mesh,
  indices,
  mapIdIn = DefaultMap,
  mapIdOut = DefaultMap
) => {
  var tmp = convEdgesToVerticesOfSameMap(mesh, indices, mapIdIn);

  return convVerticesToVertices(mesh, tmp, mapIdIn, mapIdOut);
};

export const convEdgesToEdges: SelectionConverter = (
  mesh,
  indices,
  mapIdIn = DefaultMap,
  mapIdOut = DefaultMap
) => {
  if (mapIdIn === mapIdOut) return indices;

  var tmp = convEdgesToVerticesOfSameMap(mesh, indices, mapIdIn);

  return convVerticesToEdges(mesh, indices, mapIdIn, mapIdOut);
};

export const convEdgesToVerticesOfSameMap: SelectionConverter = (
  mesh,
  indices,
  mapId = DefaultMap
) => {
  var map = PolyMaps.resolveMap(mesh, mapId),
    adjacency = map.edgeVertexAdjacency,
    edgeRangeOffsets = adjacency.edgePivotRangeOffsets,
    highVertexIndices = adjacency.edgeHighVertexIndices,
    result = new BitSet(map.values.length);

  for (var i = 0, n = indices.length; i !== n; ++i) {
    var edgeId = indices[i],
      hiVertex = highVertexIndices[edgeId],
      searchResult = Arrays.binarySearch(edgeRangeOffsets, edgeId + 1);

    if (searchResult < 0) searchResult = ~searchResult;

    var loVertex = searchResult - 1;

    result.include(hiVertex);
    result.include(loVertex);
  }

  return result.toIndexArray();
};

//

export const ConversionTable: {
  [type: string]: { [type: string]: SelectionConverter | null };
} = {
  // From Faces to...
  [ComponentType.FACE]: {
    [ComponentType.FACE]: null,
    [ComponentType.VERT]: convFacesToVertices,
    [ComponentType.EDGE]: convFacesToEdges,
  },
  // From Vertices to...
  [ComponentType.VERT]: {
    [ComponentType.FACE]: convVerticesToFaces,
    [ComponentType.VERT]: convVerticesToVertices,
    [ComponentType.EDGE]: convVerticesToEdges,
  },
  // From Edges to...
  [ComponentType.EDGE]: {
    [ComponentType.FACE]: convEdgesToFaces,
    [ComponentType.VERT]: convEdgesToVertices,
    [ComponentType.EDGE]: convEdgesToEdges,
  },
};
