import {
  EvaluateOptions,
  OperatorData,
  OperatorDefinition,
  PropertiesPrimitive,
} from '../../types';
import { arExportable } from './Default';

const layerValues = ['Layer 0', 'Layer 1', 'Layer 2', 'Layer 3', 'Layer 4'];
const layerLabels = ([] as string[]).concat(layerValues);

const PolyMeshProperties: OperatorDefinition = {
  label: 'PolyMesh Properties',
  schema: {
    // Note: right now we use double-sided rendering by default in threehub. This property is currently
    // used only to set the appropriate value in the V2 importers for export back to V1
    doubleSided: {
      label: 'Double Sided',
      type: 'Boolean',
      defaultValue: false,
    },
    visible: {
      label: 'Visible',
      type: 'Boolean',
      defaultValue: true,
      animatable: true,
    },
    arExportable,
    prefetch: { label: 'Pre-fetch mesh', type: 'Boolean', defaultValue: false },
    layer: {
      label: 'Layer',
      type: 'Options',
      values: layerValues,
      labels: layerLabels,
      defaultValue: layerValues[0],
    },
    castShadow: { label: 'Cast Shadow', type: 'Boolean', defaultValue: true },
    receiveShadow: {
      label: 'Receive Shadow',
      type: 'Boolean',
      defaultValue: true,
    },
    // discardable: { label: 'Discardable', type: 'Boolean', defaultValue: false }, // Caution: removed, but will continue to exist on unupdated assets
    interactive: { label: 'Interactive', type: 'Boolean', defaultValue: false },
    selectable: { label: 'Selectable', type: 'Boolean', defaultValue: true },
    walkableInVR: { label: 'Walkable', type: 'Boolean', defaultValue: false },
    vrayObjectId: { label: 'Vray ObjectID', type: 'Number', defaultValue: 1 },
    vrayIgnoreLights: {
      label: 'Vray Igore Lights',
      type: 'Boolean',
      defaultValue: false,
    },
  },
  update(
    op: OperatorData,
    primitive: PropertiesPrimitive,
    opts: EvaluateOptions
  ) {
    primitive.visible = op.visible;
    return primitive;
  },
};

export default PolyMeshProperties;
