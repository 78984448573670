import * as THREE from 'three';
import { EvaluateOptions, FileAttrs, ThreekitSource } from '../../types';
import queries from '../../queries';
import PolyMesh from '../../polyMesh/geometric/model/PolyMesh';
import { convertToCNSPolyMesh } from '../../polyMesh/bingeom';

// import { ThreekitStore } from '@threekit/redux-store';
// import { convertToCNSPolyMesh, files } from '@threekit/scene-graph';

// @ts-ignore
THREE.Vector2.InstanceScalarSize = 2;
// @ts-ignore
THREE.Vector3.InstanceScalarSize = 3;
// @ts-ignore
THREE.Color.InstanceScalarSize = 3;
// @ts-ignore
THREE.Matrix3.InstanceScalarSize = 9;
// @ts-ignore
THREE.Matrix4.InstanceScalarSize = 16;

export default class BingeomLoader extends THREE.Loader {
  source: ThreekitSource; // this must be set to use this loader

  static attach(source: ThreekitSource) {
    return (l: BingeomLoader) => (l.source = source);
  }

  public load(
    file: string | FileAttrs,
    onLoad?: (result: PolyMesh) => void,
    onProgress?: Function,
    onError?: Function
  ) {
    if (typeof file === 'string') {
      throw new Error('Invalid bingeom for loader');
    }
    queries.files.getBingeom(this.source, file).then((data) => {
      const mesh = convertToCNSPolyMesh(data);
      if (onLoad) onLoad(mesh);
    });
  }
}
