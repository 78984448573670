/**
 * Types and type hacks for the flat array types
 */

export type TypedArray =
  | Int8Array
  | Uint8Array
  | Uint8ClampedArray
  | Int16Array
  | Uint16Array
  | Int32Array
  | Uint32Array
  | Float32Array
  | Float64Array;
// Useful when iterating since ArrayLike<number> doesn't have a for-of iterator
export type NumberArray = TypedArray | number[];

export interface SpecificTypedArrayCtor<T extends TypedArray> {
  BYTES_PER_ELEMENT: number;
  new (length?: number): T;
  new (arrayOrArrayBuffer: ArrayLike<number> | ArrayBufferLike): T;
  new (buffer: ArrayBufferLike, byteOffset: number, length?: number): T;
}

export type TypedArrayCtor = SpecificTypedArrayCtor<TypedArray>;

// Utility for extracting the typed array's contructor
export function arrayCtor<T extends TypedArray>(
  array: T
): SpecificTypedArrayCtor<T>;
export function arrayCtor(array: TypedArray): TypedArrayCtor;
export function arrayCtor(array: TypedArray): TypedArrayCtor {
  return array.constructor as TypedArrayCtor;
}

export type ArrayOrCtor = TypedArray | TypedArrayCtor;
export type SpecificArrayOrCtor<T extends TypedArray> =
  | T
  | SpecificTypedArrayCtor<T>;
