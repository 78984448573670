import React, { useRef } from 'react';
import { EvalNodeProps } from '../Node';
import NodeOrAsset from '../NodeOrAsset';
import { EvaluatedItemNode } from '../../types';

interface NullEvalNodeProps extends EvalNodeProps {
  evalNode: EvaluatedItemNode;
}

export default function Item(props: NullEvalNodeProps) {
  const { children, evalNode, nodeProps } = props;
  const proxy = evalNode.Proxy;
  const { node } = evalNode;

  return proxy.link ? <NodeOrAsset {...props} reference={proxy.link} /> : null;
}
