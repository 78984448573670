import { Vector3 } from 'three';
import PolyMesh from '../model/PolyMesh';

export const CONVEXITY = {
  INVALID_MESH: -1,
  FAN_TRIANGULATION: 0,
  PROJ_XY: 1,
  PROJ_XZ: 2,
  PROJ_YZ: 3,
};

export default function calculateFaceConvexity(mesh) {
  const faceOffsets = mesh.faceRangeOffsets;
  const numFaces = faceOffsets.length - 1;
  const faceValueIndices = mesh.positions.faceValueIndices;
  const positionValues = mesh.positions.values;

  // Allocate array and vectors for reuse in function calls.
  const polyVertices = [];
  const currEdge = new Vector3();
  const prevEdge = new Vector3();

  const faceConvexity = new Int8Array(numFaces);

  let faceBegin = faceOffsets[0];
  for (let fi = 0; fi < numFaces; fi++) {
    const faceUntil = faceOffsets[fi + 1];
    const faceSize = faceUntil - faceBegin;
    faceConvexity[fi] = calculateConvexity(
      positionValues,
      faceValueIndices,
      faceBegin,
      faceSize,
      polyVertices,
      currEdge,
      prevEdge
    );
    faceBegin = faceUntil;
  }
  return new PolyMesh(mesh, { faceConvexity });
}

/**
 * Determine whether polygon is star convex or not, to know whether we can
 * do a fan triangulation or if earcut is necessary. The vast majority
 * of polygons will be convex, so fan will almost always work. Fan
 * triangulation also works for some non-convex polygons (those that are star
 * convex) if we start from the right vertex; here we check only whether 0th
 * vertex works for fan.
 *
 * @param positionValues - the vertices of the mesh
 * @param faceValueIndices - the indices of the mesh
 * @param faceBegin - index of the first index used for that face
 * @param faceSize - number of vertices on the face
 */
function calculateConvexity(
  positionValues,
  faceValueIndices,
  faceBegin,
  faceSize,
  polyVertices,
  currEdge,
  prevEdge
) {
  // From starting vertex v_0, orientation is the sign of the cross product of vectors v_0v_1
  // and v_0v_2, i.e., whether vertices are progressing CW or CCW around v0. If the orientation
  // remains the same around the whole polygon, we can do fan triangulation.
  let orientation = 0;
  let currentOrientation = 0;
  // If fan triangulation fails, store projection direction to pass to earcut. The projection
  // will be one where not all vertices are collinear.
  let earcutProjection = null;

  while (polyVertices.length < faceSize) polyVertices.push(new Vector3());

  for (let i = 0; i < faceSize; i++) {
    positionValues.getAt(faceValueIndices[faceBegin + i], polyVertices[i]);
  }

  const projections = [
    ['x', 'y'],
    ['x', 'z'],
    ['y', 'z'],
  ];

  // Try three different projections (onto xy-, xz-, yz-planes); success if projection of
  // polygon is convex on that plane.
  for (let projDir = 0; projDir < 3; projDir++) {
    const [dim1, dim2] = projections[projDir];
    currEdge.copy(polyVertices[1]).sub(polyVertices[0]);
    for (let i = 2; i < faceSize; i++) {
      prevEdge.copy(currEdge);
      currEdge.copy(polyVertices[i]).sub(polyVertices[0]);
      currentOrientation = Math.sign(
        currEdge[dim1] * prevEdge[dim2] - currEdge[dim2] * prevEdge[dim1]
      );
      if (currentOrientation !== 0) {
        if (currentOrientation === -orientation) {
          // Fan triangulation will fail. Remember the projection for earcut and reset orientation.
          switch (projDir) {
            case 0:
              earcutProjection = CONVEXITY.PROJ_XY;
              break;
            case 1:
              earcutProjection = CONVEXITY.PROJ_XZ;
              break;
            case 2:
              earcutProjection = CONVEXITY.PROJ_YZ;
              break;
          }
          orientation = null;
          break;
        } else {
          orientation = currentOrientation;
        }
      }
    }
    // If orientation is 0, vertices are all collinear. If orientation is null, fan triangulation
    // fails because shape is not star convex w.r.t. 0th vertex.
    if (orientation) {
      return CONVEXITY.FAN_TRIANGULATION;
    }
  }

  // All three projections failed. Use earcut if possible. If none of the three projections was
  // stored, then the points are collinear, i.e., the polygon is degenerate.
  return earcutProjection || CONVEXITY.INVALID_MESH;
}
