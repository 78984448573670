import * as THREE from 'three';
import React from 'react';
import Node, { EvalNodeProps } from '../Node';
import NodeOrAsset, { isValidReference } from '../NodeOrAsset';
import { EvaluatedPolyMeshNode } from '../../types';

interface PolyMeshEvalNodeProps extends EvalNodeProps {
  evalNode: EvaluatedPolyMeshNode;
}

const { degToRad } = THREE.MathUtils;

export default function PolyMesh(props: PolyMeshEvalNodeProps) {
  const { evalNode, assetState } = props;
  const { node } = evalNode;
  const { nodeProps } = props;
  const { color } = nodeProps || {};

  const { PolyMesh, Material, Transform } = evalNode;
  const matReference = evalNode.Material.reference;
  const { position, scale, euler } = evalNode.Transform;

  return (
    <mesh
      name={node.name}
      position={position}
      rotation={euler} // why does vec3 not work here?
      scale={scale}
      {...nodeProps}
    >
      {PolyMesh.mesh && <binGeometry args={[PolyMesh.mesh]} />}
      {PolyMesh.geometry && PolyMesh.geometry.kind === 'Box' && (
        <boxGeometry args={PolyMesh.geometry.args} />
      )}
      {isValidReference(Material.reference) ? (
        <NodeOrAsset {...props} reference={matReference} overrides={Material} />
      ) : (
        <meshStandardMaterial />
      )}
      {props.children}
    </mesh>
  );
}
