export interface ObjectsStore {
  [key: string]: string;
}

/*
 * Utility function for wrapping an object store:
 *
 * Example:
 *   const get = getObject(objects);
 *   const root = get('rootHash');
 */

export function getObject(objects: ObjectsStore) {
  return (hash: string): any => {
    if (!hash) return undefined;

    const s = objects[hash];
    if (!s) {
      throw new Error('Unknown content hash: ' + hash);
    }

    try {
      return JSON.parse(s);
    } catch (e) {
      console.log('Error parsing hash: ' + hash);
      throw new Error(e);
    }
  };
}
