import Arrays from '../utility/Arrays';
import Orders from '../utility/Orders';

export default function FlatArrayView(data, length) {
  this.data = data;
  this.length = length;
}

FlatArrayView.prototype = {
  constructor: FlatArrayView,

  offset: 0, // in scalars, constant default
  stride: 1, // in scalars, constant default
  elementSize: 1, // in scalars, constant default

  // Access to element ranges

  newRangeArray: function(optionalNumberOfElements) {
    const n = optionalNumberOfElements || 1;
    return new this.data.constructor(n * this.elementSize);
  },

  rangeToArray: function(index, n, optionalArray, optionalWriteOffset) {
    const data = this.data;
    const stride = this.stride;
    const elemSize = this.elementSize;

    const array =
      optionalArray || new this.data.constructor(n * this.elementSize);
    let writeOffset = optionalWriteOffset || 0;

    for (
      let offset = this.offset + index * stride, end = offset + n * stride;
      offset < end;
      offset += stride
    )
      for (let i = offset, e = offset + elemSize; i !== e; ++i)
        array[writeOffset++] = data[i];

    return array;
  },

  arrayToRange: function(array, index, n, optionalReadOffset) {
    const data = this.data;
    const stride = this.stride;
    const elemSize = this.elementSize;
    let readOffset = optionalReadOffset || 0;

    for (
      let offset = this.offset + index * stride, end = offset + n * stride;
      offset < end;
      offset += stride
    )
      for (let i = offset, e = offset + elemSize; i !== e; ++i)
        data[i] = array[readOffset++];

    return this;
  },

  // Bulk copy operations

  toArray: function(optionalArrayOrType, optionalOffset) {
    const stride = this.stride;
    const length = this.length;
    const elemSize = this.elementSize;
    const writeOffset = optionalOffset === undefined ? 0 : optionalOffset;
    const minimumLength = writeOffset + length * elemSize;

    if (stride === elemSize) {
      // data is packed, can use typed array API

      const data = this.data;
      const offset = this.offset;
      const dataEnd = offset + length * elemSize;

      if (
        writeOffset === 0 &&
        (optionalArrayOrType === undefined ||
          optionalArrayOrType === this.data.constructor)
      )
        return Arrays.slice(data, offset, dataEnd);

      const array = Arrays.maybeCreate(optionalArrayOrType, minimumLength);

      array.set(data.subarray(offset, dataEnd), writeOffset);

      return array;
    } else {
      // data is strided, twiddle it apart

      const array = Arrays.maybeCreate(optionalArrayOrType, minimumLength);

      return this.rangeToArray(0, length, array, optionalOffset);
    }
  },

  fromArray: function(array, optionalOffset) {
    const stride = this.stride;
    const length = this.length;
    const elemSize = this.elementSize;
    const readOffset = optionalOffset === undefined ? 0 : optionalOffset;
    const minimumLength = readOffset + length * elemSize;

    if (stride === elemSize) {
      // data is packed, can use typed array API

      const readView =
        readOffset === 0 && array.length === minimumLength
          ? array
          : array.subarray(readOffset, minimumLength);

      this.data.set(readView, this.offset);
    } else {
      // data is strided, scatter it

      return this.arrayToRange(array, 0, length, readOffset);
    }

    return this;
  },

  fromView: function(
    sourceView, // Any descendent of flatArrayView
    options
  ) {
    options = options || {};

    if (this.length < sourceView.length) {
      throw new Error('Destination view length too small');
    }

    let elementSize = sourceView.elementSize;
    if (sourceView.elementSize > this.elementSize) {
      elementSize = this.elementSize;

      if (!options.noWarning) {
        console.warn(
          'Casting to an array view with narrower type can lead to loss of data. ' +
            'If this is intentional, you can disable this message by passing {noWarning:true} as an option to "FlatArrayView.fromView".'
        );
      }
    }

    Arrays.stridedCopy(
      sourceView.data,
      sourceView.offset,
      sourceView.stride,
      this.data,
      this.offset,
      this.stride,
      elementSize,
      sourceView.length
    );

    return this;
  },

  // Array data management

  repack: function(optionalArrayType) {
    const currentArrayType = this.data.constructor;
    const requestedArrayType = optionalArrayType || currentArrayType;

    if (
      this.stride !== this.elementSize ||
      requestedArrayType !== currentArrayType
    )
      this.forceRepack(optionalArrayType);

    return this;
  },

  forceRepack: function(optionalArrayType) {
    this.data = this.toArray(optionalArrayType);
    this.offset = 0;
    this.stride = this.elementSize;

    return this;
  },

  // Factory methods for views / buffers

  clone: function() {
    return this.newCompatibleView(this.toArray(), this.length);
  },

  newCompatibleView: function(arrayOrType, optionalLength) {
    throw Error('not implemented'); // abstract
  },

  newCompatibleBuffer: function() {
    throw Error('not implemented'); // abstract
  },

  // Factory methods for compare functions

  newCompareAtIndices: function() {
    return Orders.derefedTuplesIn(this.data, this.elementSize, this.stride);
  },

  newCompareAtIndicesStable: function() {
    return Orders.derefedTuplesStableIn(
      this.data,
      this.elementSize,
      this.stride
    );
  },
};
