// import { BlackWhite } from './BlackWhite';
// import { Canvas } from './Canvas';
// import { CanvasComposite } from './CanvasComposite';
// import { CanvasFill } from './CanvasFill';
// import { CanvasLinearGradient } from './CanvasLinearGradient';
// import { CanvasText } from './CanvasText';
import { Image } from './Image';
// import { ImagePosterization } from './ImageRecolor';
// import { VPRenders } from './VPRender';

export default {
  Image,
  // Canvas,
  // CanvasComposite,
  // CanvasFill,
  // CanvasLinearGradient,
  // CanvasText,
  // BlackWhite,
  // ImagePosterization,
  // VPRenders,
};
