import { ThreekitSource } from '../types';

export async function get(source: ThreekitSource, id: string) {
  const url = `${source.apiRoot('cas')}/cas/${id}`;
  // console.log('queries.cas.get', id);
  return source.httpClient.get(url);
}

export async function getManifest(source: ThreekitSource, id: string) {
  const url = `${source.apiRoot('asset-jobs')}/asset-jobs/manifest/${id}`;
  const result = await source.httpClient.get(url);
  return result?.data;
}
