import { OperatorSchema } from '../../types';
import {
  AllUvChannelsType,
  AllUvChannels,
  NumberedUvChannels,
} from '../constants';

export enum MapModifierType {
  None,
  Factor,
  Color,
}

interface MakeMapOptions {
  mapName?: string;
  uvName?: string;
  colorName?: string;
  factorName?: string;
  defaultValue?: any;
  defaultUv?: AllUvChannelsType;
  min?: number;
  max?: number;
  step?: number;
  includeTriplanar?: boolean;
}

export function makeMap(
  name: string,
  type: MapModifierType,
  options: MakeMapOptions
) {
  options = options || {};

  const capitalizedName = name.charAt(0).toUpperCase() + name.substring(1);

  const schema: OperatorSchema = {};

  schema[name + 'Map'] = {
    label: options.mapName || capitalizedName + ' Image',
    type: 'Node',
    assetType: ['Texture', 'Vector'],
  };

  const includeTriplanar =
    options.includeTriplanar === undefined ? true : options.includeTriplanar;
  schema[name + 'UV'] = {
    label: options.uvName || 'UV Channel',
    type: 'Options',
    defaultValue: options.defaultUv ?? '1',
    options: includeTriplanar ? AllUvChannels : NumberedUvChannels,
  };

  if (type === MapModifierType.Color) {
    schema[name + 'Color'] = {
      label:
        options.colorName || options.factorName || capitalizedName + ' Color',
      type: 'Color',
      defaultValue: options.defaultValue ?? { r: 1, g: 1, b: 1 },
      animatable: true,
    };
  } else if (type === MapModifierType.Factor) {
    schema[name + 'Factor'] = {
      label:
        options.factorName || options.colorName || capitalizedName + ' Factor',
      type: 'Number',
      defaultValue: options.defaultValue ?? 1.0,
      min: options.min !== undefined ? options.min : 0,
      max: options.max !== undefined ? options.max : 1,
      step: options.step !== undefined ? options.step : 0.01,
      animatable: true,
    };
  } else if (type !== MapModifierType.None) {
    console.warn(`Upsupported map type (${name}):`, type);
  }

  return schema;
}
