/**
 * Inspired by: https://github.com/dtudury/discontinuous-range/blob/master/index.js
 */

const MAX_ARRAY_LENGTH = 0x100000000; // 2^32

/**
 * Represents a single number range bound by a start and end value (which may be the same), both inclusive
 */
class Range {
  constructor(public start: number, public end: number) { }

  intersects(range: Range) {
    return range && (this.start <= range.end && this.end >= range.start);
  }

  merge(range: Range) {
    this.start = Math.min(this.start, range.start);
    this.end = Math.max(this.end, range.end);
  }

  compare(val: number) {
    if (this.start > val) return 1;
    else if (this.end < val) return -1;
    return 0;
  }
}

/**
 * Represents a collection of sorted, non-contiguous number ranges.
 * Ex. 3-5, 7, 10-30, etc
 */
class RangeSet {

  private ranges: Range[] = [];

  /**
 * Helper method using binary array to find where
 * @param {*} array
 * @param {*} value
 */
  private sortedIndex(array: Range[], value: number) {
    let low = 0;
    let high = array.length;

    while (low < high) {
      const mid = (low + high) >>> 1;
      if (array[mid].compare(value) < 0) low = mid + 1;
      else high = mid;
    }
    return low;
  };

  /**
   *
   * @param maxIndex Exclusive maximum value of the range
   */
  constructor(public maxIndex?: number) { }

  add(a: number, b: number) {
    if (!b) b = a; // represent single element as range from/to itself
    if (a > b) {
      console.error(`Attempting to add invalid range (${a},${b})`);
      return;
    }
    if (this.maxIndex) {
      if (a >= this.maxIndex) {
        console.warn(`Range start was greater that the maximum index. The range will be discarded.`);
        return;
      }
      if (b >= this.maxIndex) {
        b = this.maxIndex - 1;
        console.warn(`Range end was greater that the maximum index. The range will be reduced to (${a},${b}).`);
      }
    }

    const subRange = new Range(a, b);

    if (!this.ranges.length) {
      this.ranges.push(subRange);
      return;
    }

    // find indices of existing ranges which the incoming range intersects
    const startIdx = this.sortedIndex(this.ranges, a);
    let endIdx = this.sortedIndex(this.ranges, b);
    if (endIdx < this.ranges.length && b >= this.ranges[endIdx].start)
      endIdx += 1;

    // if startIdx === endIdx, no overlap with existing ranges, insert new range at that index
    if (startIdx === endIdx) this.ranges.splice(startIdx, 0, subRange);
    else {
      // incoming range covers one or more existing subranges, so merge them
      const mergedRange = subRange;
      for (let i = startIdx; i < endIdx; i++) {
        mergedRange.merge(this.ranges[i]);
      }
      this.ranges.splice(startIdx, endIdx - startIdx, mergedRange);
    }
  }

  *[Symbol.iterator]() {
    for (const range of this.ranges) {
      for (let j = range.start; j <= range.end; j++) {
        yield j;
      }
    }
  }

  /**
   * Return array with expanded range values.
   * Ex. [(3,5), (8,10)] --> [3, 4, 5, 8, 9, 10]
   */
  expanded(): number[] { return [...this]; }
}

export default RangeSet;
