import { Box3, Plane, Ray, Triangle, Vector3 } from 'three';

import PolyMap from '../../../geometric/model/PolyMap';
import PolyMesh from '../../../geometric/model/PolyMesh';
import { NearestHit } from './kdnode';

export const EPS = 1e-8;
export const MAX_PER_LEAF = 3;
export const START_AXIS = 0;

const tempVec3 = new Vector3();
const tempVertexA = new Vector3();
const tempVertexB = new Vector3();

//Given a queryPoint and face, gives back the closest point to the queryPoint on the face
function closestPointOnFace(
  polyMesh: PolyMesh,
  faceIndex: number,
  queryPoint: Vector3,
  optionalReturn?: Vector3
) {
  const positions = polyMesh.positions;
  const faceBegin = positions.faceRangeOffsets[faceIndex];
  const faceEnd = positions.faceRangeOffsets[faceIndex + 1];

  optionalReturn = optionalReturn || new Vector3();
  optionalReturn.set(Infinity, Infinity, Infinity);
  const triangle = new Triangle();
  const { values, faceValueIndices } = positions;

  values.getAt(faceValueIndices[faceBegin], triangle.a);
  values.getAt(faceValueIndices[faceBegin + 1], triangle.b);

  for (let i = faceBegin; i < faceEnd - 2; ++i) {
    values.getAt(faceValueIndices[i + 2], triangle.c);
    triangle.closestPointToPoint(queryPoint, tempVertexB);
    if (
      queryPoint.distanceToSquared(tempVertexB) <
      queryPoint.distanceToSquared(optionalReturn)
    ) {
      optionalReturn.copy(tempVertexB);
    }
    triangle.b.copy(triangle.c);
  }
  return optionalReturn;
}

export function closestFaceToPoint(
  polyMesh: PolyMesh,
  faceIndices: number[],
  queryPoint: Vector3,
  nearestHit: NearestHit
) {
  closestPointOnFace(polyMesh, faceIndices[0], queryPoint, tempVec3);
  let currentClosestFace = faceIndices[0];
  let currentDistance = queryPoint.distanceTo(tempVec3);
  const currentClosestPoint = tempVertexA.copy(tempVec3);

  for (let i = 1; i < faceIndices.length; ++i) {
    closestPointOnFace(polyMesh, faceIndices[i], queryPoint, tempVec3);
    if (
      queryPoint.distanceToSquared(tempVec3) <
      queryPoint.distanceToSquared(currentClosestPoint)
    ) {
      currentClosestPoint.copy(tempVec3);
      currentClosestFace = faceIndices[i];
      currentDistance = queryPoint.distanceTo(tempVec3);
    }
  }

  if (nearestHit.distance > currentDistance) {
    nearestHit.set(currentClosestFace, currentClosestPoint, currentDistance);
  }
}

export function rayFaceIntersection(
  polyMesh: PolyMesh,
  faceIndex: number,
  ray: Ray,
  near: number,
  far: number,
  nearestHit: NearestHit
) {
  const positions = polyMesh.positions;
  const { faceValueIndices, values } = positions;
  const faceBegin = positions.faceRangeOffsets[faceIndex];
  const faceEnd = positions.faceRangeOffsets[faceIndex + 1];

  //find intersection of ray with face plane
  computeFaceNormal(polyMesh.positions, faceIndex, tempVec3);
  const facePlane = new Plane().setFromNormalAndCoplanarPoint(
    tempVec3,
    values.getAt(faceValueIndices[faceBegin], tempVertexA)
  );
  const denominator = facePlane.normal.dot(ray.direction);
  if (Math.abs(denominator) < EPS) {
    //plane and ray are parallel
    return false;
  }

  //t is the distance to the intersectionPoint along the ray (if ray.direction is normalized)
  const t =
    -(facePlane.normal.dot(ray.origin) + facePlane.constant) / denominator;
  if (t < near || t > far || t > nearestHit.distance) {
    return false;
  }
  const intersectionPoint = ray.at(t, tempVec3);

  //check if intersection within face by triangulation
  const triangle = new Triangle();
  values.getAt(faceValueIndices[faceBegin], triangle.a);
  values.getAt(faceValueIndices[faceBegin + 1], triangle.b);

  for (let i = faceBegin; i < faceEnd - 2; ++i) {
    values.getAt(faceValueIndices[i + 2], triangle.c);

    if (triangle.containsPoint(intersectionPoint)) {
      nearestHit.set(faceIndex, intersectionPoint, t);
      return true;
    }
    triangle.b.copy(triangle.c);
  }
  return false;
}

export function boxContainsApprox(
  boundingBox: Box3,
  point: Vector3,
  EPSILON: number = EPS
) {
  const { min, max } = boundingBox;
  return (
    point.x - min.x >= -EPSILON &&
    point.y - min.y >= -EPSILON &&
    point.z - min.z >= -EPSILON &&
    max.x - point.x >= -EPSILON &&
    max.y - point.y >= -EPSILON &&
    max.z - point.z >= -EPSILON
  );
}

export function buildBoundingBox(
  polyMesh: PolyMesh,
  faceIndices: number[],
  optionalResult?: Box3
): Box3 {
  const boundingBox = optionalResult || new Box3();
  const { faceValueIndices, values } = polyMesh.positions;

  for (let i = 0; i < faceIndices.length; ++i) {
    const faceBegin = polyMesh.faceRangeOffsets[faceIndices[i]];
    const faceEnd = polyMesh.faceRangeOffsets[faceIndices[i] + 1];
    for (let j = faceBegin; j < faceEnd; ++j) {
      values.getAt(faceValueIndices[j], tempVec3);
      boundingBox.expandByPoint(tempVec3);
    }
  }
  return boundingBox;
}

export function planePlaneIntersection(planeA: Plane, planeB: Plane) {
  const normalsDot = planeA.normal.dot(planeB.normal);
  const den = 1 - normalsDot * normalsDot;
  if (Math.abs(den) < EPS) {
    return null; //planes are parallel
  }

  // Using method (C) 3 planes intersection (http://geomalgorithms.com/a05-_intersect-1.html)
  const rayDirection = new Vector3();
  rayDirection.crossVectors(planeA.normal, planeB.normal);

  const rayOrigin = new Vector3();
  tempVec3.copy(planeA.normal).multiplyScalar(planeB.constant);
  rayOrigin.copy(planeB.normal).multiplyScalar(planeA.constant);

  tempVec3.sub(rayOrigin);
  rayOrigin
    .crossVectors(tempVec3, rayDirection)
    .multiplyScalar(1.0 / rayDirection.lengthSq());

  rayDirection.normalize();
  return {
    forward: new Ray(rayOrigin, rayDirection),
    backward: new Ray(rayOrigin, rayDirection.clone().negate()),
  };
}

export function computeFaceNormal(
  positionMap: PolyMap<any>,
  faceIndex: number,
  optionalTarget?: Vector3
): Vector3 {
  const faceNormal = optionalTarget || new Vector3();
  faceNormal.set(0, 0, 0);
  const { faceRangeOffsets, faceValueIndices, values } = positionMap;

  const faceBegin = faceRangeOffsets[faceIndex];
  const faceUntil = faceRangeOffsets[faceIndex + 1];
  const faceValuesCount = faceUntil - faceBegin;

  let nextValIndex = faceValueIndices[faceBegin];
  let nextNextValIndex = faceValueIndices[faceBegin + (1 % faceValuesCount)];

  for (let fv = 0; fv < faceValuesCount; ++fv) {
    const currentValIndex = nextValIndex;
    nextValIndex = nextNextValIndex;
    nextNextValIndex =
      faceValueIndices[faceBegin + ((fv + 2) % faceValuesCount)];

    values.getAt(currentValIndex, tempVertexA);
    values.getAt(nextValIndex, tempVertexB);

    faceNormal.x +=
      (tempVertexA.y - tempVertexB.y) * (tempVertexA.z + tempVertexB.z);
    faceNormal.y +=
      (tempVertexA.z - tempVertexB.z) * (tempVertexA.x + tempVertexB.x);
    faceNormal.z +=
      (tempVertexA.x - tempVertexB.x) * (tempVertexA.y + tempVertexB.y);
  }

  faceNormal.normalize();
  return faceNormal;
}
