import BlockBuffer from './BlockBuffer';

export default function ScalarBuffer(optionalArrayType) {
  BlockBuffer.call(this, optionalArrayType || Uint32Array);
}

ScalarBuffer.prototype = Object.assign(Object.create(BlockBuffer.prototype), {
  constructor: ScalarBuffer,

  getAt: function(index) {
    const blockIndex = index >> this.intraBlockIndexBits;
    const blockOffset = index & this.intraBlockIndexMask;

    return this.blocks[blockIndex][blockOffset];
  },

  setAt: function(index, value) {
    const blockIndex = index >> this.intraBlockIndexBits;
    const blockOffset = index & this.intraBlockIndexMask;

    this.blocks[blockIndex][blockOffset] = value;
    return this;
  },

  push: function(value) {
    const index = this.length++;
    let block = this.lastBlock;
    const blockOffset = index & this.intraBlockIndexMask;

    if (blockOffset === 0) {
      const blockSize = this.intraBlockIndexMask + 1;
      block = new this.arrayType(blockSize);
      this.lastBlock = block;
      this.blocks.push(block);
    }

    block[blockOffset] = value;

    return index + 1;
  },
});
